import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
// import moment from "moment"
import { withStyles } from "@material-ui/core/styles"

import { Button } from "@material-ui/core"
import { FormControl } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import { Grow } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
import { Dialog } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { withMobileDialog } from "@material-ui/core"
import { OutlinedInput } from "@material-ui/core"
import { InputLabel } from "@material-ui/core"
import { InputAdornment } from "@material-ui/core"

import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

import firebase from "../config/firebase.js"

import { PhoneFormatter, phoneFormatRegex, phoneHelperText } from "../components/PhoneNumber"

import CustomLink from "../modules/CustomLink"

// import { sendEmail } from "../functions/apiLambda"
// import { dateNow, timeNow } from "../functions/appStrings"

import { withSnackbar } from "notistack"
import { message } from "../functions/appInterface"
import { capitalize } from "../functions/appStrings"

import Loader from "./Loader"

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  footer: {
    margin: theme.spacing.unit * 3
  },
  capitalize: {
    textTransform: "capitalize"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  lowercase: {
    textTransform: "lowercase"
  },
  hiddenXs: {
    [theme.breakpoints.up("xs")]: {
      visibility: "hidden !important"
    }
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  }
})

class Auth extends Component {
  state = {
    isLoading: false,
    showPassword: false,
    userFirstName: "",
    userLastName: "",
    userPhone: "",
    userDescription: "",
    userEmail: "",
    userPassword: "",
    isSignIn: true,
    isResetPassword: false,
    isSignUp: false,
    openDialog: false
  }

  // Define alert message context
  message = message

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      const userPassword = this.state.userPassword

      // IF user connected
      if (user) {
        this.setState({ isLoading: false })

        // let userId

        // // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DEV ONLY
        // if (window.location.hostname === "localhost") {
        //   console.log(" // YBjZjVuMFFUtahZLBBdBOmHIwrY2 // Francis")
        //   console.log(" // sHBSOUDQptbe3rl7mf9L6sDbNaN2 // Stephane")
        //   console.log(" // pQ58UR3krwhT0SMkDQFI313Dxie2 // Benjamin")

        //   console.log(" // or6dDLlzK5RQXdJriCzD4rBUVrK2 // Alain")
        //   console.log(" // fFrxogFbWxcuzMSOfc6k2ADose13 // Jean-Marc")
        //   console.log(" // 48XGEraMtINxZ4Wsk2h7dkuO4Zz2 // Najla")
        //   console.log(" // rlO5vOVBVjWEbG0qfzb6xYjMNoC2 // Guy")
        // }

        // if (user.uid === "m0YKYNcm5GhQPcpUSMeNUkoU4u23") {
        //   const uid = prompt("ID", "m0YKYNcm5GhQPcpUSMeNUkoU4u23")
        //   if (uid) {
        //     userId = uid
        //   }
        // } else {
        //   userId = user.uid
        // }
        // // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< DEV ONLY

        const userId = user.uid

        if (userId) {
          // const userId = user.uid
          const userEmail = user.email

          // If emailVerified
          if (!user.emailVerified) {
            this.setState({ openDialog: true })
            this.setState({ userEmail: userEmail })
          } else {
            // Save to Redux global state
            this.props.dispatch({ type: "UPDATE_USER", value: user })
            this.props.dispatch({ type: "UPDATE_USER_EMAIL", value: userEmail })
            this.props.dispatch({ type: "UPDATE_USER_PASSWORD", value: userPassword })
            this.props.dispatch({ type: "UPDATE_NEXT_USER_EMAIL", value: userEmail })
            this.props.dispatch({ type: "UPDATE_NEXT_USER_PASSWORD", value: userPassword })

            // Load user data
            firebase
              .database()
              .ref("/users/" + userId)
              .once("value")
              .then(
                snapshot => {
                  const data = snapshot.val()
                  const userGroup = data.group
                  const userFirstName = data.firstname
                  const userLastName = data.lastname
                  const userPhone = data.phone
                  const userDescription = data.description
                  const userAreas = data.areas
                  const userAreasTemp = data.areastemp

                  this.message("success", "Bienvenu " + userFirstName + " " + userLastName + " !")

                  // Save to Redux global state
                  this.props.dispatch({ type: "UPDATE_USER_GROUP", value: userGroup })
                  this.props.dispatch({ type: "UPDATE_USER_FIRST_NAME", value: userFirstName })
                  this.props.dispatch({ type: "UPDATE_USER_LAST_NAME", value: userLastName })
                  this.props.dispatch({ type: "UPDATE_USER_PHONE", value: userPhone })
                  this.props.dispatch({ type: "UPDATE_USER_DESCRIPTION", value: userDescription })
                  this.props.dispatch({ type: "UPDATE_USER_AREAS", value: userAreas })
                  this.props.dispatch({ type: "UPDATE_NEXT_USER_FIRST_NAME", value: userFirstName })
                  this.props.dispatch({ type: "UPDATE_NEXT_USER_LAST_NAME", value: userLastName })
                  this.props.dispatch({ type: "UPDATE_NEXT_USER_PHONE", value: userPhone })
                  this.props.dispatch({ type: "UPDATE_NEXT_USER_DESCRIPTION", value: userDescription })
                  this.props.dispatch({ type: "UPDATE_NEXT_USER_AREAS", value: userAreas })

                  this.props.dispatch({ type: "UPDATE_USER_AREAS_TEMP", value: userAreasTemp })

                  // END If userAreas
                  if (userAreas) {
                    // Load users
                    firebase
                      .database()
                      .ref("/users/")
                      .on(
                        "value",
                        snapshot => {
                          const users = snapshot.val()

                          const nextUsers = Object.keys(users)
                            .filter(
                              key =>
                                // Load users for valid areas subscriptions
                                (users[key].areas && users[key].areas.some(area => userAreas.includes(area))) ||
                                // Load users for temp areas subscriptions
                                (userGroup === "admin" && users[key].areastemp && users[key].areastemp.some(area => userAreas.includes(area))) ||
                                // Load users for no existing areas subscriptions
                                (userGroup === "admin" && !users[key].areastemp)
                            )
                            .sort((a, b) => {
                              return users[a].firstname < users[b].firstname ? -1 : users[a].firstname > users[b].firstname ? 1 : 0 // Alphabetical order
                            })
                            .reduce((obj, key) => {
                              obj[key] = users[key]
                              return obj
                            }, {})

                          // Save to Redux global state
                          // console.log("nextUsers", nextUsers)
                          this.props.dispatch({ type: "UPDATE_DATA_USERS", value: nextUsers })

                          // if (userGroup === "admin") {
                          //   const tempUsers = Object.keys(users)
                          //     .filter(key => users[key].areastemp && users[key].areastemp.some(area => userAreas.includes(area))) // Ignore users from other area
                          //     .sort((a, b) => {
                          //       return users[a].firstname < users[b].firstname ? -1 : users[a].firstname > users[b].firstname ? 1 : 0 // Alphabetical order
                          //     })
                          //     .reduce((obj, key) => {
                          //       obj[key] = users[key]
                          //       return obj
                          //     }, {})

                          //   // Save to Redux global state
                          //   this.props.dispatch({ type: "UPDATE_DATA_USERS_TEMP", value: tempUsers })
                          // }

                          // // Load DEFAULT users (ADMIN access only)
                          // if (userGroup === "admin") {
                          //   const defaultUsers = Object.keys(users)
                          //     .sort((a, b) => {
                          //       return users[a].firstname < users[b].firstname ? -1 : users[a].firstname > users[b].firstname ? 1 : 0 // Alphabetical order
                          //     })
                          //     .filter(user => users[user].group === "default") // Keep only default users
                          //     .reduce((obj, key) => {
                          //       obj[key] = users[key]
                          //       return obj
                          //     }, {})

                          //   // Save to Redux global state
                          //   this.props.dispatch({ type: "UPDATE_DATA_DEFAULT_USERS", value: defaultUsers })
                          // }
                          // // END Load DEFAULT users (ADMIN access only)
                        },
                        error => {
                          console.error("Error e946a5e7-c622-48b3-a345-93327e9d2760", error)
                          // this.message("error", "Erreur : " + error.message)
                        }
                      )
                    // END Load users

                    // Load contacts
                    if (userGroup === "admin" || userGroup === "observer" || userGroup === "inspector") {
                      firebase
                        .database()
                        .ref("/contacts")
                        .on("value", snapshot => {
                          const contacts = snapshot.val()

                          if (contacts) {
                            // Filter contacts by area
                            // console.log("userAreas = ", userAreas)
                            const nextContacts = Object.keys(contacts)
                              .filter(key => userAreas.includes(contacts[key].area))
                              .reduce((obj, key) => {
                                obj[key] = contacts[key]
                                return obj
                              }, {})

                            // Save to Redux global state
                            this.props.dispatch({ type: "UPDATE_DATA_CONTACTS", value: nextContacts })
                          }
                        })
                    } else if (userGroup === "member") {
                      firebase
                        .database()
                        .ref("/contacts")
                        .orderByChild("author")
                        .equalTo(userId)
                        .on("value", snapshot => {
                          const contacts = snapshot.val()

                          if (contacts) {
                            // Filter contacts by area
                            // console.log("userAreas = ", userAreas)
                            const nextContacts = Object.keys(contacts)
                              .filter(key => userAreas.includes(contacts[key].area))
                              .reduce((obj, key) => {
                                obj[key] = contacts[key]
                                return obj
                              }, {})

                            // Save to Redux global state
                            this.props.dispatch({ type: "UPDATE_DATA_CONTACTS", value: nextContacts })
                          }
                        })
                    }
                    // END Load contacts

                    // Load alerts and ressources
                    if (userGroup === "admin" || userGroup === "inspector") {
                      // Load all joins
                      firebase
                        .database()
                        .ref("/joins")
                        .on("value", snapshot => {
                          const joins = snapshot.val()
                          // Save to Redux global state
                          this.props.dispatch({ type: "UPDATE_DATA_JOINS", value: joins })
                        })

                      // Load all alerts
                      firebase
                        .database()
                        .ref("/alerts")
                        .on("value", snapshot => {
                          const alerts = snapshot.val()
                          // Save to Redux global state
                          this.props.dispatch({ type: "UPDATE_DATA_ALERTS", value: alerts })
                        })

                      // Load all ressources
                      firebase
                        .database()
                        .ref("/ressources")
                        .on("value", snapshot => {
                          const ressources = snapshot.val()

                          if (ressources) {
                            // Filter ressources by area
                            // console.log("userAreas = ", userAreas)
                            const nextRessources = Object.keys(ressources)
                              .filter(key => userAreas.includes(ressources[key].area))
                              .reduce((obj, key) => {
                                obj[key] = ressources[key]
                                return obj
                              }, {})

                            // Save to Redux global state
                            this.props.dispatch({ type: "UPDATE_DATA_RESSOURCES", value: nextRessources })
                          }
                        })
                    } else if (userGroup === "member" || userGroup === "observer") {
                      // Load joins
                      firebase
                        .database()
                        .ref("/joins")
                        .orderByChild("user")
                        .equalTo(userId)
                        .on(
                          "value",
                          snapshot => {
                            const joins = snapshot.val()
                            // Save to Redux global state
                            this.props.dispatch({ type: "UPDATE_DATA_JOINS", value: joins })

                            // If joins
                            if (joins) {
                              // Load alerts
                              // Alerts list in array
                              const alertsKeys = Object.keys(joins).map(key => {
                                return joins[key].alert
                              })
                              firebase
                                .database()
                                .ref("/alerts") // <<<<<<<<<<<<<<< NOT SECURE !!
                                .once("value")
                                .then(snapshot => {
                                  const allAlerts = snapshot.val()

                                  return Object.keys(allAlerts)
                                    .filter(key => alertsKeys.includes(key))
                                    .reduce((obj, key) => {
                                      obj[key] = allAlerts[key]
                                      return obj
                                    }, {})
                                })

                                .then(alerts => {
                                  console.log("alerts", alerts)

                                  if (userGroup === "member") {
                                    // Save to Redux global state
                                    this.props.dispatch({ type: "UPDATE_DATA_ALERTS", value: alerts })
                                    return alerts
                                  } else if (userGroup === "observer") {
                                    // Add its own alerts
                                    return firebase
                                      .database()
                                      .ref("/alerts")
                                      .orderByChild("author") // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< TODO Firebase indexOn rules for security !!
                                      .equalTo(userId)
                                      .once("value")
                                      .then(snapshot => {
                                        const nextAlerts = Object.assign(alerts, snapshot.val())
                                        // Save to Redux global state
                                        this.props.dispatch({ type: "UPDATE_DATA_ALERTS", value: nextAlerts })
                                        return nextAlerts
                                      })
                                  }
                                })
                                .then(nextAlerts => {
                                  // Load ressources
                                  // Ressources list in array
                                  const ressourcesKeys = Object.keys(nextAlerts).map(key => {
                                    return nextAlerts[key].ressource
                                  })
                                  firebase
                                    .database()
                                    .ref("/ressources") // <<<<<<<<<<<<<<< NOT SECURE !!
                                    .once("value")
                                    .then(snapshot => {
                                      const allRessources = snapshot.val()

                                      return Object.keys(allRessources)
                                        .filter(key => ressourcesKeys.includes(key))
                                        .reduce((obj, key) => {
                                          obj[key] = allRessources[key]
                                          return obj
                                        }, {})
                                    })
                                    .then(ressources => {
                                      // NO FILTER RESSOURCES BY AREA, ONLY BY ALERT-JOIN !!!!!!!!!!!!! (member, observer)
                                      // ...

                                      if (userGroup === "member") {
                                        // Save to Redux global state
                                        this.props.dispatch({ type: "UPDATE_DATA_RESSOURCES", value: ressources })
                                      } else if (userGroup === "observer") {
                                        // Add its own ressources
                                        firebase
                                          .database()
                                          .ref("/ressources")
                                          .orderByChild("author")
                                          .equalTo(userId)
                                          .once("value")
                                          .then(snapshot => {
                                            const newRessources = snapshot.val()
                                            // Save to Redux global state
                                            this.props.dispatch({ type: "UPDATE_DATA_RESSOURCES", value: Object.assign(ressources, newRessources) })
                                          })
                                      }
                                    })

                                  // END load ressources
                                })

                              // END load alerts
                            }
                            // END If joins
                            // If no join
                            else {
                              if (userGroup === "observer") {
                                // Add its own ressources
                                firebase
                                  .database()
                                  .ref("/ressources")
                                  .orderByChild("author")
                                  .equalTo(userId)
                                  .once("value")
                                  .then(snapshot => {
                                    const ressources = snapshot.val()

                                    if (ressources) {
                                      // Filter ressources by area
                                      // console.log("userAreas = ", userAreas)
                                      const nextRessources = Object.keys(ressources)
                                        // .filter(key => userAreas.includes(ressources[key].area)) // <============================================================ SUPPR. ???
                                        .reduce((obj, key) => {
                                          obj[key] = ressources[key]
                                          return obj
                                        }, {})

                                      // Save to Redux global state
                                      this.props.dispatch({ type: "UPDATE_DATA_RESSOURCES", value: nextRessources })
                                    }
                                  })
                              }
                            }
                            // END If no join
                          },
                          error => {
                            console.error("Error ea75a059-68e3-400a-9174-cc544e9cdc68", error)
                            // this.message("error", "Erreur : " + error.message)
                          }
                        )
                      // END load joins
                    }
                    // END Load alerts and ressources

                    // // User connexion, send email notification (super admin only)
                    // if (new RegExp("@proxiloup.com").test(userEmail) || userEmail === "lyon.gus@gmail.com") {
                    //   console.log("User connexion : notification disable for demo user (...@proxiloup.com) or super admin")
                    // } else {
                    //   const mailFrom = `"Proxiloup.com" <contact@proxiloup.com>` // verified AWS email adress
                    //   const mailReplyTo = ``
                    //   const mailTo = "lyon.gus@gmail.com" // <=========================================== TEMP ADMIN
                    //   const mailSubject = userFirstName + " " + userLastName + " s'est connecté"
                    //   const mailDate = moment(dateNow(), ["YYYY-MM-DD"]).format("DD/MM/YYYY")
                    //   const mailTime = moment(timeNow(), ["HH:mm:ss"]).format("HH:mm")
                    //   const mailBody = `
                    //     <html>
                    //       <head>
                    //       <title>${mailSubject}</title>
                    //       </head>
                    //       <body>
                    //         <p>
                    //           NOUVELLE CONNEXION<br />
                    //           <b>- Nom : </b>${userFirstName} ${userLastName}<br />
                    //           <b>- Téléphone : </b>${userPhone}<br />
                    //           <b>- Description : </b>${userDescription}<br />
                    //           <b>- Email : </b>${userEmail}<br />
                    //           <b>- Groupe : </b>${userGroup}<br />
                    //           <b>- Date : </b>${mailDate} ${mailTime}<br />
                    //         </p>
                    //         <p>
                    //         --
                    //         </p>
                    //         <p><em>PS : Ceci est un message automatique envoyé via l'application Proxiloup.com</em></p>
                    //       </body>
                    //     </html>
                    //   `
                    //   sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody).then(response => {
                    //     // console.log("response : ", response)
                    //     if (response.status === 200) {
                    //       // SUCCESS
                    //       this.setState({ isLoading: false, submit: "success" })
                    //     } else {
                    //       // ERROR
                    //       this.setState({ isLoading: false, submit: "error" })
                    //     }
                    //     return response.json()
                    //   })
                    //   // .then(data => {
                    //   //   console.log("sendEmail", data)
                    //   // })
                    // }
                    // // END : User connexion, send email notification (super admin only)
                  } else {
                    console.log("no user area !!")
                    this.message("warning", "Vous n'êtes inscrit sur aucun territoire")
                    this.props.dispatch({ type: "OPEN_NO_AREA_DIALOG", value: true })
                  }
                  // END If userAreas
                },
                error => {
                  console.error("Error 769bcf78-a562-4df8-89f8-a80beddab62c", error)
                  this.message("error", "Erreur : " + error.message)
                }
              )
              .then(this.props.dispatch({ type: "TOGGLE_APP_LOADING", value: false }))
            // END load user data
          }
          // END If emailVerified
        }
      }
      // END IF user connected
    })
  }

  handleCloseDialog = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.message("warning", "Vous êtes déconnecté...")
      })
      .catch(error => {
        console.error("Error 45374115-ef7a-4a1b-8921-1c196c4b5b40", error)
        this.message("error", "Erreur : " + error.message)
      })
    this.setState({ openDialog: false })
  }

  handleSendEmailVerification = () => {
    const { userEmail } = this.state
    const user = firebase.auth().currentUser
    user
      .sendEmailVerification()
      .then(() => {
        this.message("info", "Cliquez sur le lien de vérification envoyé à " + userEmail)
      })
      .catch(error => {
        console.error("Error 8452acea-9c15-4a82-80c9-86a88dcdab57", error)
        this.message("error", "Erreur : " + error.message)
      })
    this.setState({ openDialog: false })
  }

  handleShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  refreshFirstName(e) {
    this.setState({
      userFirstName: capitalize(e.target.value),
      errorFirstName: false
    })
  }
  refreshLastName(e) {
    this.setState({
      userLastName: e.target.value.toUpperCase(),
      errorLastName: false
    })
  }
  refreshPhone(e) {
    this.setState({
      userPhone: e.target.value.toLowerCase(),
      errorPhone: false
    })
  }
  refreshDescription(e) {
    this.setState({
      userDescription: e.target.value,
      errorDescription: false
    })
  }
  refreshEmail(e) {
    this.setState({
      userEmail: e.target.value.toLowerCase(),
      errorEmail: false
    })
  }
  refreshPassword(e) {
    this.setState({
      userPassword: e.target.value,
      errorPassword: false
    })
  }

  enableSignIn = () => {
    this.setState({
      isSignIn: true,
      isResetPassword: false,
      isSignUp: false
    })
  }

  enableResetPassword = () => {
    this.setState({
      isSignIn: false,
      isResetPassword: true,
      isSignUp: false
    })
  }

  enableSignUp = () => {
    this.setState({
      isSignIn: false,
      isResetPassword: false,
      isSignUp: true
    })
  }

  handleSignIn = () => {
    this.setState({ isLoading: true })

    const userEmail = this.state.userEmail
    const userPassword = this.state.userPassword

    if (userEmail && userPassword) {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => firebase.auth().signInWithEmailAndPassword(userEmail, userPassword))
        .catch(error => {
          console.error("Error 0bd48f33-0141-483c-a5ab-9da75c6d2937", error)
          this.setState({ isLoading: false })

          if (error.code === "auth/invalid-email") {
            this.message("error", "L'adresse email n'est pas valide : " + userEmail)
            this.setState({ errorEmail: true })
          } else if (error.code === "auth/user-not-found") {
            this.message("error", "Ce compte utilisateur n'existe pas : " + userEmail)
            this.setState({ errorEmail: true })
          } else if (error.code === "auth/user-disabled") {
            this.message("error", "Ce compte utilisateur est désactivé : " + userEmail)
            this.setState({ errorEmail: true })
          } else if (error.code === "auth/wrong-password") {
            this.message("error", "Mot de passe incorrect pour " + userEmail)
            this.setState({ errorPassword: true })
          } else {
            this.message("error", "Erreur : " + error.message)
          }
        })
    } else {
      this.setState({ isLoading: false })

      this.message("warning", "L'un des champs est vide...")

      if (!userEmail) {
        this.setState({ errorEmail: true })
      }
      if (!userPassword) {
        this.setState({ errorPassword: true })
      }
    }
  }

  handleResetPassword = () => {
    this.setState({ isLoading: true })

    const userEmail = this.state.userEmail

    if (userEmail) {
      firebase
        .auth()
        .sendPasswordResetEmail(userEmail)
        .then(() => {
          this.setState({ isLoading: false })

          this.message("info", "Email de ré-initialisation envoyé à " + userEmail)
        })
        .catch(error => {
          console.error("Error cbcb6091-b7c1-4a3f-85c8-688eec385a44", error)
          this.setState({ isLoading: false })

          if (error.code === "auth/invalid-email") {
            this.message("error", "L'adresse email n'est pas valide : " + userEmail)
            this.setState({ errorEmail: true })
          } else if (error.code === "auth/user-not-found") {
            this.message("error", "Ce compte utilisateur n'existe pas : " + userEmail)
            this.setState({ errorEmail: true })
          } else if (error.code === "auth/user-disabled") {
            this.message("error", "Ce compte utilisateur est désactivé : " + userEmail)
            this.setState({ errorEmail: true })
          } else {
            this.message("error", "Erreur : " + error.message)
          }
        })
    } else {
      this.setState({ isLoading: false })

      this.message("warning", "L'un des champs est vide...")
      if (!userEmail) {
        this.setState({ errorEmail: true })
      }
    }
  }

  handleSignUp = () => {
    this.setState({ isLoading: true })

    const userFirstName = this.state.userFirstName
    const userLastName = this.state.userLastName
    const userPhone = this.state.userPhone
    const userDescription = this.state.userDescription
    const userEmail = this.state.userEmail
    const userPassword = this.state.userPassword
    const userGroup = "default"

    if (!phoneFormatRegex.test(userPhone)) {
      this.message("warning", "Numéro de mobile non valide")
      this.setState({ errorPhone: true })
      this.setState({ isLoading: false })
    } else {
      if (userFirstName && userLastName && userPhone && userDescription && userEmail && userPassword) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(userEmail, userPassword)
          .then(() => {
            this.setState({ isLoading: false })

            const user = firebase.auth().currentUser // UTILE ??? mettre user dans then() ?

            // if (user) {
            firebase
              .database()
              .ref("/users/" + user.uid)
              .set(
                {
                  firstname: userFirstName,
                  lastname: userLastName,
                  phone: userPhone,
                  description: userDescription,
                  email: userEmail,
                  group: userGroup
                },
                error => {
                  if (error) {
                    // The write failed...
                    console.error("Error 2fd9f0e6-d567-478d-8f0d-120f1c095626", error)
                    this.message("error", "Erreur : " + error.message)
                  } else {
                    this.message("success", userFirstName + " " + userLastName + ", votre nouveau compte a été créé")
                  }
                }
              )

            user.sendEmailVerification().then(() => {
              this.message("info", "Cliquez sur le lien de vérification envoyé à " + userEmail)
            })
            // }

          //   // User registration, send email notification (super admin only)
          //   const mailFrom = `"Proxiloup.com" <contact@proxiloup.com>` // verified AWS email adress
          //   const mailReplyTo = ``
          //   const mailTo = "lyon.gus@gmail.com" // <=========================================== TEMP ADMIN
          //   const mailSubject = userFirstName + " " + userLastName + " s'est inscrit"
          //   const mailDate = moment(dateNow(), ["YYYY-MM-DD"]).format("DD/MM/YYYY")
          //   const mailTime = moment(timeNow(), ["HH:mm:ss"]).format("HH:mm")
          //   const mailBody = `
          //   <html>
          //     <head>
          //     <title>${mailSubject}</title>
          //     </head>
          //     <body>
          //       <p>
          //         NOUVELLE INSCRIPTION<br />
          //         <b>- Nom : </b>${userFirstName} ${userLastName}<br />
          //         <b>- Téléphone : </b>${userPhone}<br />
          //         <b>- Description : </b>${userDescription}<br />
          //         <b>- Email : </b>${userEmail}<br />
          //         <b>- Groupe : </b>${userGroup}<br />
          //         <b>- Date : </b>${mailDate} ${mailTime}<br />
          //       </p>
          //       <p>
          //       --
          //       </p>
          //       <p><em>PS : Ceci est un message automatique envoyé via l'application Proxiloup.com</em></p>
          //     </body>
          //   </html>
          // `
          //   sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody).then(response => {
          //     // console.log("response : ", response)
          //     if (response.status === 200) {
          //       // SUCCESS
          //       this.setState({ isLoading: false, submit: "success" })
          //     } else {
          //       // ERROR
          //       this.setState({ isLoading: false, submit: "error" })
          //     }
          //     return response.json()
          //   })
          //   // .then(data => {
          //   //   console.log("sendEmail", data)
          //   // })
          //   // END : User registration, send email notification (super admin only)

            this.enableSignIn()
          })
          .catch(error => {
            console.error("Error 0cf05682-4b46-4cce-87cf-fff76f5c8a48", error)
            this.setState({ isLoading: false })

            if (error.code === "auth/email-already-in-use") {
              this.message("error", "Un compte est déjà associé à " + userEmail)
              this.setState({ errorEmail: true })
            } else if (error.code === "auth/invalid-email") {
              this.message("warning", "L'adresse email n'est pas valide : " + userEmail)
              this.setState({ errorEmail: true })
            } else if (error.code === "auth/operation-not-allowed") {
              this.message("error", "Opération non autorisée, veuillez contactez l'administrateur.")
            } else if (error.code === "auth/weak-password") {
              this.message("warning", "Le mot de passe doit comporter au moins 6 caractères.")
              this.setState({ errorPassword: true })
            } else {
              this.message("error", "Erreur : " + error.message)
            }
          })
      } else {
        this.setState({ isLoading: false })

        this.message("warning", "L'un des champs est vide...")
        if (!userFirstName) {
          this.setState({ errorFirstName: true })
        }
        if (!userLastName) {
          this.setState({ errorLastName: true })
        }
        if (!userPhone) {
          this.setState({ errorPhone: true })
        }
        if (!userDescription) {
          this.setState({ errorDescription: true })
        }
        if (!userEmail) {
          this.setState({ errorEmail: true })
        }
        if (!userPassword) {
          this.setState({ errorPassword: true })
        }
      }
    }
  }

  render() {
    const { classes } = this.props
    const { fullScreen } = this.props
    const {
      isLoading,
      showPassword,
      userFirstName,
      userLastName,
      userPhone,
      userDescription,
      userEmail,
      userPassword,
      isSignIn,
      isResetPassword,
      isSignUp
    } = this.state

    return (
      <Fragment>
        {isLoading && <Loader />}

        {!isLoading && (
          <Fragment>
            <Paper className={classes.paper}>
              <Grow in={true}>
                <div>
                  <Typography variant="subtitle1" align="center">
                    {isSignIn && "Connexion à l'application"}
                    {isResetPassword && "Réinitialisation du mot de passe"}
                    {isSignUp && "Création d'un nouveau compte"}
                  </Typography>
                  <form className={classes.form}>
                    {isSignUp && (
                      <Fragment>
                        <FormControl margin="normal" fullWidth>
                          <TextField
                            id="firstName"
                            name="firstName"
                            type="text"
                            label="Prénom"
                            placeholder="Votre prénom"
                            fullWidth
                            value={userFirstName}
                            onChange={e => this.refreshFirstName(e)}
                            error={this.state.errorFirstName}
                            variant="outlined"
                            InputProps={{
                              className: classes.capitalize
                            }}
                          />
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                          <TextField
                            id="lastName"
                            name="lastName"
                            type="text"
                            label="NOM"
                            placeholder="Votre nom de famille"
                            fullWidth
                            value={userLastName}
                            onChange={e => this.refreshLastName(e)}
                            error={this.state.errorLastName}
                            variant="outlined"
                            InputProps={{
                              className: classes.uppercase
                            }}
                          />
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                          <TextField
                            id="phone"
                            name="phone"
                            type="text"
                            label="Téléphone mobile"
                            placeholder="Votre numéro de mobile"
                            fullWidth
                            value={userPhone}
                            onChange={e => this.refreshPhone(e)}
                            error={this.state.errorPhone}
                            helperText={this.state.errorPhone ? phoneHelperText : ""}
                            variant="outlined"
                            InputProps={{
                              className: classes.lowercase,
                              inputComponent: PhoneFormatter
                            }}
                          />
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                          <TextField
                            id="description"
                            name="description"
                            type="text"
                            label="Description"
                            fullWidth
                            placeholder="Téléphone fixe, Adresse d'exploitation, Numéro d'exploitation, Nom de l'alpage, Type de bétail..."
                            value={userDescription}
                            onChange={e => this.refreshDescription(e)}
                            error={this.state.errorDescription}
                            helperText="Pour accélérer votre inscription, précisez : Téléphone fixe - Adresse d'exploitation - Numéro d'exploitation - Nom de l'alpage - Type de bétail..."
                            variant="outlined"
                            multiline
                            rows="3"
                          />
                        </FormControl>
                      </Fragment>
                    )}

                    <FormControl margin="normal" fullWidth>
                      <TextField
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        fullWidth
                        placeholder="Votre adresse email"
                        value={userEmail}
                        onChange={e => this.refreshEmail(e)}
                        error={this.state.errorEmail}
                        variant="outlined"
                        InputProps={{
                          className: classes.lowercase
                        }}
                      />
                    </FormControl>

                    {(isSignIn || isSignUp) && (
                      <FormControl margin="normal" fullWidth variant="outlined">
                        <InputLabel htmlFor="password">Mot de passe</InputLabel>
                        <OutlinedInput
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          labelWidth={98}
                          fullWidth
                          placeholder="Votre mot de passe"
                          value={userPassword}
                          onChange={e => this.refreshPassword(e)}
                          error={this.state.errorPassword}
                          onKeyPress={e => {
                            e.key === "Enter" && (isSignIn ? this.handleSignIn() : this.handleSignUp())
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <Tooltip title="Afficher le mot de passe" placement="left" TransitionComponent={Zoom}>
                                <IconButton aria-label="Afficher le mot de passe" onClick={this.handleShowPassword}>
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    )}

                    {isSignIn && (
                      <Typography align="right">
                        <CustomLink href="/#" onClick={this.enableResetPassword}>
                          Mot de passe oublié ?
                        </CustomLink>
                      </Typography>
                    )}
                    {isSignIn && (
                      <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={this.handleSignIn}>
                        Se connecter
                      </Button>
                    )}
                    {isResetPassword && (
                      <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={this.handleResetPassword}>
                        Réinitialiser
                      </Button>
                    )}
                    {isSignUp && (
                      <Button fullWidth variant="contained" color="primary" size="large" className={classes.submit} onClick={this.handleSignUp}>
                        Créer un compte
                      </Button>
                    )}
                  </form>
                </div>
              </Grow>
            </Paper>
            <Typography align="center" className={classes.footer}>
              {(isSignUp || isResetPassword) && (
                <span>
                  Déjà inscrit ?{" "}
                  <CustomLink href="/#" onClick={this.enableSignIn}>
                    Se connecter
                  </CustomLink>
                </span>
              )}
              {isSignIn && (
                <span>
                  Pas encore inscrit ?{" "}
                  <CustomLink href="/#" onClick={this.enableSignUp}>
                    Créer un compte
                  </CustomLink>
                </span>
              )}
            </Typography>

            <Dialog
              fullScreen={fullScreen}
              // disableBackdropClick
              // disableEscapeKeyDown
              open={this.state.openDialog}
              onClose={this.handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Email non vérifié"}</DialogTitle>
              <DialogContent>
                <Typography>
                  Cliquez sur le lien de validation envoyé à {userEmail}
                  <br />
                  Si vous ne l'avez pas reçu, vous pouvez recevoir un nouveau mail de confirmation.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={this.handleCloseDialog}>
                  Retour connexion
                </Button>
                <Button onClick={this.handleSendEmailVerification} color="secondary" autoFocus>
                  Renvoyer lien
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.dataReducer.contacts,
    ressources: state.dataReducer.ressources
  }
}

export default withStyles(styles)(connect(mapStateToProps)(withMobileDialog()(withSnackbar(Auth))))
