const initialState = {}

function dataReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case "UPDATE_DATA_USERS":
      nextState = {
        ...state,
        users: action.value
      }
      return nextState || state
    
    // case "UPDATE_DATA_USERS_TEMP":
    //   nextState = {
    //     ...state,
    //     tempUsers: action.value
    //   }
    //   return nextState || state

    case "UPDATE_DATA_CONTACTS":
      nextState = {
        ...state,
        contacts: action.value
      }
      return nextState || state

    case "UPDATE_DATA_ALERTS":
      nextState = {
        ...state,
        alerts: action.value
      }
      return nextState || state

    case "UPDATE_DATA_RESSOURCES":
      nextState = {
        ...state,
        ressources: action.value
      }
      return nextState || state

    case "UPDATE_DATA_JOINS":
      nextState = {
        ...state,
        joins: action.value
      }
      return nextState || state
    
    case "UPDATE_CURRENT_AREA":
      nextState = {
        ...state,
        currentArea: action.value
      }
      return nextState || state

    //...
    default:
      return state
  }
}

export default dataReducer
