import * as turf from "@turf/turf"

import store from "../store/configureStore"

import { colorBlue, colorRed, colorYellow, colorDark } from "../config/colors.json"

// -----------------------------------------------------------------------------------

export function removeRessourceCircles(map) {
  if (map.getLayer("ressourceCirclesLayer")) {
    map.removeLayer("ressourceCirclesLayer")
    map.removeLayer("ressourceCirclesLineActiveLayer")
    map.removeLayer("ressourceCirclesFillActiveLayer")
    map.removeSource("ressourceCirclesSource")
  }
}

// -----------------------------------------------------------------------------------

export function disableAlertNewMode(map) {
  // console.warn("Disable : New Mode (alert)")

  store.dispatch({ type: "UPDATE_NEXT_ALERT", value: "" })
  store.dispatch({ type: "UPDATE_ALERT_CONTACTS", value: null })

  store.dispatch({ type: "TOGGLE_ALERT_POLYGON_DRAW_MODE", value: false })

  removeContactsSelectFeatures(map)
  removeRessourceCircles(map)
}

// -----------------------------------------------------------------------------------

export function enableAlertNewMode(map, ressourceLng, ressourceLat, nextAlert, contacts) {
  // console.info("Enable : New Mode (alert)")

  function createCircle(radius) {
    return turf.circle([ressourceLng, ressourceLat], radius, {
      steps: 60,
      units: "kilometers",
      properties: {
        radius: radius,
        radiusName: radius + " Km"
      }
    })
  }

  const ressourceCirclesJson = turf.featureCollection([
    createCircle(50),
    createCircle(45),
    createCircle(40),
    createCircle(35),
    createCircle(30),
    createCircle(25),
    createCircle(20),
    createCircle(15),
    createCircle(10),
    createCircle(5)
  ])

  removeContactsSelectFeatures(map)
  // removePolygonDraw()
  removeRessourceCircles(map)

  map.addSource("ressourceCirclesSource", {
    type: "geojson",
    data: ressourceCirclesJson
  })
  map.addLayer(
    {
      id: "ressourceCirclesLayer",
      type: "line",
      source: "ressourceCirclesSource",
      paint: {
        "line-width": 2,
        "line-color": colorRed,
        "line-dasharray": [1, 1.5],
        "line-opacity": 0.8
      }
    },
    map.getStyle().layers[3].id
  )
  map.addLayer(
    {
      id: "ressourceCirclesLineActiveLayer",
      type: "line",
      source: "ressourceCirclesSource",
      paint: {
        "line-width": 2,
        "line-color": colorBlue,
        "line-dasharray": [1, 1.5]
      },
      filter: ["==", "radius", ""]
    },
    map.getStyle().layers[4].id
  )
  map.addLayer(
    {
      id: "ressourceCirclesFillActiveLayer",
      type: "fill",
      source: "ressourceCirclesSource",
      paint: {
        "fill-color": colorBlue,
        "fill-outline-color": colorBlue,
        "fill-opacity": 0.1
      },
      filter: ["==", "radius", ""]
    },
    map.getStyle().layers[5].id
  )

  selectContactsByRadius(map, ressourceLng, ressourceLat, nextAlert.radius, contacts)
}

// -----------------------------------------------------------------------------------

export function removeContactsSelectFeatures(map) {
  if (map.getLayer("contactsSelectFeaturesLayer")) {
    map.removeLayer("contactsSelectFeaturesLayer")
    map.removeSource("contactsSelectFeaturesSource")
  }

  store.dispatch({ type: "UPDATE_ALERT_CONTACTS", value: null })
}

// -----------------------------------------------------------------------------------

export function selectContactsByRadius(map, ressourceLng, ressourceLat, alertRadius, contacts) {
  const radius = Number(alertRadius)

  const contactsSelectJson = {
    type: "FeatureCollection",
    features: []
  }

  const contactsJson = map.getSource("contactsFeaturesSource")._data
  contactsJson.features.forEach(contactFeature => {
    const distance = turf.distance([ressourceLng, ressourceLat], contactFeature.geometry.coordinates, {
      units: "kilometers"
    })

    if (distance <= radius) {
      contactFeature.properties.distance = Number(distance.toFixed(1))
      contactsSelectJson.features.push(contactFeature)
    }
  })

  removeContactsSelectFeatures(map)

  map.addSource("contactsSelectFeaturesSource", {
    type: "geojson",
    data: contactsSelectJson
  })

  map.addLayer({
    id: "contactsSelectFeaturesLayer",
    type: "circle",
    source: "contactsSelectFeaturesSource",
    paint: {
      "circle-radius": 10,
      "circle-color": colorYellow,
      "circle-stroke-width": 2,
      "circle-stroke-color": colorDark
    }
  })

  contactsSelectJson.features = contactsSelectJson.features.sort((feature1, feature2) => {
    return feature1.properties.distance - feature2.properties.distance
  })

  const alertContacts = {}
  contactsSelectJson.features.forEach(contact => {
    const { id, distance } = contact.properties
    alertContacts[id] = contacts[id]
    alertContacts[id].distance = distance
  })
  store.dispatch({ type: "UPDATE_ALERT_CONTACTS", value: alertContacts })

  map.setFilter("ressourceCirclesLineActiveLayer", ["<=", "radius", radius])
  map.setFilter("ressourceCirclesFillActiveLayer", ["==", "radius", radius])

  // auto zoom on selected radius circle
  const ressourceCirclesJson = map.getSource("ressourceCirclesSource")._data
  const features = ressourceCirclesJson.features
  for (let i = 0; i < features.length; i++) {
    if (features[i].properties.radius === radius) {
      const bbox = turf.bbox(features[i])
      map.fitBounds(bbox, {
        padding: 40
      })
    }
  }
}

// -----------------------------------------------------------------------------------

export function disableAlertViewMode(map) {
  // console.warn("Disable : View Mode (alert)")

  // store.dispatch({ type: "UPDATE_ACTIVE_ALERT", value: "" })
  // store.dispatch({ type: "UPDATE_ALERT_CONTACTS", value: null })

  // store.dispatch({ type: "TOGGLE_ALERT_POLYGON_DRAW_MODE", value: false })

  removeContactsSelectFeatures(map)
  removeRessourceCircles(map)
}

// -----------------------------------------------------------------------------------

export function enableAlertViewMode(map, ressourceLng, ressourceLat, activeAlert, contacts) {
  // console.info("Enable : View Mode (alert)")

  function createCircle(radius) {
    return turf.circle([ressourceLng, ressourceLat], radius, {
      steps: 60,
      units: "kilometers",
      properties: {
        radius: radius,
        radiusName: radius + " Km"
      }
    })
  }

  const ressourceCirclesJson = turf.featureCollection([
    createCircle(40),
    createCircle(35),
    createCircle(30),
    createCircle(25),
    createCircle(20),
    createCircle(15),
    createCircle(10),
    createCircle(5)
  ])

  removeContactsSelectFeatures(map)
  // removePolygonDraw()
  removeRessourceCircles(map)

  map.addSource("ressourceCirclesSource", {
    type: "geojson",
    data: ressourceCirclesJson
  })
  map.addLayer(
    {
      id: "ressourceCirclesLayer",
      type: "line",
      source: "ressourceCirclesSource",
      paint: {
        "line-width": 2,
        "line-color": colorRed,
        "line-dasharray": [1, 1.5],
        "line-opacity": 0 // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< hook
      }
    },
    map.getStyle().layers[3].id
  )
  map.addLayer(
    {
      id: "ressourceCirclesLineActiveLayer",
      type: "line",
      source: "ressourceCirclesSource",
      paint: {
        "line-width": 2,
        "line-color": colorBlue,
        "line-dasharray": [1, 1.5]
      },
      filter: ["==", "radius", ""]
    },
    map.getStyle().layers[4].id
  )
  map.addLayer(
    {
      id: "ressourceCirclesFillActiveLayer",
      type: "fill",
      source: "ressourceCirclesSource",
      paint: {
        "fill-color": colorBlue,
        "fill-outline-color": colorBlue,
        "fill-opacity": 0.1
      },
      filter: ["==", "radius", ""]
    },
    map.getStyle().layers[5].id
  )

  selectContactsByRadius(map, ressourceLng, ressourceLat, activeAlert.radius, contacts)
}
