import React, { Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"

import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { Grow } from "@material-ui/core"

import CustomLink from "../modules/CustomLink"

const styles = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  progress: {
    margin: theme.spacing.unit * 4
  },
  footer: {
    margin: theme.spacing.unit * 3
  }
})

function Loader(props) {
  const { classes } = props
  return (
    <Fragment>
      <Grow in={true}>
        <div>
          <Paper className={classes.paper}>
            <Grid align="center">
              <CircularProgress className={classes.progress} />
            </Grid>
            <Typography align="center">Chargement en cours...</Typography>
          </Paper>
          <Typography align="center" className={classes.footer}>
            Problème de connexion ? <CustomLink href="mailto:webmaster@ipra-landry.com">Nous contacter</CustomLink>
          </Typography>
        </div>
      </Grow>
    </Fragment>
  )
}

export default withStyles(styles)(Loader)
