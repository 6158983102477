import React from "react"

import { SvgIcon } from "@material-ui/core"

export default props => (
  <SvgIcon viewBox="0 0 428.39 423.04" {...props}>
    <path
      d="M244.47,260.4c4.62,48.61-32.3,81.8-70.63,82.79-40.24,1-79.25-33.27-74.36-82.93L66.31,257c-6-.59-12-.9-17.86-2-1.93-.35-3.49-2.72-5.22-4.17,1.57-1.63,2.89-4.31,4.74-4.75q28.65-6.78,57.46-12.82c3.77-.79,4.65-2.34,4.84-5.9.65-12.37,2.94-24.44,8.06-35.84,11.3-25.17,34.34-38.25,61.81-35.09,29.21,3.36,47.86,20.41,55.63,51.33A145.67,145.67,0,0,1,239,228c.33,3.14,1.17,4.54,4.44,5.3,17,3.94,33.94,8.21,50.91,12.32,3,.74,6.12,1.39,5.78,5.48-.32,3.83-3.36,4-6.25,4.29L248.14,260C247,260.07,245.88,260.23,244.47,260.4Z"
      transform="translate(-36.78 -43.62)"
    />
    <path
      d="M37.33,466.65C31.82,406.29,68.12,355.34,126,341.94a11,11,0,0,1,6.92.9c26.1,13.13,52.07,13.12,78.15,0A11.13,11.13,0,0,1,218,342c58.2,13.58,82.65,55.43,87.93,102,.84,7.4.92,14.88,1.38,22.7Zm33.9-19H274V426.39c0-13.12,0-13.24-13.23-13.31-3.75,0-4.89-1.27-4.84-4.94.18-12.32.08-24.65,0-37,0-1.28.2-3.29-.49-3.75A62.45,62.45,0,0,0,233,358.63v53.92a10.3,10.3,0,0,1-1.87.41q-57,0-113.93.09c-3.4,0-4.26-1.42-4.21-4.52.14-8.16,0-16.32,0-24.49V357.66c-6.7,2.82-12.26,5.68-18.14,7.49-5.1,1.57-5,4.89-4.91,9,.15,11.16-.09,22.32.13,33.48.08,4-1,5.83-5.3,5.49s-8.9-.08-13.55-.08Z"
      transform="translate(-36.78 -43.62)"
    />
    <path
      d="M366.33,43.62c9-.11,19.16,3,28.81,7.63,25.65,12.41,47.11,30.52,67.31,50.25,3.72,3.64,3.6,7.19-.19,10.92-18.93,18.63-39.32,35.37-62.84,48.05-22.83,12.32-45.59,12.63-68.72.78-24.7-12.66-46-29.81-66.16-48.62-4-3.77-3.93-7.86.16-11.69,19.51-18.25,40.16-35,64-47.38C339.87,47.77,351.59,43.63,366.33,43.62Zm-87.05,63.2c21.18,19.33,42.93,36.35,69.5,46.27,11,4.1,21.8,3.73,32.65-.47,17.6-6.83,33-17.26,47.47-29.14,6.27-5.14,12.19-10.71,18.65-16.43-19.59-18.91-40-35.16-64.77-45.38a46.54,46.54,0,0,0-36.41-.17c-18.32,7.26-34.45,18.06-49.69,30.32C290.88,96.48,285.4,101.52,279.28,106.82Z"
      transform="translate(-36.78 -43.62)"
    />
    <path
      d="M244.86,439c-5.11,0-9.88-4.91-9.86-10.09,0-4.65,5.51-10,10.16-9.91,4.83.11,9.89,5.54,9.84,10.57S250.2,439,244.86,439Z"
      transform="translate(-36.78 -43.62)"
    />
    <path
      d="M112,429.52c0,5.56-3.65,9.46-8.89,9.48-4.75,0-10.61-5.15-11-9.69-.28-3.38,6.66-10.29,10.35-10.3C107.54,419,112,423.91,112,429.52Z"
      transform="translate(-36.78 -43.62)"
    />
    <path
      d="M363.5,147.16a40.33,40.33,0,1,1,40.27-40.37A40.12,40.12,0,0,1,363.5,147.16Zm-7.19-54.91a11.3,11.3,0,1,0-11.55,11.22A11.11,11.11,0,0,0,356.31,92.25Z"
      transform="translate(-36.78 -43.62)"
    />
  </SvgIcon>
)
