import React, { Component } from "react"
import { connect } from "react-redux"

import { Dialog } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogContentText } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { Button } from "@material-ui/core"

class NoAreaDialog extends Component {
  state = {
    openDialog: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // START : Open dialog if user has no area
      this.setState({ openDialog: this.props.openNoAreaDialog })
      // STOP : Open dialog if user has no area
    }
  }

  render() {
    const { openDialog } = this.state

    const handleCloseDialog = () => this.setState({ openDialog: false })

    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="noarea-dialog-title" aria-describedby="noarea-dialog-description">
        <DialogTitle id="noarea-dialog-title">{"Comment s'inscrire sur un territoire ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="noarea-dialog-description">
            Pour vous inscrire sur un territoire, cliquez sur le <b>bouton orange</b> (en haut à gauche de la carte) puis ajoutez un point dans la zone où se
            situe votre troupeau.
            <br />
            <br />
            Un gestionnaire du territoire validera ensuite votre inscription et vous pourrez commencer à recevoir les signalements par SMS ou email.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    openNoAreaDialog: state.appReducer.openNoAreaDialog
  }
}

export default connect(mapStateToProps)(NoAreaDialog)
