import React from "react"

import { IconButton } from "@material-ui/core"

import CloseIcon from "@material-ui/icons/Close"

// Function Display message
export function message(variant, text) {
  window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true
  this.props.enqueueSnackbar(text, {
    variant: variant,
    autoHideDuration: 6000,
    action: (
      <IconButton color="inherit">
        <CloseIcon />
      </IconButton>
    )
  })
}
// END : Function Display message

// Functions Toggle full screen
export function requestFullscreen() {
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen()
  } else if (document.documentElement.webkitRequestFullscreen) {
    document.documentElement.webkitRequestFullscreen()
  } else if (document.documentElement.msRequestFullscreen) {
    document.documentElement.msRequestFullscreen()
  } else alert("Impossible de passer en mode plein écran sur cet appareil...")
}
export function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
}
// END : Functions Toggle full screen
