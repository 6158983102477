import React, { Component } from "react"
import classNames from "classnames"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Drawer } from "@material-ui/core"
import { AppBar } from "@material-ui/core"
import { Toolbar } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Avatar } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
import { Chip } from "@material-ui/core"

import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"

import Menu from "./Menu"
import ToolbarShortcuts from "./ToolbarShortcuts"
import MapContainer from "./MapContainer"
import Account from "./Account"
import Users from "./Users"
import About from "./About"
import Contacts from "./Contacts"
import Test from "./Test"
import AreaDialog from "./AreaDialog"

import LogoIcon from "../images/icon-192.png"
import Ressources from "./Ressources"

const drawerWidth = 240

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    backgroundColor: theme.palette.grey[800],
    boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)", // shadow bottom
    width: `calc(100% - ${theme.spacing.unit * 7 + 1}px)`,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.spacing.unit * 9 + 1}px)`
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  menuButtonMobile: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  hide: {
    display: "none"
  },
  drawer: {
    zIndex: theme.zIndex.drawer + 2,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  drawerOpen: {
    boxShadow: "3px 0px 5px -1px rgba(0, 0, 0, 0.2), 6px 0px 10px 0px rgba(0, 0, 0, 0.14), 1px 0px 18px 0px rgba(0, 0, 0, 0.12)", // shadow right
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    boxShadow: "3px 0px 5px -1px rgba(0, 0, 0, 0.2), 6px 0px 10px 0px rgba(0, 0, 0, 0.14), 1px 0px 18px 0px rgba(0, 0, 0, 0.12)", // shadow right
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("md")]: {
      width: theme.spacing.unit * 9 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    flexGrow: 1
    // padding: theme.spacing.unit * 3
  },
  drawerHeader: {
    paddingLeft: theme.spacing.unit,
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing.unit * 2
    }
  },
  drawerLogo: {
    paddingRight: theme.spacing.unit * 2
  },
  grow: {
    flexGrow: 1
  }
})

class Dashboard extends Component {
  state = {
    open: false,
    openMobile: false
  }

  toggleDrawer = () => {
    this.setState(state => ({ open: !state.open }))
    // Resize windows = Resize map canvas
    setTimeout(() => {
      this.props.dispatch({ type: "TOGGLE_DRAWER_MENU", value: !this.props.toggleDrawerMenu })
    }, 200)
  }

  toggleDrawerMobile = () => {
    this.setState(state => ({ openMobile: !state.openMobile }))
  }

  render() {
    const { classes } = this.props

    return (
      <BrowserRouter>
        <div className={classes.root}>
          <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: this.state.open
            })}
          >
            <Toolbar disableGutters={!this.state.open}>
              <Tooltip title="Menu" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.toggleDrawer}
                  className={classNames(classes.menuButton, {
                    [classes.hide]: this.state.open
                  })}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Menu" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.toggleDrawerMobile}
                  className={classNames(classes.menuButtonMobile, {
                    [classes.hide]: this.state.openMobile
                  })}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>

              <Typography variant="h6" color="inherit" noWrap>
                {/* Canton de Vaud */}
                {/* Haut-Rhin <small> - bêta version</small> */}
                Proxiloup <small>
                <Chip label="bêta" variant="outlined" color="secondary" style={{marginLeft:"20px"}} /></small>
              </Typography>

              <div className={classes.grow} />

              <ToolbarShortcuts />
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open
              })
            }}
            open={this.state.open}
          >
            <div>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                onClick={this.toggleDrawer}
                style={{ flexWrap: "nowrap", cursor: "pointer" }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.drawerHeader + " " + classes.toolbar}
                    style={{ flexWrap: "nowrap" }}
                  >
                    <Grid item className={classes.drawerLogo}>
                      <Tooltip title="Menu" placement="right" TransitionComponent={Zoom} disableFocusListener>
                        <Avatar alt="Proxiloup" src={LogoIcon} />
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">Proxiloup</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Tooltip title="Fermer Menu" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
                    <IconButton>
                      <ChevronLeftIcon />
                      {/* <CloseIcon /> */}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
            <Menu />
          </Drawer>

          <Drawer open={this.state.openMobile} onClose={this.toggleDrawerMobile}>
            <div>
              <Grid container direction="row" justify="space-between" alignItems="center" onClick={this.toggleDrawerMobile} style={{ flexWrap: "nowrap" }}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.drawerHeader + " " + classes.toolbar}
                    style={{ flexWrap: "nowrap" }}
                  >
                    <Grid item className={classes.drawerLogo}>
                      <Avatar alt="Proxiloup" src={LogoIcon} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">Proxiloup</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Tooltip title="Fermer Menu" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
                    <IconButton>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
            <Menu onClick={this.toggleDrawerMobile} />
          </Drawer>

          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route exact path="/" component={MapContainer} />
              <Route path="/evenements" component={Ressources} />
              <Route path="/troupeaux" component={Contacts} />
              <Route path="/compte" component={Account} />

              <Route path="/utilisateurs" component={Users} />
              <Route path="/notifications" component={Test} />
              <Route path="/recherches" component={Test} />
              <Route path="/stats" component={Test} />
              
              <Route path="/reglages" component={Test} />
              <Route path="/aide" component={About} />
            </Switch>
          </main>

          <AreaDialog />
        </div>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => {
  return {
    toggleDrawerMenu: state.appReducer.toggleDrawerMenu
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Dashboard))
