import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Grid, Typography } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { Fab } from "@material-ui/core"
import { Popover } from "@material-ui/core"
import { List } from "@material-ui/core"
import { ListItem } from "@material-ui/core"
import { ListItemIcon } from "@material-ui/core"
import { ListItemText } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { Switch } from "@material-ui/core"
import { Fade } from "@material-ui/core"
import { Grow } from "@material-ui/core"

import LayerIcon from "@material-ui/icons/Layers"
import MapRotationIcon from "@material-ui/icons/ThreeDRotation"
import AddLocationIcon from "@material-ui/icons/AddLocation"
// import ColorIcon from "@material-ui/icons/ColorLens"
// import LightIcon from "@material-ui/icons/Brightness5"
// import DarkIcon from "@material-ui/icons/Brightness7"
// import SatelliteIcon from "@material-ui/icons/Satellite"
// import WalkIcon from "@material-ui/icons/DirectionsWalk"
// import TerrainIcon from "@material-ui/icons/Terrain"
import ZoomInIcon from "@material-ui/icons/ZoomIn"
import ZoomOutIcon from "@material-ui/icons/ZoomOut"
import GpsOnIcon from "@material-ui/icons/GpsFixed"
import GpsOffIcon from "@material-ui/icons/GpsNotFixed"
import ContactIcon from "@material-ui/icons/PersonPinCircle"
import RessourceIcon from "@material-ui/icons/Warning"
import CancelIcon from "@material-ui/icons/Cancel"
import ConfirmIcon from "@material-ui/icons/CheckCircle"

import Map from "./Map"
import ContactContainer from "./ContactContainer"
import RessourceContainer from "./RessourceContainer"

import streetsStyleImage from "../images/mapbox-streets.jpg"
import lightStyleImage from "../images/mapbox-light.jpg"
import darkStyleImage from "../images/mapbox-dark.jpg"
import satelliteStyleImage from "../images/mapbox-satellite.jpg"
import outdoorsStyleImage from "../images/mapbox-outdoors.jpg"
import shadowsStyleImage from "../images/mapbox-shadows.jpg"

import { styles as mapboxStyles } from "../config/mapbox.json"

console.log("MapContainer.js => window.innerHeight", window.innerHeight)

const colorRed = "#b71c1c"
const colorGreen = "#558b2f"

const styles = theme => ({
  mainCanvas: {
    flexGrow: 1
  },

  mapCanvas: {
    flexGrow: 1,

    // SMALL SCREEN
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 64px)",
      // height: "calc(100vh - 150px  - 64px)",
      width: "100%",
      position: "fixed"
    },

    // LARGE SCREEN
    [theme.breakpoints.up("md")]: {
      // height: "100%",
      position: "relative"
    }
  },

  infoPanel: {
    // flexGrow: 1,
    width: "100%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main, // Overridden by activeContact or activeRessource
    // backgroundColor: theme.palette.grey[100],
    zIndex: theme.zIndex.drawer,
    padding: theme.spacing.unit * 2,

    // SMALL SCREEN
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "calc(100vh - 150px)",
      // order: 2,
      boxShadow: "0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px 0px rgba(0, 0, 0, 0.14), 0px -1px 18px 0px rgba(0, 0, 0, 0.12)" // shadow top
    },

    // LARGE SCREEN
    [theme.breakpoints.up("md")]: {
      position: "relative",
      top: "0",
      overflowY: "auto",
      // order: 0,
      // boxShadow: "3px 0px 5px -1px rgba(0, 0, 0, 0.2), 6px 0px 10px 0px rgba(0, 0, 0, 0.14), 1px 0px 18px 0px rgba(0, 0, 0, 0.12)" // shadow right
      boxShadow: "-3px 0px 5px -1px rgba(0, 0, 0, 0.2), -6px 0px 10px 0px rgba(0, 0, 0, 0.14), -1px 0px 18px 0px rgba(0, 0, 0, 0.12)" // shadow left
    }
  },
  mapTopLeft: {
    position: "absolute",
    width: "auto",
    top: theme.spacing.unit * 2,
    left: theme.spacing.unit * 2,
    zIndex: theme.zIndex.drawer
  },
  mapTopRight: {
    position: "absolute",
    width: "auto",
    top: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    zIndex: theme.zIndex.drawer
  },
  mapBottomCenter: {
    position: "absolute",
    width: "auto",
    bottom: theme.spacing.unit * 2,
    left: "50%",
    transform: "translate(-50%)",
    margin: "0 auto",
    zIndex: theme.zIndex.drawer
  },
  extendedButton: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    }
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  mapBottomActions: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.drawer,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[800],
    // backgroundColor: theme.palette.secondary.dark,
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 2,
    boxShadow: "0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px 0px rgba(0, 0, 0, 0.14), 0px -1px 18px 0px rgba(0, 0, 0, 0.12)" // shadow top
  },
  cancelButton: {
    color: theme.palette.error.main,
    margin: theme.spacing.unit
  },
  confirmButton: {
    color: theme.palette.primary.dark,
    margin: theme.spacing.unit
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20
  },
  lightButton: {
    backgroundColor: theme.palette.background.paper
  }
})

class MapContainer extends Component {
  state = {
    openChangeLayerPopover: false,
    anchorChangeLayerButton: null,
    openAddFeaturePopover: false,
    anchorAddFeatureButton: null
  }

  handleOpenPopover = event => {
    this.setState({
      anchorChangeLayerButton: event.currentTarget
    })
  }
  handleOpenAddFeaturePopover = event => {
    this.setState({
      anchorAddFeatureButton: event.currentTarget
    })
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: false })
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: false })
  }

  handleClosePopover = () => {
    this.setState({
      anchorChangeLayerButton: null,
      anchorAddFeatureButton: null
    })
  }

  enableContactPointDrawMode = () => {
    this.props.dispatch({ type: "TOGGLE_CONTACT_POINT_DRAW_MODE", value: true })
  }
  disableContactPointDrawMode = () => {
    this.props.dispatch({ type: "TOGGLE_CONTACT_POINT_DRAW_MODE", value: false })
  }
  enableContactFeatureNewMode = () => {
    this.props.dispatch({ type: "TOGGLE_CONTACT_NEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: false })
  }

  enableRessourcePointDrawMode = () => {
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_POINT_DRAW_MODE", value: true })
  }
  disableRessourcePointDrawMode = () => {
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_POINT_DRAW_MODE", value: false })
  }
  enableRessourceFeatureNewMode = () => {
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_NEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: false })
  }

  handleChangeLayer = style => {
    this.props.dispatch({ type: "UPDATE_BASE_MAP_STYLE", value: style })
  }

  handleToggleElevationShadows = () => {
    this.props.dispatch({ type: "TOGGLE_ELEVATION_SHADOWS", value: !this.props.elevationShadows })
  }

  handleToggle3dView = () => {
    this.props.dispatch({ type: "TOGGLE_3D_VIEW", value: !this.props.is3dView })
  }

  handleZoomIn = () => {
    this.props.dispatch({ type: "ZOOM_IN_OUT", value: "in" })
  }
  handleZoomOut = () => {
    this.props.dispatch({ type: "ZOOM_IN_OUT", value: "out" })
  }

  handleToggleGeolocateUser = () => {
    this.props.dispatch({ type: "TOGGLE_USER_GEOLOCATION", value: !this.props.userGeolocation })
  }

  disableAlertPolygonDrawMode = () => {
    this.props.dispatch({ type: "TOGGLE_ALERT_POLYGON_DRAW_MODE", value: false })
  }

  render() {
    const { classes } = this.props
    const { anchorChangeLayerButton, anchorAddFeatureButton } = this.state
    const openChangeLayerPopover = Boolean(anchorChangeLayerButton)
    const openAddFeaturePopover = Boolean(anchorAddFeatureButton)

    return (
      <Fade in={true}>
        <Grid container direction="row" justify="flex-start" alignItems="stretch" className={classes.mainCanvas}>
          <Grid item className={classes.mapCanvas}>
            {/* MAP TOP LEFT */}
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={8} className={classes.mapTopLeft}>
              <Grid item>
                <Tooltip title="Changer le fond de carte" placement="right" TransitionComponent={Zoom} disableFocusListener>
                  <Fab size="small" color="primary" onClick={this.handleOpenPopover}>
                    <LayerIcon />
                  </Fab>
                </Tooltip>
                <Popover
                  id="changeLayerPopover"
                  open={openChangeLayerPopover}
                  anchorEl={anchorChangeLayerButton}
                  onClose={this.handleClosePopover}
                  onClick={this.handleClosePopover}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                >
                  <List>
                    <ListItem button onClick={() => this.handleChangeLayer(mapboxStyles.streets)}>
                      <img width="40" alt="streets" src={streetsStyleImage} />
                      <ListItemText primary="Fond Couleur" />
                      <Switch checked={this.props.baseMapStyle === mapboxStyles.streets} />
                    </ListItem>

                    <ListItem button onClick={() => this.handleChangeLayer(mapboxStyles.light)}>
                      <img width="40" alt="light" src={lightStyleImage} />
                      <ListItemText primary="Fond Clair" />
                      <Switch checked={this.props.baseMapStyle === mapboxStyles.light} />
                    </ListItem>

                    <ListItem button onClick={() => this.handleChangeLayer(mapboxStyles.dark)}>
                      <img width="40" alt="dark" src={darkStyleImage} />
                      <ListItemText primary="Fond Sombre" />
                      <Switch checked={this.props.baseMapStyle === mapboxStyles.dark} />
                    </ListItem>

                    <ListItem button onClick={() => this.handleChangeLayer(mapboxStyles.satellite)}>
                      <img width="40" alt="satellite" src={satelliteStyleImage} />
                      <ListItemText primary="Fond Satellite" />
                      <Switch checked={this.props.baseMapStyle === mapboxStyles.satellite} />
                    </ListItem>

                    <ListItem button onClick={() => this.handleChangeLayer(mapboxStyles.outdoors)}>
                      <img width="40" alt="outdoors" src={outdoorsStyleImage} />
                      <ListItemText primary="Fond Terrain" />
                      <Switch checked={this.props.baseMapStyle === mapboxStyles.outdoors} />
                    </ListItem>

                    <Divider />

                    <ListItem button onClick={this.handleToggleElevationShadows}>
                      <img width="40" alt="shadows" src={shadowsStyleImage} />
                      <ListItemText primary="Ombres du relief" />
                      <Switch checked={this.props.elevationShadows} />
                    </ListItem>
                  </List>
                </Popover>
              </Grid>
              <Grid item>
                <Tooltip title="Basculer la perspective" placement="right" TransitionComponent={Zoom} disableFocusListener>
                  <Fab size="small" color="primary" onClick={this.handleToggle3dView}>
                    <MapRotationIcon />
                  </Fab>
                </Tooltip>
              </Grid>
              {!this.props.contactPointDrawMode && !this.props.ressourcePointDrawMode && (
                <Grid item>
                  <Tooltip title="Ajouter un point" placement="right" TransitionComponent={Zoom} disableFocusListener>
                    <Fab size="small" color="secondary" onClick={this.handleOpenAddFeaturePopover}>
                      <AddLocationIcon />
                    </Fab>
                  </Tooltip>
                  <Popover
                    id="addFeaturePopover"
                    open={openAddFeaturePopover}
                    anchorEl={anchorAddFeatureButton}
                    onClose={this.handleClosePopover}
                    onClick={this.handleClosePopover}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    <List>
                      <ListItem button onClick={this.enableContactPointDrawMode}>
                        <ListItemIcon>
                          <ContactIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ajouter un troupeau" />
                      </ListItem>
                      {(this.props.userGroup === "admin" || this.props.userGroup === "observer" || this.props.userGroup === "inspector") && (
                        <ListItem button onClick={this.enableRessourcePointDrawMode}>
                          <ListItemIcon>
                            <RessourceIcon />
                          </ListItemIcon>
                          <ListItemText primary="Ajouter un évènement" />
                        </ListItem>
                      )}
                    </List>
                  </Popover>
                </Grid>
              )}
            </Grid>

            {/* MAP BOTTOM */}
            {this.props.contactPointDrawMode && !this.props.activeContact && (
              <Grow in={true}>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.mapBottomActions}>
                  {this.props.newPointFeature ? (
                    <Tooltip title="Continuer l'opération" placement="top" TransitionComponent={Zoom} disableFocusListener>
                      <Button variant="contained" className={classes.confirmButton} onClick={this.enableContactFeatureNewMode}>
                        <ConfirmIcon className={classes.buttonIcon} />
                        Continuer
                      </Button>
                    </Tooltip>
                  ) : (
                      <Typography align="center" color="inherit">
                        Cliquez sur la carte pour ajouter un point...
                      </Typography>
                    )}
                  <Tooltip title="Annuler l'opération" placement="top" TransitionComponent={Zoom} disableFocusListener>
                    <Button variant="contained" className={classes.cancelButton} onClick={this.disableContactPointDrawMode}>
                      <CancelIcon className={classes.buttonIcon} />
                      Annuler
                    </Button>
                  </Tooltip>
                </Grid>
              </Grow>
            )}
            {this.props.ressourcePointDrawMode && !this.props.activeRessource && (
              <Grow in={true}>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.mapBottomActions}>
                  {this.props.newPointFeature ? (
                    <Tooltip title="Continuer l'opération" placement="top" TransitionComponent={Zoom} disableFocusListener>
                      <Button variant="contained" className={classes.confirmButton} onClick={this.enableRessourceFeatureNewMode}>
                        <ConfirmIcon className={classes.buttonIcon} />
                        Continuer
                      </Button>
                    </Tooltip>
                  ) : (
                      <Typography align="center" color="inherit">
                        Cliquez sur la carte pour ajouter un point...
                      </Typography>
                    )}
                  <Tooltip title="Annuler l'opération" placement="top" TransitionComponent={Zoom} disableFocusListener>
                    <Button variant="contained" className={classes.cancelButton} onClick={this.disableRessourcePointDrawMode}>
                      <CancelIcon className={classes.buttonIcon} />
                      Annuler
                    </Button>
                  </Tooltip>
                </Grid>
              </Grow>
            )}
            {this.props.alertPolygonDrawMode && (
              <Grow in={true}>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.mapBottomActions}>
                  <Typography align="center" color="inherit">
                    Cliquez sur la carte pour ajouter des points...
                  </Typography>
                  <Tooltip title="Annuler l'opération" placement="top" TransitionComponent={Zoom} disableFocusListener>
                    <Button variant="contained" className={classes.cancelButton} onClick={this.disableAlertPolygonDrawMode}>
                      <CancelIcon className={classes.buttonIcon} />
                      Annuler
                    </Button>
                  </Tooltip>
                </Grid>
              </Grow>
            )}

            {/* MAP TOP RIGHT */}
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={8} className={classes.mapTopRight}>
              <Grid item>
                <Tooltip title="Zoom avant" placement="left" TransitionComponent={Zoom} disableFocusListener>
                  <Fab size="small" className={classes.lightButton} onClick={this.handleZoomIn}>
                    <ZoomInIcon />
                  </Fab>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Zoom arrière" placement="left" TransitionComponent={Zoom} disableFocusListener>
                  <Fab size="small" className={classes.lightButton} onClick={this.handleZoomOut}>
                    <ZoomOutIcon />
                  </Fab>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  title={this.props.userGeolocation ? "Désactiver la géolocalisation" : "Activer la géolocalisation"}
                  placement="left"
                  TransitionComponent={Zoom}
                  disableFocusListener
                >
                  <Fab size="small" className={classes.lightButton} onClick={this.handleToggleGeolocateUser}>
                    {this.props.userGeolocation ? <GpsOnIcon /> : <GpsOffIcon />}
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>

            {/* MAP CANVAS */}
            <Map />
          </Grid>

          {/* ACTIVE FEATURE CONTAINER */}
          {(this.props.activeContact || this.props.activeRessource) && (
            <Grid
              item
              md={4}
              className={classes.infoPanel}
              style={{ backgroundColor: (this.props.activeContact && colorGreen) || (this.props.activeRessource && colorRed) }}
            >
              {/* <Grid container spacing={16} style={{ padding: "8px" }}> */}
              <ContactContainer />
              <RessourceContainer />
              {/* </Grid> */}
            </Grid>
          )}
        </Grid>
      </Fade>
    )
  }
}

const mapStateToProps = state => {
  return {
    userGroup: state.userReducer.userGroup,

    baseMapStyle: state.mapReducer.baseMapStyle,
    elevationShadows: state.mapReducer.elevationShadows,
    is3dView: state.mapReducer.is3dView,
    zoomDirection: state.mapReducer.zoomDirection,
    userGeolocation: state.mapReducer.userGeolocation,
    contactViewMode: state.mapReducer.contactViewMode,
    contactUpdateMode: state.mapReducer.contactUpdateMode,
    contactNewMode: state.mapReducer.contactNewMode,
    ressourceViewMode: state.mapReducer.ressourceViewMode,
    ressourceUpdateMode: state.mapReducer.ressourceUpdateMode,
    ressourceNewMode: state.mapReducer.ressourceNewMode,
    activeContact: state.mapReducer.activeContact,
    activeRessource: state.mapReducer.activeRessource,
    newPointFeature: state.mapReducer.newPointFeature,
    contactPointDrawMode: state.mapReducer.contactPointDrawMode,
    ressourcePointDrawMode: state.mapReducer.ressourcePointDrawMode,
    alertPolygonDrawMode: state.mapReducer.alertPolygonDrawMode
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(MapContainer))
