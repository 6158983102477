import store from "../store/configureStore"

import { token as mapboxToken } from "../config/mapbox.json"

// Function Change base map style function
export function changeBaseMapStyle(map, baseMapStyle) {
  if (map.getLayer("baseMapLayer")) {
    map.removeLayer("baseMapLayer")
    map.removeSource("baseMapSource")
  }

  let layer = null
  if (map.getStyle().layers[1]) {
    layer = map.getStyle().layers[1].id
  } else {
    layer = null
  }

  map.addSource("baseMapSource", {
    type: "raster",
    tiles: [`https://api.mapbox.com/styles/v1/mapbox/${baseMapStyle}/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`],
  })

  map.addLayer(
    {
      id: "baseMapLayer",
      type: "raster",
      source: "baseMapSource",
      minzoom: 0,
      maxzoom: 20,
      paint: {
        "raster-opacity": 0.8
      }
    },
    layer
  )
}
// END : Function Change base map style function

// Function Toggle map elevation shadow
export function toggleElevationShadows(map) {
  if (map.getLayer("hillshading")) {
    map.removeLayer("hillshading")
    map.removeSource("dem")
  } else {
    map.addSource("dem", {
      type: "raster-dem",
      url: "mapbox://mapbox.terrain-rgb"
    })
    map.addLayer(
      {
        id: "hillshading",
        source: "dem",
        type: "hillshade"
      },
      map.getStyle().layers[2].id
    )
  }
}
// END : Function Toggle map elevation shadow

// Function Toggle map 3D view
export function toggle3dView(map) {
  map.flyTo({
    speed: 0.8,
    pitch: map.getPitch() === 0 ? 60 : 0
  })
}
// END : Function Toggle map 3D view

// Function Zoom in or Zoom out
export function zoomInOut(map, zoomDirection) {
  if (zoomDirection === "in") {
    map.zoomIn()
  } else if (zoomDirection === "out") {
    map.zoomOut()
  }
  store.dispatch({ type: "ZOOM_IN_OUT", value: null })
}
// END : Function Zoom in or Zoom out

// Function Fly to position
export function flyToPosition(map, lng, lat, zoomLevel) {
  map.flyTo({
    center: [lng, lat],
    zoom: zoomLevel,
    bearing: 0,
    speed: 0.8,
    curve: 1,
    easing: function (t) {
      return t
    }
  })
}
export function centerMapToPosition(map, lng, lat) {
  map.flyTo({
    center: [lng, lat],
    speed: 2,
    curve: 1,
    easing: function (t) {
      return t
    }
  })
}
// END : Function Fly to position
