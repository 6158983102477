import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Typography } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { Card } from "@material-ui/core"
import { CardContent } from "@material-ui/core"

import CustomLink from "../modules/CustomLink"

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[100],
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  }
})

class Test extends Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Card>
          <CardContent>
            <Typography variant="h5">Lorem ipsum dolor...</Typography>
            <Divider className={classes.divider} />
            <Typography>
              Proxiloup est un outil d'aide à la gestion en temps réel de la protection des troupeaux, permettant d'informer un réseau d'éleveurs et de bergers
              par SMS en cas de présence suspectée de prédateurs sur le territoire.
            </Typography>
            <Typography>
              En savoir plus :{" "}
              <CustomLink href="https://www.ipra-landry.com" target="_blank" className={classes.nowrap}>
                www.ipra-landry.com
              </CustomLink>
            </Typography>
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default withStyles(styles)(connect()(Test))
