import React, { Component, Fragment } from "react"
import { connect } from "react-redux"

import { Dialog } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { Button } from "@material-ui/core"

import { withSnackbar } from "notistack"

import firebase from "../config/firebase.js"
import areasName from "../data/areasName.json"

import { message } from "../functions/appInterface"
import { sendEmail } from "../functions/apiLambda"

import { translation } from "../config/languages.json"

class AreaDialog extends Component {
  // Define alert message context
  message = message
  // END : Define alert message context

  handleSubscribeArea = () => {
    const { userFirstName, userLastName, userEmail, userPhone, userGroup, currentArea, userAreasTemp } = this.props

    if (currentArea) {
      // Area is available : send request to area managers
      if (currentArea.status === "available") {
        let nextUserAreasTemp
        if (!userAreasTemp) {
          nextUserAreasTemp = [...[currentArea.shortcode]]
        } else {
          if (userAreasTemp.includes(currentArea.shortcode)) {
            this.message("warning", "Votre demande a déjà été envoyée")
          } else {
            nextUserAreasTemp = [...userAreasTemp, ...[currentArea.shortcode]]
          }
        }

        if (nextUserAreasTemp !== undefined) {
          firebase
            .database()
            .ref("/users/" + firebase.auth().currentUser.uid)
            .update({ areastemp: nextUserAreasTemp }, error => {
              if (error) {
                // Write failed...
                console.error("Error c403a9ad-cce1-49a8-ace4-44e0092506e5", error)
                this.message("error", "Erreur : " + error.message)
              } else {
                // Write success...
                this.message("success", "Demande envoyée !")
                // Save to Redux global state
                this.props.dispatch({ type: "UPDATE_USER_AREAS_TEMP", value: nextUserAreasTemp })

                // Send email notification to area managers
                firebase
                  .database()
                  .ref("/users/")
                  .once("value")
                  .then(snapshot => {
                    const users = snapshot.val()

                    const recipients = Object.keys(users)
                      .filter(key => ["manager", "admin"].includes(users[key].group))
                      .filter(key => users[key].areas.includes(currentArea.shortcode))
                      .reduce((obj, key) => {
                        obj[key] = users[key]
                        return obj
                      }, {})

                    Object.keys(recipients).forEach(key => {
                      const userName = userFirstName + " " + userLastName

                      const currentAreaTitle = areasName[currentArea.shortcode] + " (" + currentArea.shortcode + ")"

                      const mailFrom = `"Proxiloup.com" <contact@proxiloup.com>` // verified AWS email
                      const mailReplyTo = ``
                      const mailTo = recipients[key].email
                      const mailSubject = userName + " souhaite s'inscrire sur votre territoire"
                      const mailBody = `
                        <html>
                          <head>
                          <title>${mailSubject}</title>
                          </head>
                          <body>
                            <p>Un utilisateur souhaite s'inscrire sur votre territoire.</p>
                            <p>
                              INFORMATIONS<br />
                              <b>- Territoire : </b>${currentAreaTitle}<br />
                              <b>- Nom : </b>${userName}<br />
                              <b>- Email : </b>${userEmail}<br />
                              <b>- Phone : </b>${userPhone}<br />
                              <b>- Groupe : </b>${translation.users.groups[userGroup]["fr"]}
                            </p>
                            <p>Pour gérer les droits d'accès à votre territoire, rendez-vous sur la page "utilisateurs"</p>
                            <p>
                            --
                            </p>
                            <p><em>PS : Ceci est un message automatique envoyé via l'application Proxiloup.com</em></p>
                          </body>
                        </html>
                      `

                      sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody).then(response => {
                        // console.log("response : ", response)
                        if (response.status === 200) {
                          // SUCCESS
                          this.setState({ isLoading: false, submit: "success" })
                        } else {
                          // ERROR
                          this.setState({ isLoading: false, submit: "error" })
                          console.error("Error 7ddad7ce-1c14-4d86-bc83-d521299f7b8f", response)
                          this.message("error", "Erreur lors de l'envoi par email")
                        }
                        return response.json()
                      })
                    })
                  })
                  .catch(error => {
                    console.error("Error 87411701-418b-4cce-b0bd-bc15aca0aa5d", error)
                    // this.message("error", "Erreur : " + error.message)
                  })

                // END Send email notification to area managers
              }
            })
        }
      }
      // END Area is available : send request to area managers

      // Area isn't available : send request to app administrator
      if (currentArea.status === "unavailable") {
        // Send email notification
        const userName = userFirstName + " " + userLastName

        const currentAreaTitle = currentArea.name + " (" + currentArea.shortcode + ")"

        const mailFrom = `"Proxiloup.com" <contact@proxiloup.com>` // verified AWS email
        const mailReplyTo = `"${userName}" <${userEmail}>`
        const mailTo = "lyon.gus@gmail.com"
        const mailSubject = "Demande d'activation d'un territoire : " + currentAreaTitle
        const mailBody = `
          <html>
            <head>
            <title>${mailSubject}</title>
            </head>
            <body>
              <p>Un utilisateur souhaite activer un nouveau territoire.</p>
              <p>
                INFORMATIONS<br />
                <b>- Territoire : </b>${currentAreaTitle}<br />
                <b>- Nom : </b>${userName}<br />
                <b>- Email : </b>${userEmail}<br />
                <b>- Phone : </b>${userPhone}<br />
                <b>- Groupe : </b>${translation.users.groups[userGroup]["fr"]}
              </p>
              <p>
              --
              </p>
              <p><em>PS : Ceci est un message automatique envoyé via l'application Proxiloup.com</em></p>
            </body>
          </html>
        `

        sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody).then(response => {
          // console.log("response : ", response)
          if (response.status === 200) {
            // SUCCESS
            this.setState({ isLoading: false, submit: "success" })
            this.message("success", "Demande envoyée !")
          } else {
            // ERROR
            this.setState({ isLoading: false, submit: "error" })
            console.error("Error 5b0ebe9a-e46d-4ffa-b9d9-5600a17d2f1b", response)
            this.message("error", "Erreur lors de l'envoi par email")
          }
          return response.json()
        })
        // END Send email notification
      }
      // END Area isn't available : send request to app administrator
    } else {
      console.error("Error : 'currentArea' is undefined")
      this.message("error", "Erreur : Territoire non reconnu")
    }

    // Exit new point mode
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: null })
    this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_CONTACT_POINT_DRAW_MODE", value: false })
    this.props.dispatch({ type: "TOGGLE_CONTACT_NEW_MODE", value: false })

    this.props.dispatch({ type: "OPEN_AREA_DIALOG", value: false })
  }

  refreshArea = e => {
    const shortcode = e.target.value
    const nextArea = Object.assign({}, this.props.currentArea)
    nextArea.shortcode = shortcode
    nextArea.name = areasName[shortcode]
    this.props.dispatch({ type: "UPDATE_CURRENT_AREA", value: nextArea })
  }

  render() {
    const { currentArea } = this.props
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.props.openAreaDialog}
        onClose={this.handleCloseDialog}
        aria-labelledby="subscribe-area-dialog-title"
        aria-describedby="subscribe-area-dialog-description"
      >
        <DialogTitle id="subscribe-area-dialog-title">{"S'inscrire sur un territoire"}</DialogTitle>
        <DialogContent>
          <Typography style={{ marginTop: "8px" }}>
            L'inscription sur un nouveau territoire vous permet d'y ajouter des données : indices de présence, troupeaux, éleveurs, bergers...
          </Typography>
          {currentArea && (
            <Typography variant="h6" style={{ marginTop: "16px", marginBottom: "16px" }}>
              <b>
                {currentArea.shortcode.substring(3, 5)} - {currentArea.name} (<small>{currentArea.shortcode.substring(0, 2)}</small>)
              </b>
            </Typography>
          )}
          {currentArea && currentArea.status === "available" && (
            <Fragment>
              <Typography style={{ marginTop: "8px" }}>Voulez-vous envoyer une demande d'inscription aux gestionnaires de ce territoire ?</Typography>
              {/* <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="currentArea">Territoire</InputLabel>
                <Select
                  value={currentArea ? currentArea.shortcode : ""}
                  onChange={e => this.refreshArea(e)}
                  inputProps={{
                    id: "currentArea",
                    name: "currentArea"
                  }}
                >
                  <MenuItem value="">
                    <em>Choisir...</em>
                  </MenuItem>
                  {areasName &&
                    Object.keys(areasName).map(shortcode => (
                      <MenuItem key={shortcode} value={shortcode}>
                        {shortcode.substring(3, 5)} - {areasName[shortcode]} ({shortcode.substring(0, 2)})
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </Fragment>
          )}
          {currentArea && currentArea.status === "unavailable" && (
            <Fragment>
              <Typography style={{ marginTop: "8px" }}>
                Ce territoire n'est pas encore actif... Voulez-vous envoyer une demande d'activation auprès de l'équipe Proxiloup ?
              </Typography>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.props.dispatch({ type: "OPEN_AREA_DIALOG", value: false })}>
            Annuler
          </Button>
          <Button onClick={this.handleSubscribeArea} color="secondary" autoFocus>
            Envoyer ma demande
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    userFirstName: state.userReducer.userFirstName,
    userLastName: state.userReducer.userLastName,
    userEmail: state.userReducer.userEmail,
    userPhone: state.userReducer.userPhone,
    userGroup: state.userReducer.userGroup,
    userAreas: state.userReducer.userAreas,
    userAreasTemp: state.userReducer.userAreasTemp,

    openAreaDialog: state.appReducer.openAreaDialog,

    currentArea: state.dataReducer.currentArea
  }
}

export default connect(mapStateToProps)(withSnackbar(AreaDialog))
