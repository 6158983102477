import React from "react"
import MaskedInput from "react-text-mask"

// Phone formatter variables
export const phoneFormatRegex = /^(\+)(33|41)(\d{9})$/
export const phoneHelperText = "Indicatifs pays : +33 France, +41 Suisse"
// END : Phone formatter variables

// Phone number formatter
export function PhoneFormatter(props) {
    const { inputRef, ...other } = props
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null)
        }}
        mask={["+", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={"\u2000"}
        keepCharPositions={true}
      />
    )
  }
  // END : Phone number formatter
