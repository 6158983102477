import firebase from "@firebase/app"
import "@firebase/database"
import "@firebase/auth"
import "@firebase/storage"

const config = {
  apiKey: "AIzaSyCzB-FmEfCgVZyCwg63fgo4gq9LCXrsf00",
  authDomain: "proxyloup.firebaseapp.com",
  databaseURL: "https://proxyloup.firebaseio.com",
  projectId: "proxyloup",
  storageBucket: "proxyloup.appspot.com",
  messagingSenderId: "439223459942"
}
firebase.initializeApp(config)

export default firebase
