import React from "react"

import { SvgIcon } from "@material-ui/core"

export default props => (
  <SvgIcon viewBox="0 0 427.57 423.89" {...props}>
    <path
      d="M307.37,466.78H36.73c.42-7.66.48-15.25,1.31-22.76,2.85-25.73,11.25-49.25,28.78-68.84,16.38-18.31,37.45-28.36,61-33.77,1.39-.32,3.23.36,4.61,1.07q39.48,20.38,79,.15a10.3,10.3,0,0,1,6.48-.72c58.14,13.52,82.71,55.25,88,101.9C306.8,451.22,306.89,458.72,307.37,466.78Z"
      transform="translate(-36.73 -42.89)"
    />
    <path
      d="M240.25,243c10.63,36.65,2.5,67-29.47,88.77-24.07,16.37-56.33,15.12-80.4-1.71-27-18.89-35.53-46.47-26.57-87.07-3.2,0-6.31.11-9.41,0a15.43,15.43,0,0,1,0-30.86c6.49-.2,13-.2,19.49,0,3,.09,4.34-.86,5.27-3.77,4.48-13.94,9.2-27.81,13.85-41.7,3.85-11.48,10.67-16.46,22.66-16.49q17.49,0,35,0c11.16,0,18.15,5,21.75,15.61,4.6,13.56,9.25,27.1,13.51,40.76,1.29,4.13,3,6,7.55,5.62a142.69,142.69,0,0,1,15.48-.09c9,.19,15.77,6.74,15.88,15.28s-6.78,15.45-16,15.65C246,243,243.17,243,240.25,243ZM143.81,251l0,.25c-2.66.19-5.31.41-8,.57-6.54.4-9.24,2.38-10.12,7.44-1.63,9.36,0,15.42,6,17.12,14,4,26.62,1.23,31.07-15.71,1.31-5-.94-8.11-6.09-8.72C152.45,251.46,148.12,251.33,143.81,251Zm57,.27,0-.27c-4.48.3-9,.43-13.43.94-5.4.61-7.62,3.79-6.12,9.11,4.68,16.65,16.93,19.07,30.9,15.41,4.31-1.13,6.75-5.08,6.69-9.62-.13-9.57-.14-15.27-13-15.18C204.15,251.69,202.49,251.43,200.83,251.29Zm-23.16,2.34H165.46c1.4,2.51,2.26,4,3.06,5.46C172.26,258.43,177,260.79,177.67,253.63Z"
      transform="translate(-36.73 -42.89)"
    />
    <path
      d="M349.66,176.75c-15.1-12.73-24.84-27.15-29-45.3-9.06-39.57,16.39-78.65,56.79-87.07,39.43-8.22,78.88,18.45,85.59,58.25,4.67,27.72-3.6,51.36-25.35,69.17s-46.42,21-72.83,11.58a20.94,20.94,0,0,0-4.52-.56,45.15,45.15,0,0,1,.84,4.71c.15,2.05.89,5.34-.05,6-4.78,3.21-4.68,8.78-7.14,13q-19.92,34.35-39.75,68.74c-4.34,7.49-11.49,9.81-18.08,6.08s-8.36-11.29-4.14-18.64q20-34.86,40.2-69.63c.91-1.56,2.35-2.8,3.34-4.32a20.08,20.08,0,0,0,2.7-5.11c1-3.69,3-5.74,7-5.74C346.75,177.9,348.21,177.16,349.66,176.75Zm42-120.91a60,60,0,0,0-.32,119.93c33,.16,60.16-26.84,60.35-59.88S424.82,55.87,391.66,55.84Z"
      transform="translate(-36.73 -42.89)"
    />
  </SvgIcon>
)
