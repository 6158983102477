import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import ProgressiveImage from "react-progressive-image"

import { Typography } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Card } from "@material-ui/core"
import { CardContent } from "@material-ui/core"
import { Fade } from "@material-ui/core"

import HelpIcon from "@material-ui/icons/Help"

import CustomLink from "../modules/CustomLink"

// IMAGES
import LogoProxiloup from "../images/icon-192.png"
import LogoFJML from "../images/logos/logo-fjml.png"
import LogoIPRA from "../images/logos/logo-ipra.png"
import LogoVaud from "../images/logos/logo-vaud.png"

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[100],
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  nowrap: {
    whiteSpace: "nowrap"
  }
})

class About extends Component {
  render() {
    const { classes } = this.props

    return (
      <Fade in={true}>
        <div className={classes.root}>
          <Grid container direction="row" justify="flex-start" alignItems="center" style={{ margin: "8px", marginBottom: "24px" }}>
            <HelpIcon fontSize="large" style={{ marginRight: "16px" }} color="action" />
            <Grid item xs zeroMinWidth>
              <Typography variant="h5" noWrap>
                A propos / Aide
              </Typography>
            </Grid>
          </Grid>

          <Card style={{ borderRadius: "0", textAlign: "center" }}>
            <CardContent>
              <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: "8px", marginBottom: "16px" }}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className={classes.drawerHeader + " " + classes.toolbar}
                    style={{ flexWrap: "nowrap" }}
                  >
                    <Grid item style={{ paddingRight: "8px" }}>
                      <img src={LogoProxiloup} width="60" alt="" />
                    </Grid>
                    <Grid item>
                      <Typography variant="h5">Proxiloup</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Typography style={{ marginTop: "8px", marginBottom: "8px" }}>
                Proxiloup est un outil d'aide à la gestion en temps réel de la protection des troupeaux, permettant d'informer un réseau d'éleveurs et de
                bergers par SMS en cas de présence suspectée de prédateurs sur le territoire.
              </Typography>
              <Typography style={{ marginTop: "8px", marginBottom: "8px" }}>
                Mis en place par la Fondation Jean-Marc LANDRY et son institut de recherche IPRA, avec le soutien du Canton de Vaud
              </Typography>

              <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={32} style={{ marginTop: "8px", marginBottom: "8px" }}>
                <Grid item>
                  <CustomLink href="https://www.vd.ch" target="_blank" className={classes.nowrap}>
                    <ProgressiveImage src={LogoVaud}>{src => <img src={src} alt="Logo Canton de Vaud" width="200" />}</ProgressiveImage>
                    <Typography>www.vd.ch</Typography>
                  </CustomLink>
                </Grid>
                <Grid item>
                  <CustomLink href="https://www.fjml.life" target="_blank" className={classes.nowrap}>
                    <ProgressiveImage src={LogoFJML}>{src => <img src={src} alt="Logo FJML" width="160" />}</ProgressiveImage>
                    <Typography>www.fjml.life</Typography>
                  </CustomLink>
                </Grid>
                <Grid item>
                  <CustomLink href="https://www.ipra-landry.com" target="_blank" className={classes.nowrap}>
                    <ProgressiveImage src={LogoIPRA}>{src => <img src={src} alt="Logo IPRA" width="140" />}</ProgressiveImage>
                    <Typography>www.ipra-landry.com</Typography>
                  </CustomLink>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <Typography variant="h4">Contact</Typography>
              <Typography style={{ marginTop: "8px", marginBottom: "8px" }}>
                Pour une demande d'aide à l'utilisation de cette application, n'hésitez à contacter notre équipe par email (de préférence) :<br />
              </Typography>
              <Typography variant="h6" style={{ marginTop: "16px", marginBottom: "16px" }}>
                <CustomLink href="mailto:contact@proxiloup.com" target="_blank" className={classes.nowrap}>
                  <b>contact@proxiloup.com</b>
                </CustomLink>
              </Typography>

              <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={16}>
                <Grid item>
                  <Typography variant="h6">Suisse</Typography>
                  <Typography>
                    Jean-Marc LANDRY : <span className={classes.nowrap}>+41 (0) 79 326 22 39</span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">France</Typography>
                  <Typography>
                    Jean-Marc LANDRY : <span className={classes.nowrap}>+33 (0)6 79 32 84 45</span>
                    <br />
                    Gus LYON : <span className={classes.nowrap}>+33 (0)6 80 82 97 62</span>
                  </Typography>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <Typography style={{ marginTop: "8px", marginBottom: "8px" }}>
                En savoir plus :{" "}
                <CustomLink href="https://www.proxiloup.com" target="_blank">
                  www.proxiloup.com
                </CustomLink>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Fade>
    )
  }
}

// export default compose(
//   withStyles(styles, {
//     name: "About"
//   }),
//   connect()
// )(About)

export default withStyles(styles)(connect()(About))
