import React from "react"

import { SvgIcon } from "@material-ui/core"

export default props => (
  <SvgIcon viewBox="0 0 426.28 423.57" {...props}>
    <path
      d="M184.32,447.25q21-51.86,41.46-102.57c46,5.29,86.58,67,80.84,122H37.05c-.59-27.76,4.52-54,19.36-77.73,13.85-22.15,34-36,59.13-44.23q19.87,52.47,39.58,104.55l1-.06Q157.54,436,159,422.73c1.16-10.25,2.41-20.5,3.43-30.77a4.53,4.53,0,0,0-1.71-3.56c-5.33-3.23-8.93-7.22-7.73-14,.75-4.22-2.59-8.46.71-12.85,2.94-3.93,5.9-6.85,11.16-6.58,3.49.17,7,0,10.5,0A12.62,12.62,0,0,1,188,367.34c.06,2.33-.17,4.68,0,7a12.15,12.15,0,0,1-6.19,12.09c-4.73,2.78-4.11,6.68-3.72,10.66.82,8.48,2,16.93,2.85,25.4.78,7.52,1.36,15.06,2.12,22.58C183.18,445.8,183.88,446.48,184.32,447.25Z"
      transform="translate(-37 -43.09)"
    />
    <path
      d="M171.66,343.09C131.42,343,98.92,310,99.12,269.47c.19-40.24,33.19-72.68,73.72-72.47,39.59.2,72.19,33.31,72,73.15A73,73,0,0,1,171.66,343.09Z"
      transform="translate(-37 -43.09)"
    />
    <path
      d="M366.09,135.76c-10.22-15.56-20.21-29.72-29-44.56-7.29-12.24-5-26.82,4.28-37.07a33.07,33.07,0,0,1,36.51-8.83,32.63,32.63,0,0,1,21,31.52c-.13,3.89-.7,8.3-2.65,11.51C386.65,104,376.55,119.43,366.09,135.76ZM366,88.94a12.76,12.76,0,1,0-12.83-12.72A13,13,0,0,0,366,88.94Z"
      transform="translate(-37 -43.09)"
    />
    <path d="M319.32,196.37l17.25-57.63c8.83,2.94,17.42,5.76,26,8.73a3,3,0,0,1,1.33,2.34c.07,21.88.05,43.76.05,66.38Z" transform="translate(-37 -43.09)" />
    <path
      d="M395.35,138.77c5.82,19.44,11.48,38.35,17.24,57.61L368,216.16c0-18.53,0-36.33,0-54.13,0-4.63-1.4-10.45.84-13.58s8.19-3.63,12.57-5.15S390.27,140.42,395.35,138.77Z"
      transform="translate(-37 -43.09)"
    />
    <path
      d="M399.6,138.42c10.68,3.53,20.16,6.58,29.56,9.86a7,7,0,0,1,3.49,3.2q15.28,30.27,30.29,60.68c.56,1.12.25,2.68.35,4-1.39-.09-2.93.18-4.13-.34q-19.64-8.58-39.16-17.42c-1.23-.56-2.73-1.66-3.09-2.82C411.15,176.94,405.56,158.21,399.6,138.42Z"
      transform="translate(-37 -43.09)"
    />
    <path
      d="M332.28,138.4c-5.79,19.4-11.14,37.56-16.73,55.65a8.23,8.23,0,0,1-3.95,4.48c-12.82,5.92-25.74,11.61-38.68,17.26-1.22.54-2.75.34-4.14.48.1-1.51-.26-3.24.36-4.49Q283.95,181.84,299,152c.64-1.27,1.59-2.88,2.76-3.29C311.56,145.27,321.44,142,332.28,138.4Z"
      transform="translate(-37 -43.09)"
    />
  </SvgIcon>
)
