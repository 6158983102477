import React from "react"

import { SvgIcon } from "@material-ui/core"

export default props => (
  <SvgIcon viewBox="0 0 426.38 422.93" {...props}>
    <path
      d="M99.57,260.24,56,256c-2.65-.26-5.45-.12-7.9-1-1.87-.66-3.28-2.61-4.9-4,1.51-1.69,2.74-4.4,4.57-4.89,8.83-2.37,17.81-4.14,26.74-6.12,10.4-2.31,20.76-4.76,31.2-6.83,3.21-.64,4.32-1.82,4.48-5.14.6-12.54,2.87-24.79,8-36.35,11.23-25.22,34.26-38.36,61.72-35.27,29.39,3.3,48.18,20.53,55.86,51.67A152.11,152.11,0,0,1,239,228.29c.32,3,1.25,4.23,4.25,4.93C259.45,237,275.58,241,291.74,245c1.29.32,2.87.28,3.81,1a42.74,42.74,0,0,1,4.6,4.89c-1.71,1.44-3.29,3.92-5.14,4.15-15.71,1.87-31.46,3.34-47.19,4.93l-3.32.37c2.2,26.37-5.78,48.73-26.08,65.91-15.23,12.89-33.11,18.32-52.9,16.6C126.89,339.5,95,305.17,99.57,260.24Z"
      transform="translate(-36.74 -43.83)"
    />
    <path
      d="M307.67,466.76H36.74c.41-7.58.47-15.17,1.3-22.67,2.83-25.73,11.22-49.25,28.73-68.86,16.38-18.32,37.43-28.39,61-33.81a7.55,7.55,0,0,1,4.62,1q39.74,20.55,79.41-.05a8.9,8.9,0,0,1,5.58-.64c48.86,10.9,80.95,45.83,87.67,95.57C306.32,446.85,306.77,456.5,307.67,466.76ZM71.37,413v34.6H274V425.23c0-12.16,0-12.16-12.39-12.23a4,4,0,0,0-.49,0c-3.89.56-5.28-1-5.2-5,.23-12.33.1-24.65.05-37,0-1.26.09-3.21-.62-3.67a60.76,60.76,0,0,0-22.49-8.66v53.85a7.22,7.22,0,0,1-1.42.4q-57.23,0-114.44.12c-3.57,0-4-1.75-4-4.67.09-13.83,0-27.66,0-41.48v-9.17c-6.72,2.7-12.28,5.18-18,7.17-5.11,1.76-5.18,5.42-5.07,9.87.25,11-.1,22,.17,33,.11,4.28-1.38,5.6-5.47,5.33C80.33,412.77,76,413,71.37,413Z"
      transform="translate(-36.74 -43.83)"
    />
    <path
      d="M400.85,187.29h-7.69c-3.33-11.82-6.84-23.59-9.88-35.49-1-3.92-2.55-5.18-6.69-5.14q-29.23.26-58.48-.24c-5.55-.1-10.11,1.71-15.08,3.9-11.65,5.14-18.91,12.69-18.52,26.1a23.45,23.45,0,0,1-.79,4.92c-1.25,6.71-1.25,6.71-9.11,5.58V165.77a37.22,37.22,0,0,0,0-5.49c-1-6.18,1-11.05,6.11-14.65,4.66-3.28,6.28-7.69,5.63-13.35-.45-3.89-.09-7.86-.09-12.26L280,122.86c0-10.19-1-20.21.25-29.94C282,80,294.1,71.09,308,71.05c26.33-.07,52.66-.1,79,.07,4.11,0,7.3-.93,10.29-3.77,3.85-3.65,8.08-6.9,12.4-10.53-3.62-1-7-1.52-10.07-2.75-4.59-1.82-4.94-5.75-.72-8.43A12.35,12.35,0,0,1,405,44.1c8-.17,16-.58,24,.13a28,28,0,0,1,12.7,4.55c6.29,4.31,11.68,9.92,17.8,14.51,3.55,2.67,4.37,5.7,3.07,9.49a8.61,8.61,0,0,1-8.64,6.31c-8.83,0-17.66,0-26.49,0H421.5c2.61,5.43,8.18,4.88,11.69,8-.33,1-.65,2.26-1.14,3.42q-6.8,16.1-13.63,32.16c-4.12,9.72-9.65,18.19-19.41,23.29-1,.54-1.63,3-1.5,4.45,1,11,2.21,21.87,3.32,32.8C401,184.43,400.85,185.59,400.85,187.29Z"
      transform="translate(-36.74 -43.83)"
    />
    <path d="M360,188.67l5.29-34.95h14c-3.55,10.84-6.66,21.3-10.61,31.44C368,186.9,363.74,187.25,360,188.67Z" transform="translate(-36.74 -43.83)" />
    <path d="M298.23,161.22l10.36-6.1,12.06,31.77c-4.93,1.33-8.18.85-10.51-4C306.61,175.62,302.37,168.67,298.23,161.22Z" transform="translate(-36.74 -43.83)" />
    <path
      d="M245.06,439c-5.13,0-10-4.8-10.06-9.88,0-4.7,5.28-10.09,9.95-10.12,4.83,0,10,5.26,10,10.36S250.39,439,245.06,439Z"
      transform="translate(-36.74 -43.83)"
    />
    <path
      d="M112,429.67c0,5.52-3.71,9.34-9,9.33-4.67,0-10.73-5.52-10.83-9.84-.08-3.58,6.81-10.25,10.49-10.15C107.67,419.14,112,424.09,112,429.67Z"
      transform="translate(-36.74 -43.83)"
    />
  </SvgIcon>
)
