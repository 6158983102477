import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./components/App"
import * as serviceWorker from "./serviceWorker"

import { Provider } from "react-redux"
import store from "./store/configureStore"

// import querystring from "querystring"

// --------------- TEST AREA  --------------- //

// console.log("process.env.REACT_APP_ENV_SOURCE : ", process.env.REACT_APP_ENV_SOURCE)

// const API_ENDPOINT = window.location.origin + "/.netlify/functions/sendAlert"

// const params = {
//   name: `Gus index.js ${window.location.origin}`,
//   email: "webmaster@proxyloup.ipra-landry.com",
//   distance: "8.8888"
// }

// fetch(API_ENDPOINT, {
//   method: "POST",
//   headers: { "Content-Type": "application/x-www-form-urlencoded" },
//   body: querystring.stringify(params)
// })
//   .then(response => {
//     return response.json()
//   })
//   .then(data => {
//     console.log(data.response)
//   })
//   .catch(error => {
//     console.error(error)
//   })


// --------------- END TEST AREA  --------------- //

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
