import React, { Component } from "react"
import { connect } from "react-redux"
import moment from "moment"

import { Dialog } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogContentText } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { Button } from "@material-ui/core"

class IntroDialog extends Component {
  state = {
    openDialog: false
  }

  componentDidMount() {
    // Open dialog first week user connexion OR after days since user sign in
    const nowDateTime = moment(new Date().toUTCString())
    const signInTime = moment(this.props.user.metadata.lastSignInTime)
    const signUpTime = moment(this.props.user.metadata.creationTime)
    // const signInTime = moment(new Date("Fri, 30 May 2019 21:15:52 GMT").toUTCString()) // testing
    const diffSignIn = nowDateTime.diff(signInTime, "days") // <=================================================== NE FONCTIONNE PAS BIEN
    const diffSignUp = nowDateTime.diff(signUpTime, "days")
    // console.log("nowDateTime", nowDateTime)
    // console.log("signInTime", signInTime)
    // console.log("signUpTime", signUpTime)
    // console.log("diffSignIn", diffSignIn)
    // console.log("diffSignUp", diffSignUp)
    if (diffSignIn >= 1 || diffSignUp <= 7) {
      // set diffSignIn to 1 to display dialog every day
      // set diffSignIn to 7 display dialog every week
      // set diffSignIn to 30 display dialog every month
      this.setState({ openDialog: true })
    }
    // END : Open dialog first week user connexion OR after days since user sign in
  }

  render() {
    // const { openDialog } = this.state

    const handleCloseDialog = () => this.setState({ openDialog: false })

    return (
      <Dialog
        // open={openDialog}
        open={false} // <============================================================ désactivé pour tous, TODO : réactivé pour la France uniquement
        onClose={handleCloseDialog}
        aria-labelledby="intro-dialog-title"
        aria-describedby="intro-dialog-description"
      >
        <DialogTitle id="intro-dialog-title">{"Avertissement France"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="intro-dialog-description">
            Proxiloup est un outil d'aide à la gestion de la protection des troupeaux en temps réel pour les éleveurs/bergers inscrits sur cette plateforme. Les
            informations diffusées ici ne concernent pas et ne remplacent pas les démarches mises en place par les services de l'Etat (réseau de suivi loup-lynx
            ONCFS, système d'indemnisation des dommages DDT). Transmises sur demande de l'observateur, elles peuvent néanmoins enrichir les bases de données des
            organismes sus-cités.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            J'ai compris
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user
  }
}

export default connect(mapStateToProps)(IntroDialog)
