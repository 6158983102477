import React from "react"

// import PendingIcon from "@material-ui/icons/AccessTime"
// import TrueIcon from "@material-ui/icons/CheckCircleOutline"
// import SuspiciousIcon from "@material-ui/icons/HelpOutline"
// import FalseIcon from "@material-ui/icons/HighlightOff"

import PendingIcon from "@material-ui/icons/WatchLater"
import TrueIcon from "@material-ui/icons/CheckCircle"
import SuspiciousIcon from "@material-ui/icons/Help"
import FalseIcon from "@material-ui/icons/Cancel"

import { colorRed, colorDark, colorOrange, colorBrown } from "../config/colors.json"

// Function Get ressource status icon
export const getRessourceStatusIcon = status =>
  (status === "pending" && <PendingIcon color="inherit"/>) ||
  (status === "true" && <TrueIcon color="inherit" />) ||
  (status === "suspicious" && <SuspiciousIcon color="inherit" />) ||
  (status === "false" && <FalseIcon color="inherit" />)
// END : Function Get ressource status icon

// Function Get ressource status color
export const getRessourceStatusColor = status =>
  (status === "pending" && colorDark) || (status === "true" && colorRed) || (status === "suspicious" && colorOrange) || (status === "false" && colorBrown)
// END : Function Get ressource status color
