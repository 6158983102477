// Function Capitalize string
export function capitalize(string) {
  const regex = /\b\w/g
  const result = string.replace(regex, l => l.toUpperCase())
  return result
}
// END : Function Capitalize string

// Functions Generate unique id
export function formatWithTwoDigits(digit) {
  if (digit < 10) {
    digit = "0" + digit
  }
  return digit
}
export function generateId(prefix) {
  const now = new Date()
  const year = now
    .getFullYear()
    .toString()
    .substr(2, 2)
  const month = formatWithTwoDigits(now.getMonth() + 1) // January is 0 !
  const day = formatWithTwoDigits(now.getDate())
  const hours = formatWithTwoDigits(now.getHours())
  const minutes = formatWithTwoDigits(now.getMinutes())
  const seconds = formatWithTwoDigits(now.getSeconds())
  const milliseconds = formatWithTwoDigits(now.getMilliseconds())
    .toString()
    .substr(0, 2)
  return prefix + year + month + day + hours + minutes + seconds + milliseconds
}
// END : Functions Generate unique id

// Functions Now date and time
export function dateNow() {
  const now = new Date()
  const year = now.getFullYear()
  const month = formatWithTwoDigits(now.getMonth() + 1) // January is 0 !
  const day = formatWithTwoDigits(now.getDate())
  return year + "-" + month + "-" + day
}
export function timeNow() {
  const now = new Date()
  const hours = formatWithTwoDigits(now.getHours())
  const minutes = formatWithTwoDigits(now.getMinutes())
  const seconds = formatWithTwoDigits(now.getSeconds())
  return hours + ":" + minutes + ":" + seconds
}
// END : Functions Now date and time

// Functions Replace all non-GSM encoded chars
export function gsmEncoding(string) {
  // const regex = new RegExp(
  //   "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
  // )
  // const regex = " @£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞ^{}\\[~]|€ÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà"
  const regex = " @£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà" // without 2 chars count in AWS ====> ^{}\\[~]|€
  let array = []
  string.split("").forEach(key => {
    regex.indexOf(key) !== -1 ? array.push(key) : array.push("#") // replace by "#"
  })
  // console.log(array.length)
  if(array.length > 160){
    return array.slice(0, 157).join("") + "..."
  } else {
    return array.join("")
  }
  
}
// END : Functions Replace all non-GSM encoded chars
