import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { ListItemText } from "@material-ui/core"
import { Fade } from "@material-ui/core"
import { FormControl } from "@material-ui/core"
import { FormHelperText } from "@material-ui/core"
import { Select } from "@material-ui/core"
import { Checkbox } from "@material-ui/core"
import { Input } from "@material-ui/core"
import { Chip } from "@material-ui/core"
import { MenuItem } from "@material-ui/core"
import { ExpansionPanel } from "@material-ui/core"
import { ExpansionPanelDetails } from "@material-ui/core"
import { ExpansionPanelSummary } from "@material-ui/core"
import { Avatar } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { Paper } from "@material-ui/core"

import GroupIcon from "@material-ui/icons/Group"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ConfirmIcon from "@material-ui/icons/AddCircle"
import RefuseIcon from "@material-ui/icons/RemoveCircle"
import PendingIcon from "@material-ui/icons/WatchLaterOutlined"

import { sendEmail } from "../functions/apiLambda"

import firebase from "../config/firebase"
import areasName from "../data/areasName.json"
import { translation } from "../config/languages.json"

import SvgVisitor from "../images/groups/0-visitor"
import SvgMember from "../images/groups/1-member"
import SvgObserver from "../images/groups/2-observer"
import SvgInspector from "../images/groups/3-inspector"
import SvgManager from "../images/groups/4-manager"
import SvgAdministrator from "../images/groups/5-administrator"

const styles = theme => ({
  root: {
    overflowX: "auto",
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[100],
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  panelDetails: {
    minWidth: 150,
    flexGrow: 1
  },
  panelSummary: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
})

class Users extends Component {
  state = {
    expanded: ""
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  refreshAreas(e, userId, props) {
    const areas = e.target.value

    const nextAreas = Object.keys(areas)
      .filter(i => areas[i] !== undefined)
      .filter(i => areas[i] !== "")
      .reduce((obj, k) => {
        obj[k] = areas[k]
        return obj
      }, {})

    console.log("areas.length", areas.length)
    console.log("areas", areas)
    console.log("nextAreas", nextAreas)

    if (Object.keys(nextAreas).length > 0) {
      console.log("1 or + selected areas")
      // ...
    } else {
      console.log("No selected area !")
      // ...
    }

    // Update Firebase
    firebase
      .database()
      .ref("users/" + userId)
      .update({ areas: nextAreas }, error => {
        if (error) {
          // Write failed...
          console.error("Error 983985c8-9b9a-44e8-a7bb-c052d637cd36", error)
          // this.message("error", "Erreur : " + error.message)
        } else {
          // Write success...
          // this.message("success", "Base de données mise à jour !")

          // Save to Redux global state
          const nextUsers = Object.assign({}, this.props.users)
          nextUsers[userId].areas = areas
          props.dispatch({ type: "UPDATE_DATA_USERS", value: nextUsers })
        }
      })
  }

  refreshGroup(e, key, props) {
    const group = e.target.value
    console.log(group)

    if (group) {
      // Update Firebase
      firebase
        .database()
        .ref("users/" + key)
        .update({ group }, error => {
          if (error) {
            // Write failed...
            console.error("Error 080ee3c1-6d00-4979-9cb8-58ef993c1a0c", error)
            // this.message("error", "Erreur : " + error.message)
          } else {
            // Write success...
            // this.message("success", "Base de données mise à jour !")

            // Save to Redux global state
            const nextUsers = Object.assign({}, this.props.users)
            nextUsers[key].group = group
            props.dispatch({ type: "UPDATE_DATA_USERS", value: nextUsers })
          }
        })
    } else {
      console.log("No user group selected...")
    }
  }

  confirmAreaSubscription = (userId, areaShortcode) => event => {
    // const user = this.props.users[userId] ? this.props.users[userId] : this.props.tempUsers[userId] // Check if user exist in main users lists
    const user = this.props.users[userId]
    const prevAreas = user.areas ? user.areas : []
    const prevAreasTemp = user.areastemp ? user.areastemp : []

    const nextAreas = !prevAreas.includes(areaShortcode) ? [...prevAreas, ...[areaShortcode]] : [...prevAreas]
    const nextAreasTemp = prevAreasTemp.filter(area => area !== areaShortcode)

    console.log("user", user)

    console.log("prevAreas", prevAreas)
    console.log("nextAreas", nextAreas)
    console.log("prevAreasTemp", prevAreasTemp)
    console.log("nextAreasTemp", nextAreasTemp)

    // Update Firebase
    firebase
      .database()
      .ref("users/" + userId)
      .update({ areas: nextAreas, areastemp: nextAreasTemp }, error => {
        if (error) {
          // Write failed...
          console.error("Error 097c073a-c3ae-422e-ba3e-8f41f1dde409", error)
          // this.message("error", "Erreur : " + error.message)
        } else {
          // Write success...
          // this.message("success", "Base de données mise à jour !")

          // Send email notification
          const mailFrom = `"Proxiloup.com" <contact@proxiloup.com>` // verified AWS email adress
          const mailReplyTo = ``
          const mailTo = user.email
          const mailSubject = "Inscription validée pour un nouveau territoire"
          const mailBody = `
            <html>
              <head>
              <title>${mailSubject}</title>
              </head>
              <body>
                <p>${user.firstname} ${user.lastname},</p>
                <p>Votre demande d'inscription pour le territoire <b>${areasName[areaShortcode]} (${areaShortcode.substring(
            3,
            5
          )})</b> vient d'être validée...</p>
                <p>Le périmètre est maintenant visible sur votre carte et vous pouvez donc y localiser de nouveaux éléments (ajouter des points...)</p>
                <p>L'équipe Proxiloup reste disponible pour vous accompagner, n'hésitez pas à nous contacter !</p>
                <p>Pour accéder à l'application : <a href="https://www.proxiloup.com" target="_blank">www.proxiloup.com</a></p>
                <p>
                --
                </p>
                <p><em>PS : Ceci est un message automatique envoyé via l'application Proxiloup.com</em></p>
              </body>
            </html>
          `
          sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody).then(response => {
            // console.log("response : ", response)
            if (response.status === 200) {
              // SUCCESS
              this.setState({ isLoading: false, submit: "success" })
            } else {
              // ERROR
              this.setState({ isLoading: false, submit: "error" })
            }
            return response.json()
          })
          // .then(data => {
          //   console.log("sendEmail", data)
          // })
          // END : Send email notification
        }
      })
  }

  refuseAreaSubscription = (userId, areaShortcode) => event => {
    // const user = this.props.users[userId] ? this.props.users[userId] : this.props.tempUsers[userId] // Check if user exist in main users lists
    const user = this.props.users[userId]
    const prevAreasTemp = user.areastemp ? user.areastemp : []

    const nextAreasTemp = prevAreasTemp.filter(area => area !== areaShortcode)

    console.log("user", user)

    console.log("prevAreasTemp", prevAreasTemp)
    console.log("nextAreasTemp", nextAreasTemp)

    // Update Firebase
    firebase
      .database()
      .ref("users/" + userId)
      .update({ areastemp: nextAreasTemp }, error => {
        if (error) {
          // Write failed...
          console.error("Error 75b17adf-e132-42bb-8a41-66c146c9cd81", error)
          // this.message("error", "Erreur : " + error.message)
        } else {
          // Write success...
          // this.message("success", "Base de données mise à jour !")

          // Send email notification
          const mailFrom = `"Proxiloup.com" <contact@proxiloup.com>` // verified AWS email adress
          const mailReplyTo = ``
          const mailTo = user.email
          const mailSubject = "Inscription annulée pour un nouveau territoire"
          const mailBody = `
            <html>
              <head>
              <title>${mailSubject}</title>
              </head>
              <body>
                <p>${user.firstname} ${user.lastname},</p>
                <p>Votre demande d'inscription pour le territoire <b>${areasName[areaShortcode]} (${areaShortcode.substring(
            3,
            5
          )})</b> vient d'être annulée...</p>
                <p>Si vous pensez qu'il s'agit d'une erreur, n'hésitez pas à nous contacter, l'équipe Proxiloup reste disponible pour vous transmettre davantage d'informations à propos des inscriptions sur de nouveaux territoires.</p>
                <p>Pour accéder à l'application : <a href="https://www.proxiloup.com" target="_blank">www.proxiloup.com</a></p>
                <p>
                --
                </p>
                <p><em>PS : Ceci est un message automatique envoyé via l'application Proxiloup.com</em></p>
              </body>
            </html>
          `
          sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody).then(response => {
            // console.log("response : ", response)
            if (response.status === 200) {
              // SUCCESS
              this.setState({ isLoading: false, submit: "success" })
            } else {
              // ERROR
              this.setState({ isLoading: false, submit: "error" })
            }
            return response.json()
          })
          // .then(data => {
          //   console.log("sendEmail", data)
          // })
          // END : Send email notification
        }
      })
  }

  render() {
    const { classes, users, userGroup, userAreas } = this.props

    const areasShortcode = Object.keys(areasName)

    return (
      <Fade in={true}>
        <div className={classes.root}>
          <Grid container direction="row" justify="flex-start" alignItems="center" style={{ margin: "8px", marginBottom: "24px" }}>
            <GroupIcon fontSize="large" style={{ marginRight: "16px" }} color="action" />
            <Grid item xs zeroMinWidth>
              <Typography variant="h5" noWrap>
                Utilisateurs du territoire
              </Typography>
            </Grid>
          </Grid>

          {/* Loop on my user areas */}
          {userAreas &&
            userAreas.map(currentUserArea => (
              <div key={currentUserArea} style={{ marginBottom: "24px" }}>
                <Chip
                  color="primary"
                  avatar={
                    <Avatar>
                      <small style={{ textAlign: "center" }}>{currentUserArea.substring(0, 2)}</small>
                    </Avatar>
                  }
                  label={areasName[currentUserArea] + " (" + currentUserArea.substring(3, 5) + ")"}
                  style={{ margin: "4px", marginBottom: "24px" }}
                />

                {users && Object.keys(users).filter(key => users[key].areastemp && users[key].areastemp.includes(currentUserArea)).length > 0 && (
                  <Fragment>
                    <Paper style={{ padding: "16px", marginBottom: "24px", backgroundColor: "black" }}>
                      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={16} style={{ margin: "8px 0" }} wrap="nowrap">
                        <Grid item>
                          <PendingIcon color="secondary" />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" color="secondary">
                            Inscriptions en attente...
                          </Typography>
                        </Grid>
                      </Grid>

                      {Object.keys(users)
                        .filter(key => users[key].areastemp && users[key].areastemp.includes(currentUserArea)) // Filter by area
                        .map(key => (
                          <ExpansionPanel
                            key={key + currentUserArea}
                            expanded={this.state.expanded === key + currentUserArea}
                            onChange={this.handleChangePanel(key + currentUserArea)}
                          >
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                              <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                <Grid item style={{ marginRight: "8px" }}>
                                  {users[key].group === "default" && <SvgVisitor style={{ padding: "4px", width: 50, height: 50, color: "#a2a1a1" }} />}
                                  {users[key].group === "member" && <SvgMember style={{ padding: "4px", width: 50, height: 50, color: "#4a8522" }} />}
                                  {users[key].group === "observer" && <SvgObserver style={{ padding: "4px", width: 50, height: 50, color: "#be5108" }} />}
                                  {users[key].group === "inspector" && <SvgInspector style={{ padding: "4px", width: 50, height: 50, color: "#d29500" }} />}
                                  {users[key].group === "manager" && <SvgManager style={{ padding: "4px", width: 50, height: 50, color: "#1d4999" }} />}
                                  {users[key].group === "admin" && <SvgAdministrator style={{ padding: "4px", width: 50, height: 50, color: "#727272" }} />}
                                </Grid>
                                <Grid item>
                                  <ListItemText
                                    primary={users[key].firstname + " " + users[key].lastname}
                                    secondary={users[key].group ? translation.users.groups[users[key].group]["fr"] : "(aucun groupe)"}
                                  />
                                </Grid>
                              </Grid>
                            </ExpansionPanelSummary>

                            {userGroup !== "member" && (
                              <div>
                                <Divider />
                                <ExpansionPanelDetails>
                                  <div className={classes.panelDetails}>
                                    {["admin", "manager", "inspector"].includes(userGroup) && (
                                      <Fragment>
                                        <Typography variant="h6" style={{ marginTop: "16px" }}>
                                          Coordonnées
                                        </Typography>
                                        <Typography>
                                          <strong>Email : </strong> {users[key].email}
                                          {users[key].email && (
                                            <Fragment>
                                              {" ∙ ∙ ∙ "}
                                              <strong>Téléphone : </strong>
                                              {users[key].phone}
                                            </Fragment>
                                          )}
                                          {users[key].description && (
                                            <Fragment>
                                              {" ∙ ∙ ∙ "}
                                              <strong>Description : </strong>
                                              {users[key].description}
                                            </Fragment>
                                          )}
                                        </Typography>
                                        <Typography variant="h6" style={{ marginTop: "16px" }}>
                                          Territoire
                                        </Typography>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={16} style={{ margin: "8px 0" }}>
                                          <Grid item>
                                            <Button variant="outlined" color="secondary" onClick={this.confirmAreaSubscription(key, currentUserArea)}>
                                              <ConfirmIcon style={{ marginRight: "8px" }} />
                                              Accepter la demande
                                            </Button>
                                          </Grid>
                                          <Grid item>
                                            <Button variant="outlined" color="primary" onClick={this.refuseAreaSubscription(key, currentUserArea)}>
                                              <RefuseIcon style={{ marginRight: "8px" }} />
                                              Refuser l'inscription
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </Fragment>
                                    )}
                                  </div>
                                </ExpansionPanelDetails>
                              </div>
                            )}
                          </ExpansionPanel>
                        ))}
                    </Paper>
                  </Fragment>
                )}

                {users &&
                  Object.keys(users)
                    .filter(key => users[key].areas && users[key].areas.includes(currentUserArea)) // Filter by area
                    .map(key => (
                      <ExpansionPanel
                        key={key + currentUserArea}
                        expanded={this.state.expanded === key + currentUserArea}
                        onChange={this.handleChangePanel(key + currentUserArea)}
                      >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                          <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                            <Grid item style={{ marginRight: "8px" }}>
                              {users[key].group === "default" && <SvgVisitor style={{ padding: "4px", width: 50, height: 50, color: "#a2a1a1" }} />}
                              {users[key].group === "member" && <SvgMember style={{ padding: "4px", width: 50, height: 50, color: "#4a8522" }} />}
                              {users[key].group === "observer" && <SvgObserver style={{ padding: "4px", width: 50, height: 50, color: "#be5108" }} />}
                              {users[key].group === "inspector" && <SvgInspector style={{ padding: "4px", width: 50, height: 50, color: "#d29500" }} />}
                              {users[key].group === "manager" && <SvgManager style={{ padding: "4px", width: 50, height: 50, color: "#1d4999" }} />}
                              {users[key].group === "admin" && <SvgAdministrator style={{ padding: "4px", width: 50, height: 50, color: "#727272" }} />}
                            </Grid>
                            <Grid item>
                              <ListItemText
                                primary={users[key].firstname + " " + users[key].lastname}
                                secondary={users[key].group ? translation.users.groups[users[key].group]["fr"] : "(aucun groupe)"}
                              />
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>

                        {userGroup !== "member" && (
                          <div>
                            <Divider />
                            <ExpansionPanelDetails>
                              <div className={classes.panelDetails}>
                                {["admin", "manager", "inspector"].includes(userGroup) && (
                                  <Fragment>
                                    <Typography variant="h6" style={{ marginTop: "16px" }}>
                                      Coordonnées
                                    </Typography>
                                    <Typography>
                                      <strong>Email : </strong> {users[key].email}
                                      {users[key].email && (
                                        <Fragment>
                                          {" ∙ ∙ ∙ "}
                                          <strong>Téléphone : </strong>
                                          {users[key].phone}
                                        </Fragment>
                                      )}
                                      {users[key].description && (
                                        <Fragment>
                                          {" ∙ ∙ ∙ "}
                                          <strong>Description : </strong>
                                          {users[key].description}
                                        </Fragment>
                                      )}
                                    </Typography>
                                  </Fragment>
                                )}

                                <Typography variant="h6" style={{ marginTop: "16px" }}>
                                  Territoires
                                </Typography>

                                {userGroup !== "admin" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap"
                                    }}
                                  >
                                    {users[key].areas &&
                                      users[key].areas.map(
                                        shortcode =>
                                          shortcode && (
                                            <Chip
                                              key={shortcode}
                                              variant="outlined"
                                              avatar={
                                                <Avatar>
                                                  <small style={{ textAlign: "center" }}>{shortcode.substring(0, 2)}</small>
                                                </Avatar>
                                              }
                                              label={areasName[shortcode]}
                                              style={{ margin: "4px" }}
                                            />
                                          )
                                      )}
                                  </div>
                                )}

                                {userGroup === "admin" && (
                                  <Fragment>
                                    <FormControl margin="normal" fullWidth error>
                                      <Select
                                        multiple
                                        disabled={userGroup !== "admin"}
                                        value={users[key].areas ? users[key].areas : [""]}
                                        onChange={e => this.refreshAreas(e, key, this.props)}
                                        input={<Input id="selectUserAreas" />}
                                        renderValue={selected => (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap"
                                            }}
                                          >
                                            {selected.map(
                                              shortcode =>
                                                shortcode && (
                                                  <Chip
                                                    key={shortcode}
                                                    variant="outlined"
                                                    avatar={
                                                      <Avatar>
                                                        <small style={{ textAlign: "center" }}>{shortcode.substring(0, 2)}</small>
                                                      </Avatar>
                                                    }
                                                    label={areasName[shortcode]}
                                                    style={{ margin: "4px" }}
                                                  />
                                                )
                                            )}
                                          </div>
                                        )}
                                        // MenuProps={{
                                        //   PaperProps: {
                                        //     style: {
                                        //       maxHeight: 48 * 4.5 + 8,
                                        //       width: 250
                                        //     }
                                        //   }
                                        // }}
                                      >
                                        {areasShortcode.map(shortcode => (
                                          <MenuItem key={shortcode} value={shortcode}>
                                            <Checkbox color="default" checked={users[key].areas && users[key].areas.indexOf(shortcode) > -1} />
                                            <ListItemText primary={areasName[shortcode] + " (" + shortcode + ")"} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText>ATTENTION : Modification instantanée de la base de données (admin uniquement)</FormHelperText>
                                    </FormControl>

                                    <Typography variant="h6" style={{ marginTop: "16px" }}>
                                      Groupe
                                    </Typography>

                                    <FormControl margin="normal" fullWidth error>
                                      <Select
                                        value={users[key].group ? users[key].group : ""}
                                        onChange={e => this.refreshGroup(e, key, this.props)}
                                        disabled={userGroup !== "admin"}
                                        input={<Input id="selectUserGroup" />}
                                      >
                                        {Object.keys(translation.users.groups).map(userGroup => (
                                          <MenuItem key={userGroup} value={userGroup}>
                                            {translation.users.groups[userGroup]["fr"]}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText>ATTENTION : Modification instantanée de la base de données (admin uniquement)</FormHelperText>
                                    </FormControl>
                                  </Fragment>
                                )}
                              </div>
                            </ExpansionPanelDetails>
                          </div>
                        )}
                      </ExpansionPanel>
                    ))}
              </div>
            ))}
          {/* END : Loop on my user areas */}

          {/* Display users without area */}
          {userGroup === "admin" && (
            <div style={{ marginBottom: "24px" }}>
              <Chip
                color="primary"
                avatar={
                  <Avatar>
                    <small style={{ textAlign: "center" }}>?</small>
                  </Avatar>
                }
                label={"Aucun territoire"}
                style={{ margin: "4px", marginBottom: "24px" }}
              />

              {users &&
                Object.keys(users)
                  // .filter(key => !users[key].areas || !users[key].areastemp ) // If no area (and no temp area)
                  .filter(key => !users[key].areas) // If no area
                  .map(key => (
                    <ExpansionPanel key={key + "noArea"} expanded={this.state.expanded === key + "noArea"} onChange={this.handleChangePanel(key + "noArea")}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                          <Grid item style={{ marginRight: "8px" }}>
                            {users[key].group === "default" && <SvgVisitor style={{ padding: "4px", width: 50, height: 50, color: "#a2a1a1" }} />}
                            {users[key].group === "member" && <SvgMember style={{ padding: "4px", width: 50, height: 50, color: "#4a8522" }} />}
                            {users[key].group === "observer" && <SvgObserver style={{ padding: "4px", width: 50, height: 50, color: "#be5108" }} />}
                            {users[key].group === "inspector" && <SvgInspector style={{ padding: "4px", width: 50, height: 50, color: "#d29500" }} />}
                            {users[key].group === "manager" && <SvgManager style={{ padding: "4px", width: 50, height: 50, color: "#1d4999" }} />}
                            {users[key].group === "admin" && <SvgAdministrator style={{ padding: "4px", width: 50, height: 50, color: "#727272" }} />}
                          </Grid>
                          <Grid item>
                            <ListItemText
                              primary={users[key].firstname + " " + users[key].lastname}
                              secondary={users[key].group ? translation.users.groups[users[key].group]["fr"] : "(aucun groupe)"}
                            />
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>

                      {userGroup !== "member" && (
                        <div>
                          <Divider />
                          <ExpansionPanelDetails>
                            <div className={classes.panelDetails}>
                              {["admin", "manager", "inspector"].includes(userGroup) && (
                                <Fragment>
                                  <Typography variant="h6" style={{ marginTop: "16px" }}>
                                    Coordonnées
                                  </Typography>
                                  <Typography>
                                    <strong>Email : </strong> {users[key].email}
                                    {users[key].email && (
                                      <Fragment>
                                        {" ∙ ∙ ∙ "}
                                        <strong>Téléphone : </strong>
                                        {users[key].phone}
                                      </Fragment>
                                    )}
                                    {users[key].description && (
                                      <Fragment>
                                        {" ∙ ∙ ∙ "}
                                        <strong>Description : </strong>
                                        {users[key].description}
                                      </Fragment>
                                    )}
                                  </Typography>
                                </Fragment>
                              )}

                              <Typography variant="h6" style={{ marginTop: "16px" }}>
                                Territoires
                              </Typography>

                              {userGroup !== "admin" && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap"
                                  }}
                                >
                                  {users[key].areas &&
                                    users[key].areas.map(
                                      shortcode =>
                                        shortcode && (
                                          <Chip
                                            key={shortcode}
                                            variant="outlined"
                                            avatar={
                                              <Avatar>
                                                <small style={{ textAlign: "center" }}>{shortcode.substring(0, 2)}</small>
                                              </Avatar>
                                            }
                                            label={areasName[shortcode]}
                                            style={{ margin: "4px" }}
                                          />
                                        )
                                    )}
                                </div>
                              )}

                              {userGroup === "admin" && (
                                <Fragment>
                                  <FormControl margin="normal" fullWidth error>
                                    <Select
                                      multiple
                                      disabled={userGroup !== "admin"}
                                      value={users[key].areas ? users[key].areas : [""]}
                                      onChange={e => this.refreshAreas(e, key, this.props)}
                                      input={<Input id="selectUserAreas" />}
                                      renderValue={selected => (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexWrap: "wrap"
                                          }}
                                        >
                                          {selected.map(
                                            shortcode =>
                                              shortcode && (
                                                <Chip
                                                  key={shortcode}
                                                  variant="outlined"
                                                  avatar={
                                                    <Avatar>
                                                      <small style={{ textAlign: "center" }}>{shortcode.substring(0, 2)}</small>
                                                    </Avatar>
                                                  }
                                                  label={areasName[shortcode]}
                                                  style={{ margin: "4px" }}
                                                />
                                              )
                                          )}
                                        </div>
                                      )}
                                    >
                                      {areasShortcode.map(shortcode => (
                                        <MenuItem key={shortcode} value={shortcode}>
                                          <Checkbox color="default" checked={users[key].areas && users[key].areas.indexOf(shortcode) > -1} />
                                          <ListItemText primary={areasName[shortcode] + " (" + shortcode + ")"} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <FormHelperText>ATTENTION : Modification instantanée de la base de données (admin uniquement)</FormHelperText>
                                  </FormControl>

                                  <Typography variant="h6" style={{ marginTop: "16px" }}>
                                    Groupe
                                  </Typography>

                                  <FormControl margin="normal" fullWidth error>
                                    <Select
                                      value={users[key].group ? users[key].group : ""}
                                      onChange={e => this.refreshGroup(e, key, this.props)}
                                      disabled={userGroup !== "admin"}
                                      input={<Input id="selectUserGroup" />}
                                    >
                                      {Object.keys(translation.users.groups).map(userGroup => (
                                        <MenuItem key={userGroup} value={userGroup}>
                                          {translation.users.groups[userGroup]["fr"]}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    <FormHelperText>ATTENTION : Modification instantanée de la base de données (admin uniquement)</FormHelperText>
                                  </FormControl>
                                </Fragment>
                              )}
                            </div>
                          </ExpansionPanelDetails>
                        </div>
                      )}
                    </ExpansionPanel>
                  ))}
            </div>
          )}
          {/* END : Display users without area */}
        </div>
      </Fade>
    )
  }
}

const mapStateToProps = state => {
  return {
    userGroup: state.userReducer.userGroup,
    userAreas: state.userReducer.userAreas,
    users: state.dataReducer.users
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Users))
