import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import moment from "moment"
import querystring from "querystring"
import { withStyles } from "@material-ui/core/styles"

import { Typography } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
import { FormControl } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import { InputLabel } from "@material-ui/core"
import { Select } from "@material-ui/core"
import { MenuItem } from "@material-ui/core"
import { Grow } from "@material-ui/core"
import { Dialog } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { withMobileDialog } from "@material-ui/core"
import { ExpansionPanel } from "@material-ui/core"
import { ExpansionPanelDetails } from "@material-ui/core"
import { ExpansionPanelSummary } from "@material-ui/core"
import { ExpansionPanelActions } from "@material-ui/core"
import { List } from "@material-ui/core"
import { ListItem } from "@material-ui/core"
import { ListItemText } from "@material-ui/core"
import { FormControlLabel } from "@material-ui/core"
import { Checkbox } from "@material-ui/core"
import { FormGroup } from "@material-ui/core"
import { Paper } from "@material-ui/core"
import { Input } from "@material-ui/core"
import { ListItemIcon } from "@material-ui/core"

import CloseIcon from "@material-ui/icons/Close"
import EditIcon from "@material-ui/icons/Edit"
import CancelIcon from "@material-ui/icons/Cancel"
import SaveIcon from "@material-ui/icons/Save"
import DeleteIcon from "@material-ui/icons/Delete"
import MapIcon from "@material-ui/icons/Map"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AlertIcon from "@material-ui/icons/Warning"
import SendIcon from "@material-ui/icons/Send"
import NotificationIcon from "@material-ui/icons/NotificationsActive"
import ContactIcon from "@material-ui/icons/PersonPin"
import AddImageIcon from "@material-ui/icons/AddPhotoAlternate"
import RemoveIcon from "@material-ui/icons/RemoveCircle"

import firebase from "../config/firebase"

import { withSnackbar } from "notistack"

import { message } from "../functions/appInterface"
import { sendEmail } from "../functions/apiLambda"
import { sendSms } from "../functions/apiLambda"
import { generateId, dateNow, timeNow } from "../functions/appStrings"
import { gsmEncoding } from "../functions/appStrings"
import { getRessourceStatusIcon, getRessourceStatusColor } from "../functions/getRessourceStatus"
import { translation } from "../config/languages.json"

const myLanguage = "fr" // <================================== Charger dynamiquement dans Redux (paramètre utilisateur)

const styles = theme => ({
  panelDetails: {
    minWidth: 150,
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  deleteButton: {
    color: theme.palette.error.main
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20
  },
  capitalize: {
    textTransform: "capitalize"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  lowercase: {
    textTransform: "lowercase"
  }
})

class RessourceContainer extends Component {
  state = {
    isUpdating: false,
    switchActive: true,
    openDeleteFeatureDialog: false,
    openSendAlertDialog: false,
    expanded: null,
    emailNotification: true,
    smsNotification: false
  }

  // Define alert message context
  message = message
  // END : Define alert message context

  componentDidMount() {
    if (this.props.ressourceNewMode) {
      this.setState({ isUpdating: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeRessourceId !== this.props.activeRessourceId) {
      this.setState(state => ({ switchActive: !state.switchActive }))
      setTimeout(() => {
        this.setState(state => ({ switchActive: !state.switchActive }))
      }, 500)
    }
  }

  closeContainer = () => {
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: null })
    this.props.dispatch({ type: "TOGGLE_ALERT_NEW_MODE", value: null })
    this.props.dispatch({ type: "TOGGLE_ALERT_VIEW_MODE", value: null })
    this.props.dispatch({ type: "UPDATE_ACTIVE_ALERT", value: "" })
  }

  // Refresh active fields
  refreshSpecies(e) {
    const nextRessource = Object.assign({}, this.props.activeRessource)
    nextRessource.species = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
    this.setState({ errorSpecies: false })
  }
  refreshComment(e) {
    const nextRessource = Object.assign({}, this.props.activeRessource)
    nextRessource.comment = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
    this.setState({ errorComment: false })
  }
  refreshStatus(e) {
    const nextRessource = Object.assign({}, this.props.activeRessource)
    nextRessource.status = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
    this.setState({ errorStatus: false })
  }
  refreshBasis(e) {
    const nextRessource = Object.assign({}, this.props.activeRessource)
    nextRessource.basis = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
    this.setState({ errorBasis: false })
  }
  refreshDate(e) {
    const nextRessource = Object.assign({}, this.props.activeRessource)
    nextRessource.date = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
    this.setState({ errorDate: false })
  }
  refreshTime(e) {
    const nextRessource = Object.assign({}, this.props.activeRessource)
    nextRessource.time = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
    this.setState({ errorTime: false })
  }

  refreshImage(e) {
    const file = e.target.files[0]
    console.log("file", file)

    if (/\.(jpg|jpeg)$/i.test(file.name)) {
      const localImageUrl = window.URL.createObjectURL(file)
      console.log("localImageUrl", localImageUrl)

      // Save temp blob to Redux
      const nextRessource = Object.assign({}, this.props.activeRessource)
      nextRessource.image = localImageUrl
      this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
    } else {
      console.error("Error : selected file isn't a JPG/JPEG image")
    }
  }

  clearImage() {
    // Save temp blob to Redux
    const nextRessource = Object.assign({}, this.props.activeRessource)
    nextRessource.image = null
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
  }

  refreshMethod(e) {
    // const nextAlert = Object.assign({}, this.props.nextAlert)
    const nextAlert = {}
    nextAlert.method = e.target.value
    this.props.dispatch({ type: "UPDATE_NEXT_ALERT", value: nextAlert })
    this.setState({ errorMethod: false })
  }
  refreshDistance(e) {
    const nextAlert = Object.assign({}, this.props.nextAlert)
    nextAlert.radius = e.target.value
    this.props.dispatch({ type: "UPDATE_NEXT_ALERT", value: nextAlert })
    this.setState({ errorDistance: false })

    this.props.dispatch({ type: "UPDATE_ALERT_CONTACTS", value: null }) // Reset if not found
  }
  // END : Refresh active fields

  handleDeleteFeature = () => {
    const { ressources, activeRessourceId, joins, alerts } = this.props

    // Update Firebase
    firebase
      .database()
      .ref("/ressources/" + activeRessourceId)
      .remove(error => {
        if (error) {
          // Write failed...
          console.error("Error 0a8ef60f-893b-4dc3-bf88-29c7585c2859", error)
          this.message("error", "Erreur : " + error.message)
        } else {
          // Write success...
          this.message("success", "Base de données mise à jour !")

          delete ressources[activeRessourceId]
          this.setState({ openDeleteFeatureDialog: false })
          // Save to Redux global state
          this.props.dispatch({ type: "UPDATE_DATA_RESSOURCES", value: ressources })
          this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: null })

          this.props.dispatch({ type: "TOGGLE_CONTACT_UPDATE_MODE", value: false })
          this.props.dispatch({ type: "TOGGLE_RESSOURCE_UPDATE_MODE", value: false })
          this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
          this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })

          Object.keys(alerts).forEach(key => {
            if (alerts[key].ressource === activeRessourceId) {
              // Update ALERTS
              delete alerts[key]
              // Save to Redux global state
              this.props.dispatch({ type: "UPDATE_DATA_ALERTS", value: alerts })
              // Update Firebase
              firebase
                .database()
                .ref("/alerts/" + key)
                .remove(error => {
                  if (error) {
                    // Write failed...
                    console.error("Error 2c0e43ac-6edf-4f53-b18a-a72ef13a70a2", error)
                    this.message("error", "Erreur : " + error.message)
                  }
                })

              // Update JOINS
              const alertRegex = new RegExp(key)
              Object.keys(joins)
                .filter(key => alertRegex.test(key))
                .forEach(key => {
                  delete joins[key]
                  // Save to Redux global state
                  this.props.dispatch({ type: "UPDATE_DATA_JOINS", value: joins })
                  // Update Firebase
                  firebase
                    .database()
                    .ref("/joins/" + key)
                    .remove(error => {
                      if (error) {
                        // Write failed...
                        console.error("Error 8f47cffd-7eea-47a2-b670-1b5da93a3bb8", error)
                        this.message("error", "Erreur : " + error.message)
                      }
                    })
                })
            }
          })

          // Delete image
          console.log("removing image from Firebase...")

          const storageRef = firebase.storage().ref()
          const fileRef = storageRef.child("ressources/" + this.props.activeRessource.area + "/" + this.props.activeRessourceId + ".jpg")

          // Delete the file
          fileRef
            .delete()
            .then(function() {
              // File deleted successfully
              // this.message("success", "Fichier supprimé") <=========================== ERROR this.message not a function
              console.log("File deleted successfully !")
            })
            .catch(function(error) {
              // Uh-oh, an error occurred!
              console.error("Error 21c61c84-62ef-4c09-a534-ec4463f7bd52", error)
              // this.message("error", "Erreur : " + error.message) <=========================== ERROR this.message not a function
            })

          // END Delete image
        }
      })
  }

  handleDeleteAlert = () => {
    const { activeAlert, alerts, joins } = this.props

    // Update Firebase
    firebase
      .database()
      .ref("/alerts/" + activeAlert.id)
      .remove(error => {
        if (error) {
          // Write failed...
          console.error("Error f03e1d1f-4dba-43af-8078-afc020d1a7da", error)
          this.message("error", "Erreur : " + error.message)
        } else {
          delete alerts[activeAlert.id]
          // Save to Redux global state
          this.props.dispatch({ type: "UPDATE_DATA_ALERTS", value: alerts })
          this.props.dispatch({ type: "UPDATE_ACTIVE_ALERT", value: "" })

          var regex = new RegExp(activeAlert.id)
          // const nextJoins = Object.keys(joins).filter(key => !regex.test(key))
          // // Save to Redux global state
          // this.props.dispatch({ type: "UPDATE_DATA_JOINS", value: nextJoins })

          Object.keys(joins)
            .filter(key => regex.test(key))
            .forEach(key => {
              delete joins[key]
              // Save to Redux global state
              this.props.dispatch({ type: "UPDATE_DATA_JOINS", value: joins })

              // Update Firebase
              firebase
                .database()
                .ref("/joins/" + key)
                .remove(error => {
                  if (error) {
                    // Write failed...
                    console.error("Error 92f54bb7-97f7-4d1a-8e43-0202d62b57fc", error)
                    this.message("error", "Erreur : " + error.message)
                  }
                })
            })
        }
      })
  }

  saveUpdate = () => {
    const { ressources, activeRessource, activeRessourceId } = this.props

    if (activeRessource.event && activeRessource.status && activeRessource.date && activeRessource.time) {
      this.setState(state => ({ isUpdating: !state.isUpdating }))

      if (activeRessource === ressources[activeRessourceId]) {
        // Need no update
        this.message("info", "Aucune modification n'a été effectuée...")
        this.setState({ isUpdating: true })
      } else {
        if (activeRessource.image !== ressources[activeRessourceId].image) {
          if (activeRessource.image) {
            // Upload image
            console.log("new image detected !")

            const file = document.querySelector("#ressourceImage").files[0]
            console.log("file querySelector", file)

            const storageRef = firebase.storage().ref()
            const uploadTask = storageRef.child("ressources/" + this.props.activeRessource.area + "/" + this.props.activeRessourceId + ".jpg").put(file)

            const props = this.props

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
              firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log("Upload is " + progress + "% done")
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log("Upload is paused")
                    // this.message("warning", "Chargement mis en pause")
                    break
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log("Upload is running")
                    // this.message("info", "Chargement en cours...")
                    break
                  default:
                    console.log("Unknown task state")
                }
              },
              function(error) {
                console.error("Error bf5881ff-48fa-4785-945d-31d33c1be32e", error)
                console.log("A full list of error codes is available at https://firebase.google.com/docs/storage/web/handle-errors")
                // this.message("error", "Erreur : " + error.message) <=========================== ERROR this.message not a function
              },
              function() {
                // Upload completed successfully
                // this.message("success", "Fichier envoyé") <=========================== ERROR this.message not a function
                console.log("Upload completed successfully !")
                // Now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  // Update Firebase
                  firebase
                    .database()
                    .ref("/ressources/" + activeRessourceId)
                    .update({ image: downloadURL }, error => {
                      if (error) {
                        // Write failed...
                        console.error("Error a5a31fda-0b28-4fa8-bc7e-525459ba9799", error)
                        // this.message("error", "Erreur : " + error.message) <=========================== ERROR this.message not a function
                      } else {
                        // Write success...
                        // this.message("success", "Base de données mise à jour !") <=========================== ERROR this.message not a function
                        // Save temp blob to Redux
                        const nextRessource = Object.assign({}, props.activeRessource)
                        nextRessource.image = downloadURL
                        props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
                      }
                    })
                  // END Update Firebase
                })
              }
            )
            // END Upload image
          } else {
            // Delete image
            console.log("no image detected, removing from Firebase...")

            const storageRef = firebase.storage().ref()
            const fileRef = storageRef.child("ressources/" + this.props.activeRessource.area + "/" + this.props.activeRessourceId + ".jpg")

            // Delete the file
            fileRef
              .delete()
              .then(function() {
                // File deleted successfully
                // this.message("success", "Fichier supprimé") <=========================== ERROR this.message not a function
                console.log("File deleted successfully !")
              })
              .catch(function(error) {
                // Uh-oh, an error occurred!
                console.error("Error 90598350-29fd-4073-a4e7-adc0717f8d55", error)
                // this.message("error", "Erreur : " + error.message) <=========================== ERROR this.message not a function
              })

            // END Delete image
          }
        }

        // Update Firebase
        firebase
          .database()
          .ref("/ressources/" + activeRessourceId)
          .update(activeRessource, error => {
            if (error) {
              // Write failed...
              console.error("Error defdfd08-c4fd-442a-8931-9655e42cba08", error)
              this.message("error", "Erreur : " + error.message)
            } else {
              // Write success...
              this.message("success", "Base de données mise à jour !")
              // Save to Redux global state
              const nextRessources = Object.assign({}, ressources)
              nextRessources[activeRessourceId] = activeRessource
              this.props.dispatch({ type: "UPDATE_DATA_RESSOURCES", value: nextRessources })

              this.props.dispatch({ type: "TOGGLE_CONTACT_UPDATE_MODE", value: false })
              this.props.dispatch({ type: "TOGGLE_RESSOURCE_UPDATE_MODE", value: false })
              this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
              this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })
            }
          })
        // END Update Firebase
      }
    } else {
      this.message("warning", "L'un des champs est vide...")
      if (!activeRessource.event.category) {
        this.setState({ errorEventCategory: true })
      }
      if (!activeRessource.status) {
        this.setState({ errorStatus: true })
      }
      if (!activeRessource.date) {
        this.setState({ errorDate: true })
      }
      if (!activeRessource.time) {
        this.setState({ errorTime: true })
      }
    }
  }

  saveNew = () => {
    const { activeRessource, activeRessourceId, userFirstName, userLastName, userEmail, userPhone, userGroup } = this.props

    if (activeRessource.event && activeRessource.status && activeRessource.date && activeRessource.time) {
      this.setState(state => ({ isUpdating: !state.isUpdating }))

      // Update Firebase
      firebase
        .database()
        .ref("/ressources/" + activeRessourceId)
        .update(activeRessource, error => {
          if (error) {
            // Write failed...
            console.error("Error a1d3f082-19bb-48b5-b601-a836483eb7aa", error)
            this.message("error", "Erreur : " + error.message)
          } else {
            // Write success...
            this.message("success", "Base de données mise à jour !")
            // Save to Redux global state
            // const nextRessources = Object.assign({}, ressources)
            // nextRessources[activeRessourceId] = activeRessource
            // this.props.dispatch({ type: "UPDATE_DATA_RESSOURCES", value: nextRessources })
            this.props.dispatch({ type: "TOGGLE_RESSOURCE_POINT_DRAW_MODE", value: false })
            this.props.dispatch({ type: "TOGGLE_RESSOURCE_NEW_MODE", value: false })
            this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })

            // Send notification to area manager and admin
            if (!(new RegExp("@proxiloup.com").test(userEmail) || userEmail === "lyon.gus@gmail.com")) {
              firebase
                .database()
                .ref("/users/")
                .once("value")
                .then(snapshot => {
                  const users = snapshot.val()

                  const recipients = Object.keys(users)
                    .filter(key => users[key].areas && users[key].areas.includes(activeRessource.area))
                    .filter(key => users[key].group && ["inspector", "manager", "admin"].includes(users[key].group))
                    .reduce((obj, key) => {
                      obj[key] = users[key]
                      return obj
                    }, {})

                  return recipients
                })
                .then(recipients => {
                  Object.keys(recipients).forEach(recipient => {
                    const userName = userFirstName + " " + userLastName

                    const ressourceDateTime =
                      moment(activeRessource.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY") + " " + moment(activeRessource.time, ["HH:mm:ss"]).format("HH:mm")

                    const mailFrom = `"Proxiloup.com" <contact@proxiloup.com>` // verified AWS email
                    const mailReplyTo = ``
                    const mailTo = recipients[recipient].email
                    const mailSubject = userName + " a créé un évènement"
                    const mailBody = `
                          <html>
                            <head>
                            <title>${mailSubject}</title>
                            </head>
                            <body>
                              <p>Un nouvel évènement a été créé sur votre territoire.</p>
                              <p>
                                EVENEMENT<br />
                                <b>- Catégorie : </b>${activeRessource.event.category}<br />
                                <b>- Espèce : </b>${translation.ressource.species[activeRessource.species]["fr"]}<br />
                                <b>- Responsabilité prédateur : </b>${translation.ressource.status[activeRessource.status]["fr"]}<br />
                                <b>- Commentaires : </b>${activeRessource.comment}<br />
                                <b>- Date du constat : </b>${ressourceDateTime}<br />
                                <b>- Commune : </b>${activeRessource.location.city}<br />
                              </p>
                              <p>
                                AUTEUR<br />
                                <b>- Nom : </b>${userName}<br />
                                <b>- Email : </b>${userEmail}<br />
                                <b>- Phone : </b>${userPhone}<br />
                                <b>- Groupe : </b>${translation.users.groups[userGroup]["fr"]}<br />
                              </p>
                              <p>
                              --
                              </p>
                              <p><em>PS : Ceci est un message automatique envoyé via l'application Proxiloup.com</em></p>
                            </body>
                          </html>
                        `

                    sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody).then(response => {
                      // console.log("response : ", response)
                      if (response.status === 200) {
                        // SUCCESS
                        this.setState({ isLoading: false, submit: "success" })
                      } else {
                        // ERROR
                        this.setState({ isLoading: false, submit: "error" })
                      }
                      return response.json()
                    })
                  })
                })
            }
            // END OF Send notification to area manager and admin
          }
        })
    } else {
      this.message("warning", "L'un des champs est vide...")
      if (!activeRessource.event.category) {
        this.setState({ errorEventCategory: true })
      }
      if (!activeRessource.status) {
        this.setState({ errorStatus: true })
      }
      if (!activeRessource.date) {
        this.setState({ errorDate: true })
      }
      if (!activeRessource.time) {
        this.setState({ errorTime: true })
      }
    }
  }

  // Functions Toggle update modes
  enableUpdateMode = () => {
    this.setState({ isUpdating: true })
    this.setState({
      errorEventField0: false,
      errorComment: false,
      errorStatus: false,
      errorDate: false,
      errorTime: false
    })

    this.props.dispatch({ type: "TOGGLE_RESSOURCE_UPDATE_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_CONTACT_UPDATE_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: false })
    this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: false })
  }
  disableUpdateMode = () => {
    this.message("warning", "Opération annulée...")

    this.setState({ isUpdating: false })
    this.setState({
      errorEventField0: false,
      errorComment: false,
      errorStatus: false,
      errorDate: false,
      errorTime: false
    })

    // Restore original data
    const { ressources, activeRessourceId } = this.props
    const ressource = ressources[activeRessourceId]
    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: ressource })
    // END : Restore original data

    this.props.dispatch({ type: "TOGGLE_RESSOURCE_UPDATE_MODE", value: false })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
  }
  // END : Functions Toggle update modes

  cancelNew = () => {
    if (this.state.isUpdating) {
      this.message("warning", "Opération annulée...")
    }
    this.setState(state => ({ isUpdating: !state.isUpdating }))
    this.setState({
      errorEventField0: false,
      errorComment: false,
      errorStatus: false,
      errorDate: false,
      errorTime: false
    })
    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: null })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_POINT_DRAW_MODE", value: false })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_NEW_MODE", value: false })
  }

  handleCloseDialog = () => {
    this.setState({ openDeleteFeatureDialog: false })
    this.setState({ openSendAlertDialog: false })
  }

  handleFlyToActivePosition = () => {
    const { lng, lat } = this.props.activeRessource
    // Save to Redux global state
    this.props.dispatch({ type: "TOGGLE_FLY_TO_ACTIVE_FEATURE", value: { lng, lat } })
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
    if (expanded) {
      if (panel === "alertNew") {
        this.props.dispatch({ type: "TOGGLE_ALERT_NEW_MODE", value: true })
        this.props.dispatch({ type: "TOGGLE_ALERT_VIEW_MODE", value: false })
      } else if (panel === "alert") {
        this.props.dispatch({ type: "TOGGLE_ALERT_VIEW_MODE", value: true })
        this.props.dispatch({ type: "TOGGLE_ALERT_NEW_MODE", value: false })
      } else {
        this.props.dispatch({ type: "TOGGLE_ALERT_NEW_MODE", value: false })
        this.props.dispatch({ type: "TOGGLE_ALERT_VIEW_MODE", value: false })
      }
    } else {
      this.props.dispatch({ type: "TOGGLE_ALERT_NEW_MODE", value: false })
      this.props.dispatch({ type: "TOGGLE_ALERT_VIEW_MODE", value: false })
    }
  }

  enablePolygonDrawMode = () => {
    this.props.dispatch({ type: "TOGGLE_ALERT_POLYGON_DRAW_MODE", value: true })
  }

  handleSendAlert = () => {
    const { alertContacts, user, activeRessourceId, activeRessource, nextAlert, users } = this.props

    const contactsNumber = Object.keys(alertContacts).length
    const userId = user.uid
    const ressourceId = activeRessourceId
    const ressourceEvent = activeRessource.event.category
    const ressourceSpecies = activeRessource.species
    const ressourceComment = activeRessource.comment
    const ressourceStatus = activeRessource.status
    const ressourceDate = activeRessource.date
    const ressourceTime = activeRessource.time
    const ressourceCity = activeRessource.location.city
    // const alertId = "AL" + ressourceId.slice(2)
    const alertId = generateId("AL")
    const alertDate = dateNow()
    const alertTime = timeNow()
    const alertMethod = nextAlert.method
    const alertRadius = () => (nextAlert.radius ? nextAlert.radius : null)
    const alertPolygon = () => (nextAlert.polygon ? nextAlert.polygon : null)

    const alert = {
      author: userId,
      ressource: ressourceId,
      date: alertDate,
      time: alertTime,
      method: alertMethod,
      radius: alertRadius(),
      polygon: alertPolygon(),
      contacts: contactsNumber
    }

    // Firebase alerts update
    firebase
      .database()
      .ref("/alerts/" + alertId)
      .update(alert, error => {
        if (error) {
          // Write failed...
          console.error("Error a2388735-bdb8-4956-998d-04af10a054e5", error)
          this.message("error", "Erreur : " + error.message)
        } else {
          // Write success...
          this.message("success", "Base de données mise à jour !")
          // Save to Redux global state
          const alerts = { ...this.props.alerts }
          alerts[alertId] = alert
          this.props.dispatch({ type: "UPDATE_DATA_ALERTS", value: alerts })
          this.props.dispatch({ type: "UPDATE_ACTIVE_ALERT", value: alert })

          const joins = { ...this.props.joins }
          const contacts = this.props.alertContacts
          // For each contact
          Object.keys(contacts).forEach(contactId => {
            const contact = contacts[contactId]
            const contactAuthor = contact.author
            const contactEmail = contact.email
            const contactPhone = contact.phone
            const contactName = contact.firstname + " " + contact.lastname
            const contactTitle = contact.title
            const contactActivity = contact.activity
            // const contactLng = contact.lng
            // const contactLat = contact.lat
            const contactDistance = contact.distance
            const contactCity = contact.location.city

            const join = {
              alert: alertId,
              contact: contactId,
              user: contactAuthor,
              distance: contactDistance
            }

            // Firebase joins update
            const joinId = alertId + "-" + contactId
            firebase
              .database()
              .ref("/joins/" + joinId)
              .update(join, error => {
                if (error) {
                  // Write failed...
                  console.error("Error b140f142-a1b5-4e7b-a628-1fcdc6831b2d", error)
                  this.message("error", "Erreur : " + error.message)
                } else {
                  // Save to Redux global state
                  joins[joinId] = join
                  this.props.dispatch({ type: "UPDATE_DATA_JOINS", value: joins })

                  // Send email & sms notification
                  const API_ENDPOINT = window.location.origin + "/.netlify/functions/sendAlert"
                  const secretKey = process.env.REACT_APP_AMAZON_SECRET

                  const alertAuthor = users[userId].firstname + " " + users[userId].lastname

                  const params = {
                    secretKey,
                    contactEmail,
                    contactName,
                    contactDistance,
                    contactTitle,
                    contactActivity,
                    contactCity,
                    alertDate: moment(alertDate, ["YYYY-MM-DD"]).format("DD/MM/YYYY"),
                    alertTime: moment(alertTime, ["HH:mm:ss"]).format("HH:mm"),
                    alertMethod,
                    alertAuthor,
                    contactsNumber,
                    ressourceEvent,
                    ressourceSpecies,
                    ressourceComment,
                    ressourceStatus,
                    ressourceDate: moment(ressourceDate, ["YYYY-MM-DD"]).format("DD/MM/YYYY"),
                    ressourceTime: moment(ressourceTime, ["HH:mm:ss"]).format("HH:mm"),
                    ressourceCity
                  }

                  // Send email notification
                  if (this.state.emailNotification) {
                    fetch(API_ENDPOINT, {
                      method: "POST",
                      headers: { "Content-Type": "application/x-www-form-urlencoded" },
                      body: querystring.stringify(params)
                    })
                      .then(response => {
                        return response.json()
                      })
                      .then(data => {
                        console.log(data.response)
                      })
                      .catch(error => {
                        console.error(error)
                      })
                  }
                  // END Send email notification

                  // Send sms notification
                  if (this.state.smsNotification) {
                    const smsSender = `Proxiloup` // display name in receiver SMS app
                    const smsReceiver = contactPhone
                    const smsMessage = gsmEncoding(
                      `Nouvel évènement signalé à ${contactDistance} Km de votre troupeau. COMMUNE ${ressourceCity}. STATUT ${
                        translation.ressource.status[ressourceStatus]["fr"]
                      }. EVENEMENT ${translation.ressource.species[ressourceSpecies]["fr"]} ${ressourceEvent}. AUTEUR ${alertAuthor}.`
                    )

                    console.log("smsMessage", smsMessage)

                    sendSms(smsSender, smsReceiver, smsMessage).then(response => {
                      // console.log("response : ", response)
                      if (response.status === 200) {
                        // SUCCESS
                        this.setState({ isLoading: false, submit: "success" })
                        console.log("SUCCESS ==> SMS sent to " + contactPhone)
                      } else {
                        // ERROR
                        this.setState({ isLoading: false, submit: "error" })
                        console.error("Error 834e79a7-4276-429c-8e9b-8af06ff4fb81", response)
                        this.message("error", "Erreur lors de l'envoi du SMS")
                      }
                      return response.json()
                    })
                  }
                  // END Send sms notification

                  // END Send email & sms notification
                }
              })
          })
          // END : For each contact

          this.message("success", "Signalement envoyé à " + contactsNumber + " personne" + (contactsNumber > 1 ? "s" : ""))

          // Reset UI
          this.handleCloseDialog()
          this.setState({ expanded: "alert" })
          this.props.dispatch({ type: "TOGGLE_ALERT_POLYGON_DRAW_MODE", value: false })
          this.props.dispatch({ type: "TOGGLE_ALERT_NEW_MODE", value: false })
          this.props.dispatch({ type: "UPDATE_ALERT_CONTACTS", value: null })
        }
        // END : Write success...
      })
    // END : Firebase alerts update
  }

  // Change notification type
  handleChangeNotification = name => event => {
    this.setState({ [name]: event.target.checked })
  }
  // END : Change notification type

  handleRefreshEventField = field => e => {
    const nextRessource = Object.assign({}, this.props.activeRessource)
    // Init event object
    if (!nextRessource.event || field === "category") {
      nextRessource.event = {}
    }
    nextRessource.event[`${field}`] = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
  }

  handleRefreshBasis = () => event => {
    const nextRessource = Object.assign({}, this.props.activeRessource)
    nextRessource.basis = event.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
    this.setState({ errorBasis: false })
  }

  render() {
    const { isUpdating } = this.state
    const { classes, users, activeRessource, activeAlert, nextAlert, alertContacts, alertPolygonDrawMode } = this.props
    const { fullScreen } = this.props

    let contactsNumber = ""
    if (alertContacts) {
      contactsNumber = Object.keys(alertContacts).length
    }

    const EditButtonsBar = () => (
      <Paper style={{ margin: "16px 0", padding: "8px", backgroundColor: "black" }}>
        <Grid container direction="row" justify="space-around" alignItems="center" style={{ paddingRight: 0 }}>
          {!this.props.ressourceNewMode && (
            <Fragment>
              <Button color="primary" size="small" onClick={this.disableUpdateMode}>
                <CancelIcon className={classes.buttonIcon} />
                Annuler
              </Button>
              <Button color="secondary" size="small" onClick={this.saveUpdate}>
                <SaveIcon className={classes.buttonIcon} />
                Mettre à jour
              </Button>
            </Fragment>
          )}
          {this.props.ressourceNewMode && (
            <Fragment>
              <Button color="primary" size="small" onClick={this.cancelNew}>
                <CancelIcon className={classes.buttonIcon} />
                Annuler
              </Button>
              <Button color="secondary" size="small" onClick={this.saveNew}>
                <SaveIcon className={classes.buttonIcon} />
                Enregistrer
              </Button>
            </Fragment>
          )}
        </Grid>
      </Paper>
    )

    const DeleteButtonsBar = () => (
      <Paper style={{ margin: "16px 0", padding: "8px", backgroundColor: "black" }}>
        <Grid container direction="row" justify="space-around" alignItems="center" style={{ paddingRight: 0 }}>
          {(this.props.userGroup === "admin" || (this.props.userGroup !== "admin" && activeRessource.author === this.props.user.uid)) && (
            <Button size="small" className={classes.deleteButton} onClick={() => this.setState({ openDeleteFeatureDialog: true })}>
              <DeleteIcon className={classes.buttonIcon} />
              Supprimer
            </Button>
          )}
        </Grid>
      </Paper>
    )

    return (
      <Fragment>
        {activeRessource && (
          <Fragment>
            <Grid container direction="row" justify="space-between" alignItems="center" onClick={this.toggleDrawerMobile} style={{ flexWrap: "nowrap" }}>
              <Typography variant="h6" color="inherit" style={{ marginLeft: "8px" }} noWrap>
                Evènement - Signalement
              </Typography>
              <Tooltip title="Fermer" placement="left" TransitionComponent={Zoom} disableFocusListener>
                <div>
                  <IconButton onClick={this.closeContainer} disabled={!this.props.ressourceViewMode} color="inherit">
                    <CloseIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>

            <Grow in={this.state.switchActive}>
              {/* DIV GROW !important */}
              <div>
                {/* Editing action buttons bar */}
                {isUpdating && <EditButtonsBar />}
                {/* END : Editing action buttons bar  */}

                {/* Send alert drawer */}
                {this.props.ressourceViewMode &&
                  !activeAlert &&
                  // IF OBSERVER OR ADMIN
                  (["observer", "admin"].includes(this.props.userGroup) ||
                    // IF INSPECTOR
                    (this.props.userGroup === "inspector" &&
                      ((users[activeRessource.author] && ["observer"].includes(users[activeRessource.author].group)) ||
                        this.props.user.uid === activeRessource.author))) && (
                    <ExpansionPanel expanded={this.state.expanded === "alertNew"} onChange={this.handleChangePanel("alertNew")}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                          <Typography>
                            <strong> Signalement : </strong>
                          </Typography>
                          <Grid item style={{ marginLeft: "8px", marginRight: "8px" }}>
                            <AlertIcon color="action" />
                          </Grid>
                          <Grid item>
                            <Typography>Prévenir les éleveurs</Typography>
                          </Grid>
                        </Grid>
                      </ExpansionPanelSummary>
                      <Divider />
                      <ExpansionPanelDetails>
                        <div className={classes.panelDetails}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel shrink>Notifications</InputLabel>
                            <div style={{ marginTop: "25px" }}>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.emailNotification}
                                      onChange={this.handleChangeNotification("emailNotification")}
                                      value="emailNotification"
                                      style={{ padding: "0 12px" }}
                                    />
                                  }
                                  label="Signaler par Email"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.smsNotification}
                                      onChange={this.handleChangeNotification("smsNotification")}
                                      value="smsNotification"
                                      style={{ padding: "0 12px" }}
                                    />
                                  }
                                  label="Signaler par SMS"
                                />
                              </FormGroup>
                            </div>
                          </FormControl>

                          <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="alertMethod">Méthode de sélection</InputLabel>
                            <Select
                              value={nextAlert.method ? nextAlert.method : ""}
                              onChange={e => this.refreshMethod(e)}
                              // disabled={!isUpdating}
                              inputProps={{
                                id: "alertMethod",
                                name: "alertMethod"
                              }}
                              error={this.state.errorMethod}
                            >
                              <MenuItem value="">
                                <em>Choisir...</em>
                              </MenuItem>
                              <MenuItem value="auto">Automatique (distance, rayon)</MenuItem>
                              <MenuItem value="manual">Manuelle (polygone)</MenuItem>
                            </Select>
                          </FormControl>

                          {nextAlert.method === "auto" && (
                            <FormControl margin="normal" fullWidth>
                              <InputLabel htmlFor="alertDistance">Distance</InputLabel>
                              <Select
                                value={nextAlert.radius ? nextAlert.radius : ""}
                                onChange={e => this.refreshDistance(e)}
                                // disabled={!isUpdating}
                                inputProps={{
                                  id: "alertDistance",
                                  name: "alertDistance"
                                }}
                                error={this.state.errorDistance}
                              >
                                <MenuItem value="0.01">
                                  <em>Choisir...</em>
                                </MenuItem>
                                <MenuItem value="5">5 Km</MenuItem>
                                <MenuItem value="10">10 Km</MenuItem>
                                <MenuItem value="15">15 Km</MenuItem>
                                <MenuItem value="20">20 Km</MenuItem>
                                <MenuItem value="25">25 Km</MenuItem>
                                <MenuItem value="30">30 Km</MenuItem>
                                <MenuItem value="35">35 Km</MenuItem>
                                <MenuItem value="40">40 Km</MenuItem>
                                <MenuItem value="45">45 Km</MenuItem>
                                <MenuItem value="50">50 Km</MenuItem>
                              </Select>
                            </FormControl>
                          )}

                          {nextAlert.method === "manual" && (
                            <FormControl margin="normal" fullWidth>
                              <InputLabel shrink>Traçage manuel</InputLabel>
                              <div style={{ marginTop: "25px" }}>
                                {!alertPolygonDrawMode ? (
                                  <Button color="primary" variant="outlined" fullWidth onClick={this.enablePolygonDrawMode}>
                                    <MapIcon className={classes.buttonIcon} />
                                    Tracer un polygone
                                  </Button>
                                ) : (
                                  <Typography>
                                    <em>Traçage en cours sur la carte...</em>
                                  </Typography>
                                )}
                              </div>
                            </FormControl>
                          )}

                          {alertContacts && contactsNumber > 0 && (
                            <FormControl margin="normal" fullWidth>
                              <Typography>
                                {contactsNumber} troupeau{contactsNumber > 1 && "x"} trouvé{contactsNumber > 1 && "s"} à proximité
                              </Typography>
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ marginTop: "25px" }}
                                fullWidth
                                onClick={() => this.setState({ openSendAlertDialog: true })}
                              >
                                <NotificationIcon className={classes.buttonIcon} />
                                Continuer
                              </Button>
                            </FormControl>
                          )}
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                    </ExpansionPanel>
                  )}
                {/* END : Send alert drawer */}

                {/* Alert infos drawer */}
                {this.props.ressourceViewMode && activeAlert && (
                  <ExpansionPanel expanded={this.state.expanded === "alert"} onChange={this.handleChangePanel("alert")}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>
                        <strong> Signalement : </strong>Envoyé le {moment(activeAlert.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY")}
                      </Typography>
                    </ExpansionPanelSummary>
                    <Divider />
                    <ExpansionPanelDetails>
                      <div className={classes.panelDetails}>
                        <Typography>
                          <strong>Méthode : </strong>
                          {activeAlert.method === "auto" ? "Sélection automatique (rayon de " + activeAlert.radius + " Km)" : "Sélection manuelle (polygone)"}
                          {" ∙ ∙ ∙ "}
                          <strong>Date du signalement : </strong>
                          {moment(activeAlert.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY")} {moment(activeAlert.time, ["HH:mm:ss"]).format("HH:mm")}
                          {" ∙ ∙ ∙ "}
                          <strong>Signalé par : </strong>
                          {users[activeAlert.author] && users[activeAlert.author].firstname + " " + users[activeAlert.author].lastname}
                        </Typography>
                        <br />
                        <Typography>
                          <strong>Destinataires : </strong>
                          {activeAlert.contacts} personnes alertées
                        </Typography>
                      </div>
                    </ExpansionPanelDetails>
                    {// IF ADMINISTRATOR
                    this.props.userGroup === "admin" && ( // replace or operator ||
                      // IF MANAGER
                      // ...
                      // IF INSPECTOR
                      // (this.props.userGroup === "inspector" && activeRessource.author === this.props.user.uid) ||
                      // IF OBSERVER
                      // (this.props.userGroup === "observer" && activeRessource.author === this.props.user.uid)) && (
                      <div>
                        <Divider />
                        <ExpansionPanelActions style={{ flexWrap: "wrap" }}>
                          <Button
                            size="small"
                            className={classes.deleteButton}
                            // onClick={() => this.setState({ openDeleteAlertDialog: true })}
                            onClick={this.handleDeleteAlert}
                          >
                            <DeleteIcon className={classes.buttonIcon} />
                            Supprimer le signalement
                          </Button>
                        </ExpansionPanelActions>
                      </div>
                    )}
                  </ExpansionPanel>
                )}
                {/* END : Alert infos drawer */}



                {/* Observation infos drawer */}
                <ExpansionPanel expanded={this.state.expanded === "observation"} onChange={this.handleChangePanel("observation")}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      <strong>Observation : </strong>
                      {moment(activeRessource.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY")}
                    </Typography>
                  </ExpansionPanelSummary>
                  <Divider />
                  <ExpansionPanelDetails>
                    <div className={classes.panelDetails}>
                      <FormControl margin="normal" fullWidth>
                        <Grid container spacing={8}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="ressourceDate"
                              label="Date"
                              fullWidth
                              type="date"
                              value={activeRessource.date ? activeRessource.date : ""}
                              onChange={e => this.refreshDate(e)}
                              disabled={
                                !(
                                  isUpdating &&
                                  (["admin"].includes(this.props.userGroup) ||
                                    (activeRessource.author === this.props.user.uid &&
                                      (["inspector"].includes(this.props.userGroup) ||
                                        (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                )
                              }
                              InputLabelProps={{
                                shrink: true
                              }}
                              error={this.state.errorDate}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="ressourceTime"
                              label="Heure"
                              fullWidth
                              type="time"
                              value={activeRessource.time ? activeRessource.time : ""}
                              onChange={e => this.refreshTime(e)}
                              disabled={
                                !(
                                  isUpdating &&
                                  (["admin"].includes(this.props.userGroup) ||
                                    (activeRessource.author === this.props.user.uid &&
                                      (["inspector"].includes(this.props.userGroup) ||
                                        (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                )
                              }
                              InputLabelProps={{
                                shrink: true
                              }}
                              error={this.state.errorTime}
                              // inputProps={{
                              //   step: 300 // 5 min
                              // }}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>

                      <FormControl margin="normal" fullWidth>
                        <TextField
                          id="ressourceAuthor"
                          name="ressourceAuthor"
                          type="text"
                          label="Auteur"
                          fullWidth
                          value={
                            activeRessource.author && users[activeRessource.author]
                              ? users[activeRessource.author].firstname + " " + users[activeRessource.author].lastname
                              : ""
                          }
                          disabled={true}
                        />
                      </FormControl>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* END : Observation infos drawer */}

                {/* Location infos drawer */}
                <ExpansionPanel expanded={this.state.expanded === "location"} onChange={this.handleChangePanel("location")}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      <strong> Localisation : </strong>
                      {activeRessource.location.city + ", " + activeRessource.location.region + ", " + activeRessource.location.country}
                    </Typography>
                  </ExpansionPanelSummary>
                  <Divider />
                  <ExpansionPanelDetails>
                    <div className={classes.panelDetails}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel shrink>Localisation</InputLabel>
                        <Button color="primary" variant="outlined" style={{ marginTop: "25px" }} fullWidth onClick={this.handleFlyToActivePosition}>
                          <MapIcon className={classes.buttonIcon} />
                          Situer sur la carte
                        </Button>
                      </FormControl>

                      <FormControl margin="normal" fullWidth>
                        <TextField
                          id="ressourceCity"
                          name="ressourceCity"
                          type="text"
                          label="Commune"
                          fullWidth
                          value={
                            activeRessource.location
                              ? activeRessource.location.city + ", " + activeRessource.location.region + ", " + activeRessource.location.country
                              : ""
                          }
                          disabled={true}
                        />
                      </FormControl>

                      <FormControl margin="normal" fullWidth>
                        <Grid container spacing={8}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="ressourceLng"
                              name="ressourceLng"
                              type="text"
                              label="Longitude"
                              fullWidth
                              value={activeRessource.lng ? activeRessource.lng : ""}
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="ressourceLat"
                              name="ressourceLat"
                              type="text"
                              label="Latitude"
                              fullWidth
                              value={activeRessource.lat ? activeRessource.lat : ""}
                              disabled={true}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* END : Location infos drawer */}

                                {/* Ressource infos drawer */}
                                <ExpansionPanel expanded={this.state.expanded === "ressource"} onChange={this.handleChangePanel("ressource")}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      {activeRessource.event ? (
                        <Fragment>
                          <strong> Evènement : </strong>
                          {activeRessource.event && (
                            <Fragment>
                              {activeRessource.event.category ? activeRessource.event.category : activeRessource.event[0] && activeRessource.event[0]}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <strong> Evènement : </strong>(à définir...)
                        </Fragment>
                      )}
                      {/* {activeRessource.date ? " ∙ ∙ ∙ " + moment(activeRessource.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY") : ""} */}
                    </Typography>
                  </ExpansionPanelSummary>
                  <Divider />
                  <ExpansionPanelDetails>
                    <div className={classes.panelDetails}>
                      {/* === START : NEW FIELDS === */}

                      {(!activeRessource.event || (activeRessource.event && !activeRessource.event[0])) && (
                        <Fragment>
                          {/* START : Field Event category */}
                          <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="eventCategory">Catégorie d'évènement</InputLabel>
                            <Select
                              value={activeRessource.event && activeRessource.event.category ? activeRessource.event.category : ""}
                              onChange={this.handleRefreshEventField("category")}
                              disabled={
                                !(
                                  isUpdating &&
                                  (["admin"].includes(this.props.userGroup) ||
                                    (activeRessource.author === this.props.user.uid &&
                                      (["inspector"].includes(this.props.userGroup) ||
                                        (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                )
                              }
                              inputProps={{
                                id: "eventCategory",
                                name: "eventCategory"
                              }}
                              error={this.state.errorEventCategory}
                            >
                              <MenuItem value="">
                                <em>Catégorie d'évènement :</em>
                              </MenuItem>
                              <MenuItem value="impact sur bétail-troupeau">impact sur bétail-troupeau</MenuItem>
                              <MenuItem value="indice de présence prédateur">indice de présence prédateur</MenuItem>
                            </Select>
                          </FormControl>
                          {/* STOP : Field Event category */}

                          {/* START : Flock fields */}
                          {activeRessource.event && activeRessource.event.category === "impact sur bétail-troupeau" && (
                            <Fragment>
                              <FormControl margin="normal" fullWidth>
                                <InputLabel htmlFor="ressourceEventFlock">Espèce domestique</InputLabel>
                                <Select
                                  value={activeRessource.event && activeRessource.event.flock ? activeRessource.event.flock : ""}
                                  onChange={this.handleRefreshEventField("flock")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    id: "ressourceEventFlock",
                                    name: "ressourceEventFlock"
                                  }}
                                  error={this.state.errorEventFlock}
                                >
                                  <MenuItem value="">
                                    <em>Espèce domestique :</em>
                                  </MenuItem>
                                  {["bovins", "ovins", "caprins", "mixte"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <InputLabel htmlFor="ressourceEventPlace">Type de localisation</InputLabel>
                                <Select
                                  value={activeRessource.event && activeRessource.event.place ? activeRessource.event.place : ""}
                                  onChange={this.handleRefreshEventField("place")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    id: "ressourceEventPlace",
                                    name: "ressourceEventPlace"
                                  }}
                                  error={this.state.errorEventPlace}
                                >
                                  <MenuItem value="">
                                    <em>Type de localisation :</em>
                                  </MenuItem>
                                  {["exploitation", "piemond zone intermédiaire", "alpage"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <InputLabel htmlFor="ressourceEventConfiguration">Configuration du troupeau</InputLabel>
                                <Select
                                  value={activeRessource.event && activeRessource.event.configuration ? activeRessource.event.configuration : ""}
                                  onChange={this.handleRefreshEventField("configuration")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    id: "ressourceEventConfiguration",
                                    name: "ressourceEventConfiguration"
                                  }}
                                  error={this.state.errorEventConfiguration}
                                >
                                  <MenuItem value="">
                                    <em>Configuration du troupeau :</em>
                                  </MenuItem>
                                  {["parcours gardé", "parcours libre", "parc de pâturage", "parc de nuit"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <InputLabel htmlFor="ressourceEventDisturbance">Dérangement des animaux</InputLabel>
                                <Select
                                  value={activeRessource.event && activeRessource.event.disturbance ? activeRessource.event.disturbance : ""}
                                  onChange={this.handleRefreshEventField("disturbance")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    id: "ressourceEventDisturbance",
                                    name: "ressourceEventDisturbance"
                                  }}
                                  error={this.state.errorEventDisturbance}
                                >
                                  <MenuItem value="">
                                    <em>Dérangement des animaux :</em>
                                  </MenuItem>
                                  {["oui", "non"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <TextField
                                  id="ressourceEventLost"
                                  name="ressourceEventLost"
                                  type="number"
                                  label="Nombre d'animaux égarés"
                                  fullWidth
                                  value={activeRessource.event ? activeRessource.event.lost : ""}
                                  onChange={this.handleRefreshEventField("lost")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    min: 0
                                  }}
                                  error={this.state.errorEventLost}
                                />
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <TextField
                                  id="ressourceEventInjured"
                                  name="ressourceEventInjured"
                                  type="number"
                                  label="Nombre d'animaux blessés"
                                  fullWidth
                                  value={activeRessource.event ? activeRessource.event.injured : ""}
                                  onChange={this.handleRefreshEventField("injured")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    min: 0
                                  }}
                                  error={this.state.errorEventInjured}
                                />
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <TextField
                                  id="ressourceEventKilled"
                                  name="ressourceEventKilled"
                                  type="number"
                                  label="Nombre d'animaux tués"
                                  fullWidth
                                  value={activeRessource.event ? activeRessource.event.killed : ""}
                                  onChange={this.handleRefreshEventField("killed")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    min: 0
                                  }}
                                  error={this.state.errorEventKilled}
                                />
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <Typography variant="subtitle1" align="center">
                                  <b>Indices de présence prédateur à proximité</b>
                                </Typography>
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <TextField
                                  id="ressourceEventObservation"
                                  name="ressourceEventObservation"
                                  type="text"
                                  label="Observation visuelle"
                                  fullWidth
                                  value={activeRessource.event ? activeRessource.event.observation : ""}
                                  onChange={this.handleRefreshEventField("observation")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  error={this.state.errorEventObservation}
                                />
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <TextField
                                  id="ressourceEventExcrement"
                                  name="ressourceEventExcrement"
                                  type="text"
                                  label="Excréments"
                                  fullWidth
                                  value={activeRessource.event ? activeRessource.event.excrement : ""}
                                  onChange={this.handleRefreshEventField("excrement")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  error={this.state.errorEventExcrement}
                                />
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <TextField
                                  id="ressourceEventFootprint"
                                  name="ressourceEventFootprint"
                                  type="text"
                                  label="Empreintes"
                                  fullWidth
                                  value={activeRessource.event ? activeRessource.event.footprint : ""}
                                  onChange={this.handleRefreshEventField("footprint")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  error={this.state.errorEventFootprint}
                                />
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <TextField
                                  id="ressourceEventClues"
                                  name="ressourceEventClues"
                                  type="text"
                                  label="Autres indices : témoignage, hurlements..."
                                  fullWidth
                                  value={activeRessource.event ? activeRessource.event.clues : ""}
                                  onChange={this.handleRefreshEventField("clues")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  error={this.state.errorEventClues}
                                />
                              </FormControl>
                            </Fragment>
                          )}
                          {/* STOP : Flock fields */}

                          {/* START : Clues fields */}
                          {activeRessource.event && activeRessource.event.category === "indice de présence prédateur" && (
                            <Fragment>
                              <FormControl margin="normal" fullWidth>
                                <TextField
                                  id="ressourceEventPlace"
                                  name="ressourceEventPlace"
                                  type="text"
                                  label="Lieu-dit"
                                  fullWidth
                                  value={activeRessource.event ? activeRessource.event.place : ""}
                                  onChange={this.handleRefreshEventField("place")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  error={this.state.errorEventPlace}
                                />
                              </FormControl>

                              <FormControl margin="normal" fullWidth>
                                <InputLabel htmlFor="ressourceEventClue">Type d'indice</InputLabel>
                                <Select
                                  value={activeRessource.event && activeRessource.event.clue ? activeRessource.event.clue : ""}
                                  onChange={this.handleRefreshEventField("clue")}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    id: "ressourceEventClue",
                                    name: "ressourceEventClue"
                                  }}
                                  error={this.state.errorEventClue}
                                >
                                  <MenuItem value="">
                                    <em>Type d'indice :</em>
                                  </MenuItem>
                                  {["observation", "excréments", "empreintes", "hurlements", "carcasse", "autres"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              {/* START : Observation clue fields */}
                              {activeRessource.event.clue === "observation" && (
                                <Fragment>
                                  <FormControl margin="normal" fullWidth>
                                    <TextField
                                      id="ressourceEventAnimals"
                                      name="ressourceEventAnimals"
                                      type="number"
                                      label="Nombre d'individus observés"
                                      fullWidth
                                      value={activeRessource.event ? activeRessource.event.animals : ""}
                                      onChange={this.handleRefreshEventField("animals")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        min: 0
                                      }}
                                      error={this.state.errorEventAnimals}
                                    />
                                  </FormControl>

                                  <FormControl margin="normal" fullWidth>
                                    <TextField
                                      id="ressourceEventDistance"
                                      name="ressourceEventDistance"
                                      type="number"
                                      label="Distance approximative (en mètres)"
                                      fullWidth
                                      value={activeRessource.event ? activeRessource.event.distance : ""}
                                      onChange={this.handleRefreshEventField("distance")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        min: 0
                                      }}
                                      error={this.state.errorEventDistance}
                                    />
                                  </FormControl>
                                </Fragment>
                              )}
                              {/* STOP : Observation clue fields */}

                              {/* START : Excrement clue fields */}
                              {activeRessource.event.clue === "excréments" && (
                                <Fragment>
                                  <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="ressourceEventHairBones">Présence poils/os</InputLabel>
                                    <Select
                                      value={activeRessource.event && activeRessource.event.hairbones ? activeRessource.event.hairbones : ""}
                                      onChange={this.handleRefreshEventField("hairbones")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        id: "ressourceEventHairBones",
                                        name: "ressourceEventHairBones"
                                      }}
                                      error={this.state.errorEventHairBones}
                                    >
                                      <MenuItem value="">
                                        <em>Présence poils/os :</em>
                                      </MenuItem>
                                      {["oui", "non"].map(name => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Fragment>
                              )}
                              {/* STOP : Excrement clue fields */}

                              {/* START : Footprint clue fields */}
                              {activeRessource.event.clue === "empreintes" && (
                                <Fragment>
                                  <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="ressourceEventLand">Type de terrain/sol</InputLabel>
                                    <Select
                                      value={activeRessource.event && activeRessource.event.land ? activeRessource.event.land : ""}
                                      onChange={this.handleRefreshEventField("land")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        id: "ressourceEventLand",
                                        name: "ressourceEventLand"
                                      }}
                                      error={this.state.errorEventLand}
                                    >
                                      <MenuItem value="">
                                        <em>Type de terrain/sol :</em>
                                      </MenuItem>
                                      {["boue", "sable", "neige"].map(name => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="ressourceEventFootprint">Type d'empreintes</InputLabel>
                                    <Select
                                      value={activeRessource.event && activeRessource.event.footprint ? activeRessource.event.footprint : ""}
                                      onChange={this.handleRefreshEventField("footprint")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        id: "ressourceEventFootprint",
                                        name: "ressourceEventFootprint"
                                      }}
                                      error={this.state.errorEventFootprint}
                                    >
                                      <MenuItem value="">
                                        <em>Type d'empreintes :</em>
                                      </MenuItem>
                                      {["empreinte unique", "trace voie"].map(name => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Fragment>
                              )}
                              {/* STOP : Footprint clue fields */}

                              {/* START : Howls clue fields */}
                              {activeRessource.event.clue === "hurlements" && (
                                <Fragment>
                                  <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="ressourceEventAnimals">Nombre d'individus</InputLabel>
                                    <Select
                                      value={activeRessource.event && activeRessource.event.animals ? activeRessource.event.animals : ""}
                                      onChange={this.handleRefreshEventField("animals")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        id: "ressourceEventAnimals",
                                        name: "ressourceEventAnimals"
                                      }}
                                      error={this.state.errorEventAnimals}
                                    >
                                      <MenuItem value="">
                                        <em>Nombre d'individus :</em>
                                      </MenuItem>
                                      {["un seul individu", "plusieurs individus"].map(name => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="ressourceEventAudio">Enregistrement audio</InputLabel>
                                    <Select
                                      value={activeRessource.event && activeRessource.event.audio ? activeRessource.event.audio : ""}
                                      onChange={this.handleRefreshEventField("audio")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        id: "ressourceEventAudio",
                                        name: "ressourceEventAudio"
                                      }}
                                      error={this.state.errorEventAudio}
                                    >
                                      <MenuItem value="">
                                        <em>Enregistrement audio :</em>
                                      </MenuItem>
                                      {["oui", "non"].map(name => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Fragment>
                              )}
                              {/* STOP : Howls clue fields */}

                              {/* START : Carcass clue fields */}
                              {activeRessource.event.clue === "carcasse" && (
                                <Fragment>
                                  <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="ressourceEventPrey">Espèce sauvage</InputLabel>
                                    <Select
                                      value={activeRessource.event && activeRessource.event.prey ? activeRessource.event.prey : ""}
                                      onChange={this.handleRefreshEventField("prey")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        id: "ressourceEventPrey",
                                        name: "ressourceEventPrey"
                                      }}
                                      error={this.state.errorEventPrey}
                                    >
                                      <MenuItem value="">
                                        <em>Espèce sauvage :</em>
                                      </MenuItem>
                                      {["chamois", "chevreuil", "sanglier", "cerf", "mouflon", "autre espèce"].map(name => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="ressourceEventBiting">Morsures</InputLabel>
                                    <Select
                                      value={activeRessource.event && activeRessource.event.biting ? activeRessource.event.biting : ""}
                                      onChange={this.handleRefreshEventField("biting")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        id: "ressourceEventBiting",
                                        name: "ressourceEventBiting"
                                      }}
                                      error={this.state.errorEventBiting}
                                    >
                                      <MenuItem value="">
                                        <em>Morsures :</em>
                                      </MenuItem>
                                      {["oui", "non"].map(name => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  <FormControl margin="normal" fullWidth>
                                    <InputLabel htmlFor="ressourceEventConsumption">Consommation</InputLabel>
                                    <Select
                                      value={activeRessource.event && activeRessource.event.consumption ? activeRessource.event.consumption : ""}
                                      onChange={this.handleRefreshEventField("consumption")}
                                      disabled={
                                        !(
                                          isUpdating &&
                                          (["admin"].includes(this.props.userGroup) ||
                                            (activeRessource.author === this.props.user.uid &&
                                              (["inspector"].includes(this.props.userGroup) ||
                                                (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                        )
                                      }
                                      inputProps={{
                                        id: "ressourceEventConsumption",
                                        name: "ressourceEventConsumption"
                                      }}
                                      error={this.state.errorEventConsumption}
                                    >
                                      <MenuItem value="">
                                        <em>Consommation :</em>
                                      </MenuItem>
                                      {["oui", "non"].map(name => (
                                        <MenuItem key={name} value={name}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Fragment>
                              )}
                              {/* STOP : Carcass clue fields */}
                            </Fragment>
                          )}
                          {/* STOP : Clues fields */}
                        </Fragment>
                      )}

                      {/* === STOP : NEW FIELDS === */}

                      {/* === START : OLD FIELDS === */}

                      {activeRessource.event && activeRessource.event[0] && (
                        <Fragment>
                          {/* Field 0 */}
                          <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="ressourceEventField0">Type d'évènement</InputLabel>
                            <Select
                              value={activeRessource.event && activeRessource.event[0] ? activeRessource.event[0] : ""}
                              onChange={this.handleRefreshEventField(0)}
                              disabled={
                                !(
                                  isUpdating &&
                                  (["admin"].includes(this.props.userGroup) ||
                                    (activeRessource.author === this.props.user.uid &&
                                      (["inspector"].includes(this.props.userGroup) ||
                                        (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                )
                              }
                              inputProps={{
                                id: "ressourceEventField0",
                                name: "ressourceEventField0"
                              }}
                              error={this.state.errorEventField0}
                            >
                              <MenuItem value="">
                                <em>Choisir...</em>
                              </MenuItem>
                              <MenuItem value="Bétail">Bétail</MenuItem>
                              <MenuItem value="Observation visuelle">Observation visuelle</MenuItem>
                              <MenuItem value="Images">Images</MenuItem>
                              <MenuItem value="Indice de présence">Indice de présence</MenuItem>
                              <MenuItem value="Proie sauvage">Proie sauvage</MenuItem>
                            </Select>
                          </FormControl>
                          {/* END : Field 0 */}

                          {/* Field 1 */}
                          {activeRessource.event && activeRessource.event[0] && (
                            <FormControl margin="normal" fullWidth>
                              <InputLabel htmlFor="ressourceEventField1">
                                {activeRessource.event[0] === "Bétail" && "Espèce domestique"}
                                {activeRessource.event[0] === "Observation visuelle" && "Observateur"}
                                {activeRessource.event[0] === "Images" && "Type d'images"}
                                {activeRessource.event[0] === "Indice de présence" && "Type d'indice"}
                                {activeRessource.event[0] === "Proie sauvage" && "Espèce sauvage"}
                              </InputLabel>
                              <Select
                                value={activeRessource.event && activeRessource.event[1] ? activeRessource.event[1] : ""}
                                onChange={this.handleRefreshEventField(1)}
                                disabled={
                                  !(
                                    isUpdating &&
                                    (["admin"].includes(this.props.userGroup) ||
                                      (activeRessource.author === this.props.user.uid &&
                                        (["inspector"].includes(this.props.userGroup) ||
                                          (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                  )
                                }
                                inputProps={{
                                  id: "ressourceEventField1",
                                  name: "ressourceEventField1"
                                }}
                                error={this.state.errorEventField1}
                              >
                                <MenuItem value="">
                                  <em>Choisir...</em>
                                </MenuItem>
                                {activeRessource.event[0] === "Bétail" &&
                                  ["Bovins", "Ovins", "Caprins", "Mixte"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                {activeRessource.event[0] === "Observation visuelle" &&
                                  ["Par l'observateur", "Par un tiers"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                {activeRessource.event[0] === "Images" &&
                                  ["Photo", "Vidéo"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                {activeRessource.event[0] === "Indice de présence" &&
                                  ["Empreintes", "Excréments", "Urines", "Poils", "Hurlements", "Grattages"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                                {activeRessource.event[0] === "Proie sauvage" &&
                                  ["Chamois", "Chevreuil", "Sanglier", "Cerf", "Mouflon", "Autre"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          )}
                          {/* END : Field 1 */}

                          {/* Field 2 */}
                          {activeRessource.event &&
                            ["Bétail", "Indice de présence", "Proie sauvage"].includes(activeRessource.event[0]) &&
                            activeRessource.event[1] && (
                              <FormControl margin="normal" fullWidth>
                                <InputLabel htmlFor="ressourceEventField2">
                                  {activeRessource.event[0] === "Bétail" && "Evènement bétail"}
                                  {activeRessource.event[0] === "Indice de présence" && "Récolte de l'indice"}
                                  {activeRessource.event[0] === "Proie sauvage" && "Etat du cadavre"}
                                </InputLabel>
                                <Select
                                  value={activeRessource.event && activeRessource.event[2] ? activeRessource.event[2] : ""}
                                  onChange={this.handleRefreshEventField(2)}
                                  disabled={
                                    !(
                                      isUpdating &&
                                      (["admin"].includes(this.props.userGroup) ||
                                        (activeRessource.author === this.props.user.uid &&
                                          (["inspector"].includes(this.props.userGroup) ||
                                            (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                    )
                                  }
                                  inputProps={{
                                    id: "ressourceEventField2",
                                    name: "ressourceEventField2"
                                  }}
                                  error={this.state.errorEventField2}
                                >
                                  <MenuItem value="">
                                    <em>Choisir...</em>
                                  </MenuItem>
                                  {activeRessource.event[0] === "Bétail" &&
                                    ["Agité", "Dispersé", "Affolement", "Cadavre"].map(name => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  {activeRessource.event[0] === "Indice de présence" &&
                                    ["Récolté", "Non récolté"].map(name => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  {activeRessource.event[0] === "Proie sauvage" &&
                                    ["Intact", "Consommé", "Morsures-Lutte"].map(name => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            )}
                          {/* END : Field 2 */}

                          {/* Field 3 */}
                          {activeRessource.event && ["Cadavre"].includes(activeRessource.event[2]) && activeRessource.event[2] && (
                            <FormControl margin="normal" fullWidth>
                              <InputLabel htmlFor="ressourceEventField3">{activeRessource.event[0] === "Bétail" && "Etat du cadavre"}</InputLabel>
                              <Select
                                value={activeRessource.event && activeRessource.event[3] ? activeRessource.event[3] : ""}
                                onChange={this.handleRefreshEventField(3)}
                                disabled={
                                  !(
                                    isUpdating &&
                                    (["admin"].includes(this.props.userGroup) ||
                                      (activeRessource.author === this.props.user.uid &&
                                        (["inspector"].includes(this.props.userGroup) ||
                                          (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                  )
                                }
                                inputProps={{
                                  id: "ressourceEventField3",
                                  name: "ressourceEventField3"
                                }}
                                error={this.state.errorEventField4}
                              >
                                <MenuItem value="">
                                  <em>Choisir...</em>
                                </MenuItem>
                                {activeRessource.event[0] === "Bétail" &&
                                  ["Intact", "Consommé", "Morsures-Lutte"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          )}
                          {/* END : Field 3 */}

                          {/* Field 4 */}
                          {activeRessource.event && activeRessource.event[0] && ["Bétail"].includes(activeRessource.event[0]) && (
                            <FormControl margin="normal" fullWidth>
                              <InputLabel htmlFor="ressourceEventField4">{activeRessource.event[0] === "Bétail" && "Configuration bétail"}</InputLabel>
                              <Select
                                value={activeRessource.event && activeRessource.event[4] ? activeRessource.event[4] : ""}
                                onChange={this.handleRefreshEventField(4)}
                                disabled={
                                  !(
                                    isUpdating &&
                                    (["admin"].includes(this.props.userGroup) ||
                                      (activeRessource.author === this.props.user.uid &&
                                        (["inspector"].includes(this.props.userGroup) ||
                                          (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                                  )
                                }
                                inputProps={{
                                  id: "ressourceEventField4",
                                  name: "ressourceEventField4"
                                }}
                                error={this.state.errorEventField4}
                              >
                                <MenuItem value="">
                                  <em>Choisir...</em>
                                </MenuItem>
                                {activeRessource.event[0] === "Bétail" &&
                                  ["Troupeau", "Lot"].map(name => (
                                    <MenuItem key={name} value={name}>
                                      {name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          )}
                          {/* END : Field 4 */}
                        </Fragment>
                      )}

                      {/* === STOP : OLD FIELDS === */}

                      <FormControl margin="normal" fullWidth>
                        <TextField
                          id="ressourceComment"
                          name="ressourceComment"
                          type="text"
                          label="Commentaire"
                          fullWidth
                          value={activeRessource.comment ? activeRessource.comment : ""}
                          onChange={e => this.refreshComment(e)}
                          disabled={
                            !(
                              isUpdating &&
                              (["admin"].includes(this.props.userGroup) ||
                                (activeRessource.author === this.props.user.uid &&
                                  (["inspector"].includes(this.props.userGroup) ||
                                    (!["admin", "inspector"].includes(this.props.userGroup) && activeRessource.status === "pending"))))
                            )
                          }
                          error={this.state.errorComment}
                        />
                      </FormControl>

                      <FormControl margin="normal" fullWidth>
                        <InputLabel shrink>Image</InputLabel>
                        <div style={{ marginTop: "25px" }}>
                          <a href={this.props.activeRessource.image} target="_blank" rel="noopener noreferrer">
                            <img src={this.props.activeRessource.image} alt="" style={{ width: "100%" }} />
                          </a>

                          {isUpdating &&
                            activeRessource.status === "pending" &&
                            ((["observer", "inspector"].includes(this.props.userGroup) && activeRessource.author === this.props.user.uid) ||
                              this.props.userGroup === "admin") && (
                              <Fragment>
                                <input id="ressourceImage" type="file" accept=".jpg, .jpeg" style={{ display: "none" }} onChange={e => this.refreshImage(e)} />
                                {!this.props.activeRessource.image && (
                                  <Fragment>
                                    <label htmlFor="ressourceImage">
                                      <Tooltip title="Fichiers autorisés : JPG, JPEG" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
                                        <Button color="secondary" variant="outlined" component="span" fullWidth>
                                          <AddImageIcon className={classes.buttonIcon} />
                                          Ajouter une photo
                                        </Button>
                                      </Tooltip>
                                    </label>
                                  </Fragment>
                                )}

                                {this.props.activeRessource.image && (
                                  <Button
                                    size="small"
                                    className={classes.deleteButton}
                                    fullWidth
                                    onClick={() => {
                                      // Save temp blob to Redux
                                      const nextRessource = Object.assign({}, this.props.activeRessource)
                                      nextRessource.image = null
                                      this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: nextRessource })
                                    }}
                                  >
                                    <RemoveIcon className={classes.buttonIcon} />
                                    Effacer l'image
                                  </Button>
                                )}
                              </Fragment>
                            )}
                        </div>
                      </FormControl>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* END : Ressource infos drawer */}

                {/* Status infos drawer */}
                <ExpansionPanel expanded={this.state.expanded === "status"} onChange={this.handleChangePanel("status")}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      <strong> Responsabilité prédateur : </strong>
                      {translation.ressource.status[activeRessource.status][myLanguage]}
                    </Typography>
                  </ExpansionPanelSummary>
                  <Divider />
                  <ExpansionPanelDetails>
                    <div className={classes.panelDetails}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="ressourceStatus">Responsabilité prédateur (avis modérateur)</InputLabel>
                        <Select
                          value={activeRessource.status ? activeRessource.status : ""}
                          onChange={e => this.refreshStatus(e)}
                          disabled={!(isUpdating && ["inspector", "admin"].includes(this.props.userGroup))}
                          inputProps={{
                            id: "ressourceStatus",
                            name: "ressourceStatus"
                          }}
                          error={this.state.errorStatus}
                        >
                          {Object.keys(translation.ressource.status).map(name => (
                            <MenuItem key={name} value={name}>
                              <Grid container direction="row" justify="flex-start" alignItems="center">
                                <ListItemIcon style={{ marginRight: "8px", color: getRessourceStatusColor(name) }}>{getRessourceStatusIcon(name)}</ListItemIcon>
                                <Typography style={{ color: getRessourceStatusColor(name) }} noWrap>
                                  {translation.ressource.status[name][myLanguage]}
                                </Typography>
                              </Grid>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl margin="normal" fullWidth>
                        <InputLabel htmlFor="ressourceSpecies">Espèce prédatrice</InputLabel>
                        <Select
                          value={activeRessource.species ? activeRessource.species : ""}
                          onChange={e => this.refreshSpecies(e)}
                          disabled={!(isUpdating && ["inspector", "admin"].includes(this.props.userGroup))}
                          inputProps={{
                            id: "ressourceSpecies",
                            name: "ressourceSpecies"
                          }}
                          error={this.state.errorSpecies}
                        >
                          <MenuItem value="">
                            <em>Choisir...</em>
                          </MenuItem>
                          {Object.keys(translation.ressource.species).map(name => (
                            <MenuItem key={name} value={name}>
                              {translation.ressource.species[name][myLanguage]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {activeRessource.status !== "pending" && (
                        <Fragment>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel htmlFor="ressourceBasis">Fondements (fiabilité)</InputLabel>
                            <Select
                              multiple
                              value={activeRessource.basis ? activeRessource.basis : []}
                              onChange={this.handleRefreshBasis()}
                              disabled={!(isUpdating && ["inspector", "admin"].includes(this.props.userGroup))}
                              input={<Input id="ressourceBasis" />}
                              error={this.state.errorBasis}
                              renderValue={selected => selected.join(", ")}
                            >
                              {[
                                "Avis d'expert",
                                "Avis ONCFS",
                                "Analyse génétique",
                                "Sur photo ou vidéo",
                                "Analyse carcasse",
                                "Indices de présence",
                                "Manque d'éléments"
                              ].map(name => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox checked={activeRessource.basis ? activeRessource.basis.includes(name) : false} />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Fragment>
                      )}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* END : Status infos drawer */}

                {/* ViewMode action buttons drawer */}
                {!isUpdating && (
                  <Paper style={{ margin: "16px 0", padding: "8px" }}>
                    <Grid container direction="row" justify="space-around" alignItems="center" style={{ paddingRight: 0 }}>
                      {// IF ADMINISTRATOR
                      (this.props.userGroup === "admin" ||
                        // IF MANAGER
                        // ...
                        // IF INSPECTOR
                        (this.props.userGroup === "inspector" &&
                          (activeRessource.author === this.props.user.uid ||
                            (users[activeRessource.author] && ["member", "observer"].includes(users[activeRessource.author].group)))) ||
                        // IF OBSERVER
                        (this.props.userGroup === "observer" && activeRessource.author === this.props.user.uid && activeRessource.status === "pending")) && (
                        <Button color="secondary" size="small" onClick={this.enableUpdateMode}>
                          <EditIcon className={classes.buttonIcon} />
                          Modifier
                        </Button>
                      )}
                      <Button color="default" size="small" onClick={this.closeContainer} disabled={!this.props.ressourceViewMode}>
                        <CloseIcon className={classes.buttonIcon} />
                        Fermer
                      </Button>
                    </Grid>
                  </Paper>
                )}
                {/* END : ViewMode action buttons drawer */}

                {/* Editing action buttons bar */}
                {isUpdating && <EditButtonsBar />}
                {/* END : Editing action buttons bar  */}

                {/* Deleting action buttons bar */}
                {isUpdating && !this.props.ressourceNewMode && <DeleteButtonsBar />}
                {/* END : Deleting action buttons bar */}

                {this.props.userGroup === "admin" && (
                  <FormControl margin="normal" fullWidth>
                    <Typography color="inherit">RESSOURCE TYPE (temp) : {this.props.activeRessource.category}</Typography>
                    <Typography color="inherit">RESSOURCE SPECIES (temp) : {this.props.activeRessource.species}</Typography>
                    <Typography color="inherit">RESSOURCE REF : {this.props.activeRessourceId}</Typography>
                    <Typography color="inherit">ALERT REF : {this.props.activeAlert.id}</Typography>
                  </FormControl>
                )}
              </div>
            </Grow>
          </Fragment>
        )}

        <Dialog fullScreen={fullScreen} open={this.state.openDeleteFeatureDialog} onClose={this.handleCloseDialog}>
          <DialogTitle>{"Supprimer définitivement ?"}</DialogTitle>
          <DialogContent>
            <Typography>Etes-vous certain de vouloir supprimer cette donnée ?</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleCloseDialog}>
              Annuler
            </Button>
            <Button onClick={this.handleDeleteFeature} className={classes.deleteButton} autoFocus>
              Effacer
            </Button>
          </DialogActions>
        </Dialog>

        {alertContacts && (
          <Dialog fullScreen={fullScreen} open={this.state.openSendAlertDialog} onClose={this.handleCloseDialog} scroll="paper">
            <DialogTitle>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <NotificationIcon color="action" style={{ marginRight: "16px" }} />

                <Grid item>{"Envoyer et signaler l'évènement ?"}</Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Typography>
                <strong>
                  La notification sera envoyée à {contactsNumber} personne{contactsNumber > 1 && "s"} :
                </strong>
              </Typography>
              <List>
                {Object.keys(alertContacts).map(key => {
                  const contact = alertContacts[key]
                  const primary = contact.firstname + " " + contact.lastname
                  const secondary = contact.distance + " Km ∙∙∙ " + contact.title
                  return (
                    <ListItem key={key}>
                      <ContactIcon fontSize="large" color="action" />
                      <ListItemText primary={primary} secondary={secondary} />
                    </ListItem>
                  )
                })}
              </List>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button color="primary" onClick={this.handleCloseDialog}>
                Annuler
              </Button>
              <Button color="secondary" onClick={this.handleSendAlert} autoFocus>
                Envoyer <SendIcon style={{ marginLeft: "16px" }} />
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    userFirstName: state.userReducer.userFirstName,
    userLastName: state.userReducer.userLastName,
    userEmail: state.userReducer.userEmail,
    userPhone: state.userReducer.userPhone,
    userGroup: state.userReducer.userGroup,

    users: state.dataReducer.users,
    ressources: state.dataReducer.ressources,
    alerts: state.dataReducer.alerts,
    joins: state.dataReducer.joins,

    activeRessource: state.mapReducer.activeRessource,
    activeRessourceId: state.mapReducer.activeRessourceId,
    contactViewMode: state.mapReducer.contactViewMode,
    ressourceViewMode: state.mapReducer.ressourceViewMode,
    ressourceNewMode: state.mapReducer.ressourceNewMode,
    activeAlert: state.mapReducer.activeAlert,
    nextAlert: state.mapReducer.nextAlert,
    alertContacts: state.mapReducer.alertContacts,
    alertPolygonDrawMode: state.mapReducer.alertPolygonDrawMode
  }
}

export default withStyles(styles)(connect(mapStateToProps)(withMobileDialog()(withSnackbar(RessourceContainer))))
