import React, { Component } from "react"
import classNames from "classnames"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Button } from "@material-ui/core"
import { FormControl } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Card } from "@material-ui/core"
import { CardActions } from "@material-ui/core"
import { CardContent } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import { Hidden } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
import { Dialog } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { withMobileDialog } from "@material-ui/core"
import { Fade } from "@material-ui/core"
import { Chip } from "@material-ui/core"
import { Avatar } from "@material-ui/core"
import { InputLabel } from "@material-ui/core"

import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import AccountIcon from "@material-ui/icons/Person"
import IdentityIcon from "@material-ui/icons/PersonOutline"
import EmailIcon from "@material-ui/icons/AlternateEmail"
import PasswordIcon from "@material-ui/icons/LockOutlined"
import EditIcon from "@material-ui/icons/Edit"
import CancelIcon from "@material-ui/icons/Cancel"
import SaveIcon from "@material-ui/icons/Save"
import SignOutIcon from "@material-ui/icons/PowerSettingsNew"
import PhoneIcon from "@material-ui/icons/LocalPhoneOutlined"
import DescriptionIcon from "@material-ui/icons/MessageOutlined"
import GroupIcon from "@material-ui/icons/GroupOutlined"
import AreasIcon from "@material-ui/icons/MapOutlined"

import firebase from "../config/firebase"
import areasName from "../data/areasName.json"

import { PhoneFormatter, phoneFormatRegex, phoneHelperText } from "../components/PhoneNumber"

import { withSnackbar } from "notistack"
import { message } from "../functions/appInterface"
import { capitalize } from "../functions/appStrings"

import { translation } from "../config/languages.json"

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[100],
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  button: {
    margin: theme.spacing.unit
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20
  },
  dangerButton: {
    color: theme.palette.error.main
  },
  footer: {
    margin: theme.spacing.unit * 3
  },
  capitalize: {
    textTransform: "capitalize"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  lowercase: {
    textTransform: "lowercase"
  },
  hiddenXs: {
    [theme.breakpoints.up("xs")]: {
      visibility: "hidden !important"
    }
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2
  }
})

class Account extends Component {
  state = {
    isUpdating: false,
    showPassword: false,
    openDialog: false
  }

  // Define alert message context
  message = message
  // END : Define alert message context

  componentDidMount() {
    // Re-enter user password
    if (!this.props.prevUserPassword) {
      this.setState({ openDialog: true })
    }
  }

  handleCloseDialog = () => {
    const { user, prevUserEmail, nextUserPassword } = this.props

    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_USER_PASSWORD", value: nextUserPassword })

    // Re-authenticate user
    const credential = firebase.auth.EmailAuthProvider.credential(prevUserEmail, nextUserPassword)
    user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        this.setState({ openDialog: false })
        this.message("success", "Mot de passe correct")
      })
      .catch(error => {
        console.error("Error 48f12c9b-fb9e-4bc3-81f9-26ca67281831", error)
        if (error.code === "auth/wrong-password") {
          this.message("error", "Mot de passe incorrect pour " + prevUserEmail)
          this.setState({ errorPassword: true })
        } else {
          this.message("error", "Erreur : " + error.message)
        }
      })
  }

  handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Save to Redux global state
        this.props.dispatch({ type: "UPDATE_USER", value: null })
        this.props.dispatch({ type: "UPDATE_USER_EMAIL", value: null })
        this.props.dispatch({ type: "UPDATE_USER_PASSWORD", value: null })
        this.props.dispatch({ type: "UPDATE_USER_GROUP", value: null })
        this.props.dispatch({ type: "UPDATE_USER_FIRST_NAME", value: null })
        this.props.dispatch({ type: "UPDATE_USER_LAST_NAME", value: null })
        this.props.dispatch({ type: "UPDATE_USER_PHONE", value: null })
        this.props.dispatch({ type: "UPDATE_USER_AREAS", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_EMAIL", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_PASSWORD", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_FIRST_NAME", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_LAST_NAME", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_PHONE", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_AREAS", value: null })

        this.props.dispatch({ type: "TOGGLE_APP_LOADING", value: true })
        this.message("warning", "Vous êtes déconnecté...")
      })
      .catch(error => {
        console.error("Error 0f2feded-4609-42f0-9944-52d554243b68", error)
        this.message("error", "Erreur : " + error.message)
      })
  }

  componentWillUnmount() {
    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_NEXT_USER_FIRST_NAME", value: this.props.prevUserFirstName })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_LAST_NAME", value: this.props.prevUserLastName })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_EMAIL", value: this.props.prevUserEmail })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_PASSWORD", value: this.props.prevUserPassword })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_PHONE", value: this.props.prevUserPhone })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_DESCRIPTION", value: this.props.prevUserDescription })
  }

  toggleUpdateMode = () => {
    if (this.state.isUpdating) {
      this.message("warning", "Opération annulée...")
    }
    this.setState(state => ({ isUpdating: !state.isUpdating }))
    this.setState({ errorFirstName: false, errorLastName: false, errorEmail: false, errorPassword: false, errorPhone: false, errorDescription: false })

    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_NEXT_USER_FIRST_NAME", value: this.props.prevUserFirstName })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_LAST_NAME", value: this.props.prevUserLastName })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_EMAIL", value: this.props.prevUserEmail })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_PASSWORD", value: this.props.prevUserPassword })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_PHONE", value: this.props.prevUserPhone })
    this.props.dispatch({ type: "UPDATE_NEXT_USER_DESCRIPTION", value: this.props.prevUserDescription })
  }

  saveUpdate = () => {
    const {
      user,
      nextUserFirstName,
      nextUserLastName,
      nextUserEmail,
      nextUserPassword,
      nextUserPhone,
      nextUserDescription,
      prevUserFirstName,
      prevUserLastName,
      prevUserEmail,
      prevUserPassword,
      prevUserPhone,
      prevUserDescription
    } = this.props

    if (!phoneFormatRegex.test(nextUserPhone)) {
      this.message("warning", "Numéro de mobile non valide")
      this.setState({ errorPhone: true })
      this.setState({ isUpdating: true })
    } else {
      if (nextUserFirstName && nextUserLastName && nextUserEmail && nextUserPassword && nextUserPhone && nextUserDescription) {
        this.setState(state => ({ isUpdating: !state.isUpdating }))

        // Need no update
        if (
          nextUserFirstName === prevUserFirstName &&
          nextUserLastName === prevUserLastName &&
          nextUserEmail === prevUserEmail &&
          nextUserPassword === prevUserPassword &&
          nextUserPhone === prevUserPhone &&
          nextUserDescription === prevUserDescription
        ) {
          this.message("info", "Aucune modification n'a été effectuée...")
          this.setState({ isUpdating: true })
        }

        // Update user firstname
        if (nextUserFirstName !== prevUserFirstName) {
          firebase
            .database()
            .ref("/users/" + user.uid)
            .update({ firstname: nextUserFirstName }, error => {
              if (error) {
                // Write failed...
                console.error("Error 202a4348-119b-45ce-a6ef-b681651d52b6", error)
                this.message("error", "Erreur : " + error.message)
              } else {
                // Write success...
                this.message("success", "Prénom mis à jour : " + prevUserFirstName + " > " + nextUserFirstName)
                // Save to Redux global state
                this.props.dispatch({ type: "UPDATE_USER_FIRST_NAME", value: nextUserFirstName })
              }
            })
        }

        // Update user lastname
        if (nextUserLastName !== prevUserLastName) {
          firebase
            .database()
            .ref("/users/" + user.uid)
            .update({ lastname: nextUserLastName }, error => {
              if (error) {
                // Write failed...
                console.error("Error c48e9d2b-0b3c-401d-824d-ef1fab59d45f", error)
                this.message("error", "Erreur : " + error.message)
              } else {
                // Write success...
                this.message("success", "Nom mis à jour : " + prevUserLastName + " > " + nextUserLastName)
                // Save to Redux global state
                this.props.dispatch({ type: "UPDATE_USER_LAST_NAME", value: nextUserLastName })
              }
            })
        }

        // Update user email
        if (nextUserEmail !== prevUserEmail) {
          user
            .updateEmail(nextUserEmail)
            .then(() => {
              user
                .sendEmailVerification()
                .then(() => {
                  firebase
                    .database()
                    .ref("/users/" + user.uid)
                    .update({ email: nextUserEmail }, error => {
                      if (error) {
                        // Write failed...
                        console.error("Error a97def9d-e7e1-4bde-88d9-013ca9ac9d00", error)
                        this.message("error", "Erreur : " + error.message)
                      } else {
                        // Write success...
                        this.message(
                          "success",

                          "Email mis à jour... Cliquez sur le lien envoyé à " + nextUserEmail
                        )
                        // Save to Redux global state
                        this.props.dispatch({ type: "UPDATE_USER_EMAIL", value: nextUserEmail })
                      }
                    })
                })
                .catch(error => {
                  console.error("Error cc76f09d-02a7-428c-9b86-907f6a39023d", error)
                  this.message("error", "Erreur : " + error.message)
                })
            })
            .catch(error => {
              if (error.code === "auth/email-already-in-use") {
                this.message("error", "Un compte est déjà associé à " + nextUserEmail)
                this.setState({ errorEmail: true })
              } else if (error.code === "auth/invalid-email") {
                this.message("warning", "L'adresse email n'est pas valide : " + nextUserEmail)
                this.setState({ errorEmail: true })
              } else {
                console.error("Error fb31d708-9ffb-43cc-9fb0-0ce2e1b143ac", error)
                this.message("error", "Erreur : " + error.message)
              }
              this.setState({ isUpdating: true })
            })
        }

        // Update user password
        if (nextUserPassword !== prevUserPassword) {
          user
            .updatePassword(nextUserPassword)
            .then(() => {
              this.message("success", "Votre mot de passe a été mis à jour")
              // Save to Redux global state
              this.props.dispatch({ type: "UPDATE_USER_PASSWORD", value: nextUserPassword })
            })
            .catch(error => {
              if (error.code === "auth/weak-password") {
                this.message("warning", "Le mot de passe doit comporter au moins 6 caractères.")
                this.setState({ errorPassword: true })
              } else {
                console.error("Error 83a7fc52-443d-44da-aae0-6761f6ab895b", error)
                this.message("error", "Erreur : " + error.message)
              }
              this.setState({ isUpdating: true })
            })
        }

        // Update user phone
        if (nextUserPhone !== prevUserPhone) {
          firebase
            .database()
            .ref("/users/" + user.uid)
            .update({ phone: nextUserPhone }, error => {
              if (error) {
                // Write failed...
                console.error("Error 1f3b732c-e11a-45e1-810f-4bef8012696f", error)
                this.message("error", "Erreur : " + error.message)
              } else {
                // Write success...
                this.message("success", "Téléphone mis à jour : " + prevUserPhone + " > " + nextUserPhone)
                // Save to Redux global state
                this.props.dispatch({ type: "UPDATE_USER_PHONE", value: nextUserPhone })
              }
            })
        }

        // Update user description
        if (nextUserDescription !== prevUserDescription) {
          firebase
            .database()
            .ref("/users/" + user.uid)
            .update({ description: nextUserDescription }, error => {
              if (error) {
                // Write failed...
                console.error("Error ce55fb72-4c0e-462d-abb7-d2d702fa1574", error)
                this.message("error", "Erreur : " + error.message)
              } else {
                // Write success...
                this.message("success", "Description mise à jour : " + prevUserDescription + " > " + nextUserDescription)
                // Save to Redux global state
                this.props.dispatch({ type: "UPDATE_USER_DESCRIPTION", value: nextUserDescription })
              }
            })
        }
      } else {
        this.message("warning", "L'un des champs est vide...")
        if (!nextUserFirstName) {
          this.setState({ errorFirstName: true })
        }
        if (!nextUserLastName) {
          this.setState({ errorLastName: true })
        }
        if (!nextUserEmail) {
          this.setState({ errorEmail: true })
        }
        if (!nextUserPassword) {
          this.setState({ errorPassword: true })
        }
        if (!nextUserPhone) {
          this.setState({ errorPhone: true })
        }
        if (!nextUserDescription) {
          this.setState({ errorDescription: true })
        }
      }
    }
  }

  refreshFirstName(e) {
    this.props.dispatch({ type: "UPDATE_NEXT_USER_FIRST_NAME", value: capitalize(e.target.value) })
    this.setState({ errorFirstName: false })
  }
  refreshLastName(e) {
    this.props.dispatch({ type: "UPDATE_NEXT_USER_LAST_NAME", value: e.target.value.toUpperCase() })
    this.setState({ errorLastName: false })
  }
  refreshEmail(e) {
    this.props.dispatch({ type: "UPDATE_NEXT_USER_EMAIL", value: e.target.value.toLowerCase() })
    this.setState({ errorEmail: false })
  }
  refreshPassword(e) {
    this.props.dispatch({ type: "UPDATE_NEXT_USER_PASSWORD", value: e.target.value })
    this.setState({ errorPassword: false })
  }
  refreshPhone(e) {
    this.props.dispatch({ type: "UPDATE_NEXT_USER_PHONE", value: e.target.value })
    // Respect phone format
    if (!phoneFormatRegex.test(e.target.value)) {
      this.setState({ errorPhone: true })
    } else {
      this.setState({ errorPhone: false })
    }
  }
  refreshDescription(e) {
    this.props.dispatch({ type: "UPDATE_NEXT_USER_DESCRIPTION", value: e.target.value })
    this.setState({ errorPassword: false })
  }

  handleShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  render() {
    const { classes } = this.props
    const { isUpdating, showPassword } = this.state
    const { nextUserFirstName, nextUserLastName, nextUserEmail, nextUserPassword, nextUserPhone, nextUserDescription, userGroup, userAreas } = this.props
    const { fullScreen } = this.props

    return (
      <Fade in={true}>
        <div className={classes.root}>
          <Grid container direction="row" justify="flex-start" alignItems="center" style={{ margin: "8px", marginBottom: "24px" }}>
            <AccountIcon fontSize="large" style={{ marginRight: "16px" }} color="action" />
            <Grid item xs zeroMinWidth>
              <Typography variant="h5" noWrap>
                Mon compte utilisateur
              </Typography>
            </Grid>
          </Grid>

          <Card style={{ borderRadius: "0" }}>
            <CardContent>
              <FormControl margin="normal" fullWidth>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={6}>
                    <Grid container alignItems="flex-end" spacing={8}>
                      <Grid item>
                        <IdentityIcon color="action" />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          id="firstName"
                          name="firstName"
                          type="text"
                          label="Prénom"
                          fullWidth
                          value={nextUserFirstName ? nextUserFirstName : ""}
                          onChange={e => this.refreshFirstName(e)}
                          inputProps={{
                            className: classes.capitalize
                          }}
                          disabled={!isUpdating}
                          error={this.state.errorFirstName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container alignItems="flex-end" spacing={8}>
                      <Grid item>
                        <Hidden smUp>
                          <AccountIcon color="action" className={classes.hiddenXs} />
                        </Hidden>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          id="lastName"
                          name="lastName"
                          type="text"
                          label="NOM"
                          fullWidth
                          value={nextUserLastName ? nextUserLastName : ""}
                          onChange={e => this.refreshLastName(e)}
                          inputProps={{
                            className: classes.uppercase
                          }}
                          disabled={!isUpdating}
                          error={this.state.errorLastName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <Grid container alignItems="flex-end" spacing={8}>
                  <Grid item>
                    <EmailIcon color="action" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="email"
                      name="email"
                      type="email"
                      label="Email"
                      fullWidth
                      value={nextUserEmail ? nextUserEmail : ""}
                      onChange={e => this.refreshEmail(e)}
                      inputProps={{
                        className: classes.lowercase
                      }}
                      disabled={!isUpdating}
                      error={this.state.errorEmail}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <Grid container alignItems="flex-end" spacing={8}>
                  <Grid item>
                    <PasswordIcon color="action" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      label="Mot de passe"
                      fullWidth
                      value={nextUserPassword ? nextUserPassword : ""}
                      onChange={e => this.refreshPassword(e)}
                      disabled={!isUpdating}
                      error={this.state.errorPassword}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="Afficher le mot de passe" placement="left" TransitionComponent={Zoom}>
                      <IconButton aria-label="Afficher le mot de passe" onClick={this.handleShowPassword} style={{ marginBottom: "-8px" }}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <Grid container alignItems="flex-end" spacing={8}>
                  <Grid item>
                    <PhoneIcon color="action" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="phone"
                      name="phone"
                      type="text"
                      label="Téléphone mobile"
                      fullWidth
                      value={nextUserPhone ? nextUserPhone : ""}
                      onChange={e => this.refreshPhone(e)}
                      disabled={!isUpdating}
                      error={this.state.errorPhone}
                      helperText={this.state.errorPhone ? phoneHelperText : ""}
                      InputProps={{
                        inputComponent: PhoneFormatter
                      }}
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <Grid container alignItems="flex-end" spacing={8}>
                  <Grid item>
                    <DescriptionIcon color="action" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="description"
                      name="description"
                      type="text"
                      label="Description"
                      fullWidth
                      value={nextUserDescription ? nextUserDescription : ""}
                      onChange={e => this.refreshDescription(e)}
                      disabled={!isUpdating}
                      error={this.state.errorDescription}
                      placeholder="Pour accélérer votre inscription, précisez : téléphone fixe, adresse d'exploitation, numéro d'exploitation, nom de l'alpage, type de bétail..."
                      multiline
                      rows="4"
                    />
                  </Grid>
                </Grid>
                <Typography variant="body2">
                  Pour accélérer votre inscription, précisez : téléphone fixe, adresse d'exploitation, numéro d'exploitation, nom de l'alpage, type de bétail...
                </Typography>
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <Grid container alignItems="flex-end" spacing={8}>
                  <Grid item>
                    <GroupIcon color="action" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      id="group"
                      name="group"
                      type="text"
                      label="Groupe"
                      fullWidth
                      value={userGroup ? translation.users.groups[userGroup]["fr"] : ""}
                      disabled
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <FormControl margin="normal" fullWidth>
                <Grid container alignItems="flex-end" spacing={8}>
                  <Grid item>
                    <AreasIcon color="action" />
                  </Grid>
                  <Grid item xs style={{ position: "relative" }}>
                    <InputLabel shrink>Territoires</InputLabel>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "16px"
                      }}
                    >
                      {userAreas &&
                        userAreas.map(
                          shortcode =>
                            shortcode && (
                              <Chip
                                key={shortcode}
                                variant="outlined"
                                avatar={
                                  <Avatar>
                                    <small style={{ textAlign: "center" }}>{shortcode.substring(0, 2)}</small>
                                  </Avatar>
                                }
                                label={areasName[shortcode]}
                                style={{ margin: "4px" }}
                              />
                            )
                        )}
                    </div>
                  </Grid>
                </Grid>
              </FormControl>
            </CardContent>
            <CardActions>
              {!isUpdating && (
                <Grid container justify="space-between">
                  <Button color="secondary" size="small" className={classes.button} onClick={this.toggleUpdateMode}>
                    <EditIcon className={classes.buttonIcon} />
                    Modifier
                  </Button>
                  <Button size="small" className={classNames(classes.button, classes.dangerButton)} onClick={this.handleSignOut}>
                    <SignOutIcon className={classes.buttonIcon} />
                    Se déconnecter
                  </Button>
                </Grid>
              )}
              {isUpdating && (
                <div>
                  <Button color="primary" size="small" className={classes.button} onClick={this.toggleUpdateMode}>
                    <CancelIcon className={classes.buttonIcon} />
                    Annuler
                  </Button>
                  <Button color="secondary" size="small" className={classes.button} onClick={this.saveUpdate}>
                    <SaveIcon className={classes.buttonIcon} />
                    Mettre à jour
                  </Button>
                </div>
              )}
            </CardActions>
          </Card>

          <Dialog
            fullScreen={fullScreen}
            disableBackdropClick
            disableEscapeKeyDown
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Reconnexion demandée"}</DialogTitle>
            <DialogContent>
              <Grid container alignItems="flex-end" spacing={8}>
                <Grid item>
                  <PasswordIcon color="action" />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="reenterPassword"
                    name="reenterPassword"
                    type={showPassword ? "text" : "password"}
                    label="Mot de passe actuel"
                    fullWidth
                    value={nextUserPassword}
                    onChange={e => this.refreshPassword(e)}
                    error={this.state.errorPassword}
                    onKeyPress={e => {
                      e.key === "Enter" && this.handleCloseDialog()
                    }}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="Afficher le mot de passe" placement="left" TransitionComponent={Zoom}>
                    <IconButton aria-label="Afficher le mot de passe" onClick={this.handleShowPassword} style={{ marginBottom: "-8px" }}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {/* <Button color="primary" component={Link} to="/"> */}
              <Button color="primary" onClick={() => this.props.history.goBack()}>
                Annuler
              </Button>
              <Button onClick={this.handleCloseDialog} color="secondary" autoFocus>
                Valider
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Fade>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    userGroup: state.userReducer.userGroup,
    userAreas: state.userReducer.userAreas,

    prevUserEmail: state.userReducer.userEmail,
    prevUserPassword: state.userReducer.userPassword,
    prevUserFirstName: state.userReducer.userFirstName,
    prevUserLastName: state.userReducer.userLastName,
    prevUserPhone: state.userReducer.userPhone,
    prevUserDescription: state.userReducer.userDescription,
    nextUserEmail: state.userReducer.nextUserEmail,
    nextUserPassword: state.userReducer.nextUserPassword,
    nextUserFirstName: state.userReducer.nextUserFirstName,
    nextUserLastName: state.userReducer.nextUserLastName,
    nextUserPhone: state.userReducer.nextUserPhone,
    nextUserDescription: state.userReducer.nextUserDescription
  }
}

export default withStyles(styles)(connect(mapStateToProps)(withRouter(withMobileDialog()(withSnackbar(Account)))))
