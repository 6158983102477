import * as turf from "@turf/turf"

import { colorGreen, colorRed, colorLight, colorDark, colorOrange, colorBrown } from "../config/colors.json"

// Function Display/Hide features layers (contact, ressource)
export function displayContacts(map, contacts) {
  const loadContactsFeatureCollection = () => {
    const featureCollection = turf.featureCollection([])

    Object.keys(contacts).map(key => {
      const geometry = {
        type: "Point",
        coordinates: [contacts[key].lng, contacts[key].lat]
      }
      const properties = {
        id: key
      }
      const feature = turf.feature(geometry, properties)
      return featureCollection.features.push(feature)
    })

    return featureCollection
  }

  if (map.getLayer("contactsFeatures")) {
    map.removeLayer("contactsFeatures")
    map.removeLayer("contactsFeaturesHover")
    map.removeLayer("contactsFeaturesActive")
    map.removeLayer("contactsClusters")
    map.removeLayer("contactsClustersHover")
    map.removeLayer("contactsClustersCount")
    map.removeSource("contactsFeaturesSource")
  }

  map.addSource("contactsFeaturesSource", {
    type: "geojson",
    data: loadContactsFeatureCollection(),
    cluster: true,
    clusterRadius: 50
  })

  map.addLayer({
    id: "contactsFeatures",
    type: "circle",
    source: "contactsFeaturesSource",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-radius": 10,
      "circle-color": colorGreen,
      "circle-stroke-width": 2,
      "circle-stroke-color": colorLight
    }
  })

  map.addLayer(
    {
      id: "contactsFeaturesHover",
      type: "circle",
      source: "contactsFeaturesSource",
      layout: {},
      paint: {
        "circle-radius": 12,
        "circle-color": colorGreen,
        "circle-stroke-width": 2,
        "circle-stroke-color": colorDark
      },
      filter: ["==", "id", ""]
    },
    "contactsFeatures"
  )

  map.addLayer(
    {
      id: "contactsFeaturesActive",
      type: "circle",
      source: "contactsFeaturesSource",
      layout: {},
      paint: {
        "circle-radius": 12,
        "circle-color": colorGreen,
        "circle-stroke-width": 2,
        "circle-stroke-color": colorDark
      },
      filter: ["==", "id", ""]
    },
    "contactsFeatures"
  )

  map.addLayer({
    id: "contactsClusters",
    type: "circle",
    source: "contactsFeaturesSource",
    filter: ["has", "point_count"],
    paint: {
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      "circle-color": colorGreen,
      "circle-stroke-width": 2,
      "circle-stroke-color": colorLight
    }
  })

  map.addLayer({
    id: "contactsClustersHover",
    type: "circle",
    source: "contactsFeaturesSource",
    filter: ["all", ["==", "cluster_id", ""], ["has", "point_count"]],
    paint: {
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      "circle-color": colorGreen,
      "circle-stroke-width": 2,
      "circle-stroke-color": colorDark
    }
  })

  map.addLayer({
    id: "contactsClustersCount",
    type: "symbol",
    source: "contactsFeaturesSource",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
      "text-size": 14
    },
    paint: {
      "text-color": "#ffffff"
    }
  })

  map.on("click", "contactsClusters", function(e) {
    var features = map.queryRenderedFeatures(e.point, { layers: ["contactsClusters"] })
    var clusterId = features[0].properties.cluster_id
    map.getSource("contactsFeaturesSource").getClusterExpansionZoom(clusterId, function(err, zoom) {
      if (err) return

      map.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom
      })
    })
  })
}
export function displayRessources(map, ressources) {
  const loadRessourcesFeatureCollection = () => {
    const featureCollection = turf.featureCollection([])

    Object.keys(ressources).map(key => {
      var geometry = {
        type: "Point",
        coordinates: [ressources[key].lng, ressources[key].lat]
      }
      var properties = {
        id: key,
        status: ressources[key].status
      }
      var feature = turf.feature(geometry, properties)
      return featureCollection.features.push(feature)
    })

    return featureCollection
  }

  if (map.getLayer("ressourcesFeatures")) {
    map.removeLayer("ressourcesFeatures")
    map.removeLayer("ressourcesFeaturesHover")
    map.removeLayer("ressourcesFeaturesActive")
    map.removeLayer("ressourcesClusters")
    map.removeLayer("ressourcesClustersHover")
    map.removeLayer("ressourcesClustersCount")
    map.removeSource("ressourcesFeaturesSource")
  }

  map.addSource("ressourcesFeaturesSource", {
    type: "geojson",
    data: loadRessourcesFeatureCollection(),
    cluster: true,
    clusterRadius: 50
  })

  map.addLayer({
    id: "ressourcesFeatures",
    type: "circle",
    source: "ressourcesFeaturesSource",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-radius": 10,
      // "circle-color": colorRed,
      "circle-color": ["match", ["get", "status"], "suspicious", colorOrange, "true", colorRed, "false", colorBrown, /* other */ colorDark],
      "circle-stroke-width": 2,
      "circle-stroke-color": colorLight
    }
  })

  map.addLayer(
    {
      id: "ressourcesFeaturesHover",
      type: "circle",
      source: "ressourcesFeaturesSource",
      layout: {},
      paint: {
        "circle-radius": 12,
        "circle-color": colorRed,
        "circle-stroke-width": 2,
        "circle-stroke-color": colorDark
      },
      filter: ["==", "id", ""]
    },
    "ressourcesFeatures"
  )

  map.addLayer(
    {
      id: "ressourcesFeaturesActive",
      type: "circle",
      source: "ressourcesFeaturesSource",
      layout: {},
      paint: {
        "circle-radius": 12,
        "circle-color": colorRed,
        "circle-stroke-width": 2,
        "circle-stroke-color": colorDark
      },
      filter: ["==", "id", ""]
    },
    "ressourcesFeatures"
  )

  map.addLayer({
    id: "ressourcesClusters",
    type: "circle",
    source: "ressourcesFeaturesSource",
    filter: ["has", "point_count"],
    paint: {
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      "circle-color": colorDark,
      "circle-stroke-width": 2,
      "circle-stroke-color": colorLight
    }
  })

  map.addLayer({
    id: "ressourcesClustersHover",
    type: "circle",
    source: "ressourcesFeaturesSource",
    filter: ["all", ["==", "cluster_id", ""], ["has", "point_count"]],
    paint: {
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      "circle-color": colorDark,
      "circle-stroke-width": 2,
      "circle-stroke-color": colorDark
    }
  })

  map.addLayer({
    id: "ressourcesClustersCount",
    type: "symbol",
    source: "ressourcesFeaturesSource",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
      "text-size": 14
    },
    paint: {
      "text-color": "#ffffff"
    }
  })

  map.on("click", "ressourcesClusters", function(e) {
    var features = map.queryRenderedFeatures(e.point, { layers: ["ressourcesClusters"] })
    var clusterId = features[0].properties.cluster_id
    map.getSource("ressourcesFeaturesSource").getClusterExpansionZoom(clusterId, function(err, zoom) {
      if (err) return

      map.easeTo({
        center: features[0].geometry.coordinates,
        zoom: zoom
      })
    })
  })
}
// END : Function Display/Hide features layers (contact, ressource)

async function getGeoJson(fileUrl) {
  return fetch(fileUrl)
    .then(response => response.json())
    .catch(err => {
      // Do something for an error here
      console.error("Error 3d6526cc-eaf6-4d3b-b986-a79607b44671", err)
    })
}

export function displayAreas(map, areasArray) {
  let areasCollection = turf.featureCollection([])

  if (map.getLayer("areasFeatures")) {
    map.removeLayer("areasFeatures")
    map.removeLayer("areasFeaturesBorder")
    map.removeSource("areasSource")
  }

  map.addSource("areasSource", {
    type: "geojson",
    data: areasCollection
  })

  map.addLayer({
    id: "areasFeaturesBorder",
    type: "line",
    source: "areasSource",
    layout: {},
    paint: {
      "line-width": 5,
      // "line-color": "#616161", //#494949 = un peu plus foncé
      "line-color": "white", //#494949 = un peu plus foncé
      "line-opacity": 0.5
    }
  })
  map.addLayer({
    id: "areasFeatures",
    type: "line",
    source: "areasSource",
    layout: {},
    paint: {
      "line-width": 3,
      // "line-color": "#ffff00",
      "line-color": "#8e24aa",
      "line-opacity": 1
      // "line-dasharray": [1, 2]
    }
  })

  if (areasArray) {
    areasArray.forEach(key => {
      const areaJson = require("../data/areas/" + key + ".geojson")
      getGeoJson(areaJson)
        .then(area => {
          areasCollection.features = [...areasCollection.features, ...area.features]
          // console.log("areasCollection", areasCollection)
          map.getSource("areasSource").setData(areasCollection)
          return areasCollection
        })
        .then(areasCollection => {
          const bbox = turf.bbox(areasCollection)
          map.fitBounds(bbox, {
            padding: 40,
            duration: 0
          })

          // setTimeout(() => {
          //   const bbox = turf.bbox(areasCollection)
          //   map.fitBounds(bbox, {
          //     padding: 40,
          //     duration: 10000
          //   })
          // }, 100)
        })
    })
  }

  // const bbox = turf.bbox(map.getSource('areasLine')._data)
  // map.fitBounds(bbox, { padding: 40 })
}
