import React, { Component, Fragment } from "react"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { SnackbarProvider } from "notistack"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"

import { CssBaseline } from "@material-ui/core"
import { Fade } from "@material-ui/core"

import Home from "./Home"
import Auth from "./Auth"
import Dashboard from "./Dashboard"
import IntroDialog from "./IntroDialog"
import NoAreaDialog from "./NoAreaDialog"

const theme = createMuiTheme({
  // overrides: {
  //   MuiDivider: {
  //     root: {
  //       marginTop: 8 * 3,
  //       marginBottom: 8 * 3
  //     }
  //   },
  // },
  palette: {
    primary: {
      light: "#77a0b0",
      main: "#55899d",
      dark: "#3b5f6d",
      contrastText: "#fff"
    },
    secondary: {
      light: "#dca665",
      main: "#d4903f",
      dark: "#94642c",
      contrastText: "#fff"
    },

    // palette: {
    //   primary: {
    //     light: "#3492ca",
    //     main: "#0277bd",
    //     dark: "#015384",
    //     contrastText: "#fff"
    //   },
    //   secondary: {
    //     light: "#676767",
    //     main: "#424242",
    //     dark: "#2e2e2e",
    //     contrastText: "#fff"
    //   },
    background: {
      default: "#e0e0e0"
    }
  },
  typography: {
    h1: {
      fontWeight: 300
    },
    h2: {
      fontWeight: 300
    },
    h3: {
      fontWeight: 300
    },
    h4: {
      fontWeight: 300
    },
    h5: {
      fontWeight: 300
    },
    h6: {
      fontWeight: 300
    },
    useNextVariants: true
  },
  mixins: {
    toolbar: {
      minHeight: 64
      // minHeight: 56,
      // "@media (min-width:0px) and (orientation: landscape)": {
      //   minHeight: 56
      // },
      // "@media (min-width:600px)": {
      //   minHeight: 64
      // }
    }
  }
})

class App extends Component {
  componentDidMount() {
    // const string = "Nouvel évènement signalé à 35.2 Km de votre troupeau. COMMUNE Fresse-sur-Moselle. STATUT En cours d'analyse. EVENEMENT Loup Attaque bovins. AUTEUR Alain LAURENT."
    // const smsMessage = gsmEncoding(string)
    // console.log(smsMessage.length, smsMessage)

    //   window.scroll(function() {
    //     // var scrollTop = window.scrollTop()
    //     var windowHeight = window.innerHeight
    //     // console.log("scrollTop", scrollTop)
    //     console.log("windowHeight", windowHeight)
    //   })
  }

  handleUser = user => {
    this.setState({ user: user })
  }
  handleLoading = loading => {
    this.setState({ loading: loading })
  }

  render() {
    return (
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Proxiloup</title>
            <link rel="canonical" href="https://www.proxiloup.com" />
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
          </Helmet>

          <MuiThemeProvider theme={theme}>
            <CssBaseline />

            {this.props.isAppLoading && !this.props.user && (
              <Home>
                <Auth />
              </Home>
            )}

            {!this.props.isAppLoading && this.props.user && (
              <Fade in={true}>
                <div style={{ height: "100%" }}>
                  <IntroDialog/>
                  <NoAreaDialog/>
                  <Dashboard />
                </div>
              </Fade>
            )}
          </MuiThemeProvider>
        </Fragment>
      </SnackbarProvider>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    isAppLoading: state.appReducer.isAppLoading
    // user: {
    //   uid: "m0YKYNcm5…000"
    // }
  }
}

export default connect(mapStateToProps)(App)
