import React, { Component } from "react"
import { Link } from "react-router-dom"

import { List } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { ListItem } from "@material-ui/core"
import { ListItemIcon } from "@material-ui/core"
import { ListItemText } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Zoom } from "@material-ui/core"

import AccountIcon from "@material-ui/icons/Person"
// import NotificationIcon from "@material-ui/icons/Notifications"
import MapIcon from "@material-ui/icons/Map"
import GroupIcon from "@material-ui/icons/Group"
import ContactIcon from "@material-ui/icons/PersonPinCircle"
import RessourceIcon from "@material-ui/icons/Warning"
import HelpIcon from "@material-ui/icons/Help"
// import SettingsIcon from "@material-ui/icons/Settings"
// import SearchIcon from "@material-ui/icons/Search"
// import StatsIcon from "@material-ui/icons/Assessment"

const colorGreen = "#558b2f"
const colorRed = "#b71c1c"

class ListItemLink extends Component {
  renderLink = itemProps => <Link to={this.props.to} {...itemProps} />

  render() {
    const { icon, text } = this.props
    return (
      <li>
        <ListItem button component={this.renderLink}>
          <ListItemIcon style={{ marginLeft: "8px", marginRight: "8px" }}>{icon}</ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      </li>
    )
  }
}

class Menu extends Component {
  render() {
    return (
      <div onClick={this.props.onClick}>
        <Divider />

        <List component="nav">
          <ListItemLink
            to="/"
            text="Carte"
            icon={
              <Tooltip title="Carte" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <MapIcon />
              </Tooltip>
            }
          />
          <ListItemLink
            to="/evenements"
            text={<span style={{ color: colorRed }}>Evènements</span>}
            icon={
              <Tooltip title="Evènements" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <RessourceIcon style={{ color: colorRed }} />
              </Tooltip>
            }
          />
          <ListItemLink
            to="/troupeaux"
            text={<span style={{ color: colorGreen }}>Troupeaux</span>}
            icon={
              <Tooltip title="Troupeaux" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <ContactIcon style={{ color: colorGreen }} />
              </Tooltip>
            }
          />
        </List>

        <Divider />

        <List component="nav">
          <ListItemLink
            to="/compte"
            text="Compte"
            icon={
              <Tooltip title="Compte" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <AccountIcon />
              </Tooltip>
            }
          />
          <ListItemLink
            to="/utilisateurs"
            text="Utilisateurs"
            icon={
              <Tooltip title="Utilisateurs" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <GroupIcon />
              </Tooltip>
            }
          />
          {/* <ListItemLink
            to="/notifications"
            text="Notifications"
            icon={
              <Tooltip title="Notifications" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <NotificationIcon />
              </Tooltip>
            }
          /> */}
          {/* <ListItemLink
            to="/recherche"
            text="Recherches"
            icon={
              <Tooltip title="Recherches" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <SearchIcon />
              </Tooltip>
            }
          />
          <ListItemLink
            to="/stats"
            text="Statistiques"
            icon={
              <Tooltip title="Statistiques" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <StatsIcon />
              </Tooltip>
            }
          /> */}

          {/* </List>

        <Divider />

        <List component="nav"> */}

          {/* <ListItemLink
            to="/reglages"
            text="Paramètres"
            icon={
              <Tooltip title="Paramètres" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <SettingsIcon />
              </Tooltip>
            }
          /> */}
          <ListItemLink
            to="/aide"
            text="Aide - A propos"
            icon={
              <Tooltip title="Aide - A propos" placement="right" TransitionComponent={Zoom} disableFocusListener>
                <HelpIcon />
              </Tooltip>
            }
          />
        </List>

        <Divider />
      </div>
    )
  }
}

export default Menu
