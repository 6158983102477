import React from "react"

import { SvgIcon } from "@material-ui/core"

export default props => (
  <SvgIcon viewBox="0 0 426.23 421.65" {...props} >
    <path
      d="M37.34,466.65C31.73,406.43,68.11,355.35,126,341.94a11.08,11.08,0,0,1,6.93.9C159,356,185,356,211.1,342.82A11,11,0,0,1,218,342c58.19,13.58,82.65,55.42,87.93,102,.84,7.4.92,14.88,1.38,22.71Z"
      transform="translate(-36.77 -45)"
    />
    <path
      d="M171.78,343.09c-40.27-.08-72.81-33-72.66-73.51.13-40,33-72.63,73.11-72.58s72.75,33,72.66,73A73,73,0,0,1,171.78,343.09Z"
      transform="translate(-36.77 -45)"
    />
    <path
      d="M377.82,215A85,85,0,1,1,463,130.45C462.93,177.17,424.78,215,377.82,215ZM372,124.05V96.64c-8.92,0-17.53,0-26.15.07a3.41,3.41,0,0,0-2.66,1.6c-2.63,8.35-4.72,16.81-4.32,25.74Zm46.38,0c-1.81-8.71-3.37-16.67-5.19-24.56-.26-1.1-1.79-2.7-2.76-2.72-8.76-.21-17.52-.12-26.29-.12v27.4ZM372,163.36V136H337.67c1.83,8.76,3.48,17,5.37,25.17a3.67,3.67,0,0,0,2.76,2.08C354.42,163.43,363,163.36,372,163.36ZM384.05,136v27.36c9,0,17.6.07,26.23-.09a3.69,3.69,0,0,0,2.7-2.15c1.88-8.17,3.52-16.4,5.34-25.12Zm-80.74-12.08h23.34c1.5-9.08,3-17.87,4.5-27.24-5.85,0-11.62-.14-17.37.13-1,0-2.53,1.62-2.91,2.78C308.29,107.39,306,115.29,303.31,123.91Zm126,0H452.7c-2.66-8.64-5-16.54-7.6-24.33a4.87,4.87,0,0,0-3.41-2.79c-5.58-.3-11.19-.13-16.83-.13C426.41,106.05,427.86,114.86,429.34,123.89Zm-98.21,39.47c-1.54-9.41-3-18.31-4.49-27.33H303.27c2.77,8.88,5.23,17.05,7.93,25.13a3.9,3.9,0,0,0,2.89,2.1C319.69,163.46,325.31,163.36,331.13,163.36Zm121.56-27.3H429.35c-1.51,9.19-3,18-4.49,27.3,5.78,0,11.39.14,17-.12a4.37,4.37,0,0,0,3.12-2.48C447.61,152.82,450,144.79,452.69,136.06ZM372,59.34c-10.21,6.81-17.67,14.88-23.06,25.49H372Zm35.27,25.71A66.12,66.12,0,0,0,384.1,59.37V85.05Zm-58.39,90a65.84,65.84,0,0,0,23.1,25.59V175.07Zm35.24,0v25.6A65.45,65.45,0,0,0,407.17,175ZM350.32,61.52a74.79,74.79,0,0,0-30.84,23.67c5.64,0,10.22.14,14.77-.11,1,0,2.15-1.29,2.77-2.27C341.46,75.86,345.77,68.83,350.32,61.52Zm55.46.12c4.53,7.3,8.83,14.34,13.26,21.3.62,1,1.85,2.11,2.85,2.16,4.53.23,9.08.1,14.69.1C427.72,74,417.76,66.51,405.78,61.64ZM350.2,198.39c-4.5-7.32-8.88-14.49-13.33-21.61-.48-.77-1.34-1.83-2.06-1.85-4.81-.17-9.64-.09-15.36-.09A74.67,74.67,0,0,0,350.2,198.39Zm55.59,0a74,74,0,0,0,30.74-23.61c-5.5,0-9.93-.12-14.33.09a4.47,4.47,0,0,0-3,1.9C414.66,183.87,410.3,191.06,405.79,198.4Z"
      transform="translate(-36.77 -45)"
    />
  </SvgIcon>
)
