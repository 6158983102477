import querystring from "querystring"

// Function Send email
export async function sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody) {
  const API_ENDPOINT = window.location.origin + "/.netlify/functions/sendEmail"
  const secretKey = process.env.REACT_APP_AMAZON_SECRET
  
  const params = {
    mailFrom,
    mailReplyTo,
    mailTo,
    mailSubject,
    mailBody,
    secretKey
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params)
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("Error 79401a34-0b96-46a7-bba7-9dcb68ddb163", error)
      })
      .then(response => {
        // console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Send email


// Function Send sms
export async function sendSms(smsSender, smsReceiver, smsMessage) {
  const API_ENDPOINT = window.location.origin + "/.netlify/functions/sendSms"
  const secretKey = process.env.REACT_APP_AMAZON_SECRET
  
  const params = {
    smsSender,
    smsReceiver,
    smsMessage,
    secretKey
  }

  return (
    fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: querystring.stringify(params)
    })
      // .then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data.response)
      //   return data
      // })
      .catch(error => {
        console.error("Error 8eb7a9e1-5708-4f98-bfb5-79dde646ec26", error)
      })
      .then(response => {
        // console.log("SUCCESS ==> ", response)
        return response
      })
  )
}
// END : Function Send sms