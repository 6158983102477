import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import moment from "moment"
import { withStyles } from "@material-ui/core/styles"

import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
import { Hidden } from "@material-ui/core"
import { List } from "@material-ui/core"
import { ListItem } from "@material-ui/core"
import { ListItemSecondaryAction } from "@material-ui/core"
import { ListItemText } from "@material-ui/core"
import { Fade } from "@material-ui/core"
import { ExpansionPanel } from "@material-ui/core"
import { ExpansionPanelDetails } from "@material-ui/core"
import { ExpansionPanelSummary } from "@material-ui/core"
import { Button } from "@material-ui/core"

import WarningIcon from "@material-ui/icons/Warning"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
// import NotificationImportantIcon from "@material-ui/icons/NotificationImportant"
// import RessourceIcon from "@material-ui/icons/Place"
import MapIcon from "@material-ui/icons/Map"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ContactIcon from "@material-ui/icons/PersonPin"

const colorRed = "#b71c1c"

const styles = theme => ({
  root: {
    overflowX: "auto",
    padding: theme.spacing.unit * 2,
    // backgroundColor: theme.palette.grey[100],
    backgroundColor: colorRed,
    color: theme.palette.common.white,
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  panelDetails: {
    minWidth: 150,
    flexGrow: 1
  },
  panelSummary: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20
  }
})

class Ressources extends Component {
  // getAuthor(userId) {
  //   firebase
  //     .database()
  //     .ref("/users/" + userId)
  //     .once("value")
  //     .then(snapshot => {
  //       const user = snapshot.val()
  //       console.log(user.firstname)
  //       return user.firstname + " " + user.lastname
  //     })
  // }

  state = {
    expanded: ""
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  handleFlyToRessourcePosition = (lng, lat, ressourceId) => {
    const ressource = this.props.ressources[ressourceId]
    // Redirect to map
    this.props.history.push("/")

    this.props.dispatch({ type: "UPDATE_ACTIVE_ALERT", value: "" })
    const { alerts } = this.props
    Object.keys(alerts).forEach(key => {
      const alert = alerts[key]
      if (alert.ressource === ressourceId) {
        alert.id = key
        this.props.dispatch({ type: "UPDATE_ACTIVE_ALERT", value: alert })
      }
    })

    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE_ID", value: ressourceId })
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: ressource })
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT_ID", value: null })
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: null })

    setTimeout(() => {
      // Save to Redux global state
      this.props.dispatch({ type: "TOGGLE_FLY_TO_ACTIVE_FEATURE", value: { lng, lat } })
    }, 500)
  }

  handleFlyToContactPosition = (lng, lat, contactId) => {
    const contact = this.props.contacts[contactId]
    // Redirect to map
    this.props.history.push("/")

    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT_ID", value: contactId })
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: contact })
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE_ID", value: null })
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: null })

    setTimeout(() => {
      // Save to Redux global state
      this.props.dispatch({ type: "TOGGLE_FLY_TO_ACTIVE_FEATURE", value: { lng, lat } })
    }, 500)
  }

  render() {
    const { classes, alerts, joins, users, contacts, ressources } = this.props

    const loadEvents = (min, max) => {
      const difference = alert => Number(moment().diff(moment(alert.date + "_" + alert.time, ["YYYY-MM-DD_hh:mm:ss"]).format(), "months"))
      return Object.keys(alerts)
        .filter(key => difference(alerts[key]) >= min && difference(alerts[key]) <= max)
        .reverse()
        .map(key => {
          const alert = alerts[key]
          const ressourceId = alert.ressource
          const ressource = ressources[ressourceId]
          const alertRegex = new RegExp(key)
          const alertJoinsKeys = Object.keys(joins).filter(key => alertRegex.test(key))

          return (
            <Fragment key={key}>
              {alert && ressource && alertJoinsKeys && (
                <ExpansionPanel expanded={this.state.expanded === key} onChange={this.handleChangePanel(key)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.panelSummary}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      <Grid item style={{ marginRight: "8px" }}>
                        <CheckCircleIcon color={ressource.status === "true" ? "action" : "disabled"} />

                        <WarningIcon
                          color={
                            (ressource.event && (ressource.event[0] === "Bétail" && ressource.event[2] === "Cadavre")) ||
                            (ressource.event.category === "impact sur bétail-troupeau" && ressource.event.killed > 0)
                              ? "error"
                              : "disabled"
                          }
                        />
                      </Grid>
                      <Grid item>
                        {ressource.event && (
                          <Typography>
                            {moment(alert.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY")} :{" "}
                            {ressource.event.category ? (
                              ressource.event.category
                            ) : (
                              <Fragment>
                                {ressource.event[0]}, {ressource.event[1]}, {ressource.event[2]}
                              </Fragment>
                            )}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>
                  <Divider />
                  <ExpansionPanelDetails>
                    <div className={classes.panelDetails}>
                      <Typography variant="h6">Evènement</Typography>
                      <Typography>
                        <strong>Catégorie : </strong>
                        {ressource.event.category ? (
                          ressource.event.category
                        ) : (
                          <Fragment>
                            {ressource.event[0]}, {ressource.event[1]}, {ressource.event[2]}
                          </Fragment>
                        )}
                        {" ∙ ∙ ∙ "}
                        <strong>Date d'observation : </strong>
                        {moment(ressource.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY")} {moment(ressource.time, ["HH:mm:ss"]).format("HH:mm")}
                        {" ∙ ∙ ∙ "}
                        <strong>Commune : </strong>
                        {ressource.location.city}
                        {" ∙ ∙ ∙ "}
                        <strong>Observateur : </strong>
                        {users[ressource.author] && users[ressource.author].firstname + " " + users[ressource.author].lastname}
                      </Typography>

                      <Button
                        color="primary"
                        variant="outlined"
                        style={{ marginTop: "24px", marginBottom: "24px" }}
                        fullWidth
                        onClick={() => this.handleFlyToRessourcePosition(ressource.lng, ressource.lat, ressourceId)}
                      >
                        <MapIcon className={classes.buttonIcon} />
                        Situer sur la carte
                      </Button>

                      <Typography variant="h6">Signalement envoyé</Typography>
                      <Typography>
                        <strong>Date du signalement : </strong>
                        {moment(alert.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY")} {moment(alert.time, ["HH:mm:ss"]).format("HH:mm")}
                        {" ∙ ∙ ∙ "}
                        <strong>Méthode : </strong>
                        {alert.method === "auto" ? "Sélection automatique (rayon de " + alert.radius + " Km)" : "Sélection manuelle (polygone)"}
                        {" ∙ ∙ ∙ "}
                        <strong>Signalé par : </strong>
                        {users[alert.author] && users[alert.author].firstname + " " + users[alert.author].lastname}
                      </Typography>

                      <Typography variant="h6" style={{ marginTop: "16px" }}>
                        Personnes alertées ({alertJoinsKeys.length})
                      </Typography>

                      <List dense>
                        {alertJoinsKeys.map(key => {
                          const contactId = joins[key].contact
                          const contact = contacts[contactId]
                          return (
                            <div key={key}>
                              <ListItem>
                                <Hidden xsDown>
                                  <ContactIcon fontSize="large" color="action" />
                                </Hidden>
                                <ListItemText
                                  primary={contact.firstname + " " + contact.lastname + " ∙ ∙ ∙ " + contact.title + " ∙ ∙ ∙ " + contact.location.city}
                                  secondary={contact.phone + " ∙ ∙ ∙ " + contact.email}
                                />
                                <ListItemSecondaryAction>
                                  <Tooltip title="Situer sur la carte" placement="left" TransitionComponent={Zoom} disableFocusListener>
                                    <IconButton onClick={() => this.handleFlyToContactPosition(contact.lng, contact.lat, contactId)}>
                                      <MapIcon />
                                    </IconButton>
                                  </Tooltip>
                                </ListItemSecondaryAction>
                              </ListItem>
                              <Divider />
                            </div>
                          )
                        })}
                      </List>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </Fragment>
          )
        })
    }

    return (
      <Fade in={true}>
        <div className={classes.root}>
          <Grid container direction="row" justify="flex-start" alignItems="center" style={{ margin: "8px", marginBottom: "24px" }}>
            <WarningIcon fontSize="large" style={{ marginRight: "16px" }} color="inherit" />
            <Grid item xs zeroMinWidth>
              <Typography variant="h5" noWrap color="inherit">
                Evènements - Signalements
              </Typography>
            </Grid>
          </Grid>

          {joins && alerts && contacts && users && ressources && (
            <Fragment>
              {loadEvents(0, 6)}
              <Typography variant="h6" color="inherit" style={{ marginTop: "16px", marginBottom: "8px" }}>
                Il y a plus de 6 mois :
              </Typography>
              {loadEvents(7, 12)}
              <Typography variant="h6" color="inherit" style={{ marginTop: "16px", marginBottom: "8px" }}>
                Il y a plus de 1 an :
              </Typography>
              {loadEvents(13, 24)}
              <Typography variant="h6" color="inherit" style={{ marginTop: "16px", marginBottom: "8px" }}>
                Il y a plus de 2 ans :
              </Typography>
              {loadEvents(25, 9999)}
            </Fragment>
          )}
        </div>
      </Fade>
    )
  }
}

const mapStateToProps = state => {
  return {
    joins: state.dataReducer.joins,
    alerts: state.dataReducer.alerts,
    contacts: state.dataReducer.contacts,
    ressources: state.dataReducer.ressources,
    users: state.dataReducer.users
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Ressources))
