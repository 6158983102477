import { styles as mapboxStyles } from "../../config/mapbox.json"

const initialState = {
  baseMapStyle: mapboxStyles.streets,
  elevationShadows: false,
  is3dView: false,
  zoomDirection: null,
  userGeolocation: false,
  contactViewMode: true,
  contactUpdateMode: false,
  contactNewMode: false,
  ressourceViewMode: true,
  ressourceUpdateMode: false,
  ressourceNewMode: false,
  activeContact: null,
  activeRessource: null,
  newPointFeature: false,
  contactPointDrawMode: false,
  ressourcePointDrawMode: false,
  activeAlert: "",
  nextAlert: "",
  alertNewMode: false,
  alertViewMode: false,
  alertContacts: null,
  alertPolygonDrawMode: false
}

function mapReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    // MAP - TOOLS - ACTIONS

    case "UPDATE_BASE_MAP_STYLE":
      nextState = {
        ...state,
        baseMapStyle: action.value
      }
      return nextState || state

    case "TOGGLE_ELEVATION_SHADOWS":
      nextState = {
        ...state,
        elevationShadows: action.value
      }
      return nextState || state

    case "TOGGLE_3D_VIEW":
      nextState = {
        ...state,
        is3dView: action.value
      }
      return nextState || state

    case "ZOOM_IN_OUT":
      nextState = {
        ...state,
        zoomDirection: action.value
      }
      return nextState || state

    case "TOGGLE_USER_GEOLOCATION":
      nextState = {
        ...state,
        userGeolocation: action.value
      }
      return nextState || state

    case "TOGGLE_FLY_TO_ACTIVE_FEATURE":
      nextState = {
        ...state,
        flyToActiveFeature: action.value
      }
      return nextState || state

    // VIEW - UPDATE - NEW

    case "TOGGLE_CONTACT_VIEW_MODE":
      nextState = {
        ...state,
        contactViewMode: action.value
      }
      return nextState || state

    case "TOGGLE_CONTACT_UPDATE_MODE":
      nextState = {
        ...state,
        contactUpdateMode: action.value
      }
      return nextState || state

    case "TOGGLE_CONTACT_NEW_MODE":
      nextState = {
        ...state,
        contactNewMode: action.value
      }
      return nextState || state

    case "TOGGLE_RESSOURCE_VIEW_MODE":
      nextState = {
        ...state,
        ressourceViewMode: action.value
      }
      return nextState || state

    case "TOGGLE_RESSOURCE_UPDATE_MODE":
      nextState = {
        ...state,
        ressourceUpdateMode: action.value
      }
      return nextState || state

    case "TOGGLE_RESSOURCE_NEW_MODE":
      nextState = {
        ...state,
        ressourceNewMode: action.value
      }
      return nextState || state

    // ACTIVE FEATURE

    case "UPDATE_ACTIVE_CONTACT":
      nextState = {
        ...state,
        activeContact: action.value
      }
      return nextState || state

    case "UPDATE_ACTIVE_RESSOURCE":
      nextState = {
        ...state,
        activeRessource: action.value
      }
      return nextState || state

    case "UPDATE_ACTIVE_CONTACT_ID":
      nextState = {
        ...state,
        activeContactId: action.value
      }
      return nextState || state

    case "UPDATE_ACTIVE_RESSOURCE_ID":
      nextState = {
        ...state,
        activeRessourceId: action.value
      }
      return nextState || state

    // POINT DRAW MODE

    case "UPDATE_NEW_POINT_FEATURE":
      nextState = {
        ...state,
        newPointFeature: action.value
      }
      return nextState || state

    case "TOGGLE_CONTACT_POINT_DRAW_MODE":
      nextState = {
        ...state,
        contactPointDrawMode: action.value
      }
      return nextState || state

    case "TOGGLE_RESSOURCE_POINT_DRAW_MODE":
      nextState = {
        ...state,
        ressourcePointDrawMode: action.value
      }
      return nextState || state

    // ALERT

    case "UPDATE_ACTIVE_ALERT":
      nextState = {
        ...state,
        activeAlert: action.value
      }
      return nextState || state

    case "UPDATE_NEXT_ALERT":
      nextState = {
        ...state,
        nextAlert: action.value
      }
      return nextState || state

    case "TOGGLE_ALERT_NEW_MODE":
      nextState = {
        ...state,
        alertNewMode: action.value
      }
      return nextState || state

    case "TOGGLE_ALERT_VIEW_MODE":
      nextState = {
        ...state,
        alertViewMode: action.value
      }
      return nextState || state

    case "UPDATE_ALERT_CONTACTS":
      nextState = {
        ...state,
        alertContacts: action.value
      }
      return nextState || state

    case "TOGGLE_ALERT_POLYGON_DRAW_MODE":
      nextState = {
        ...state,
        alertPolygonDrawMode: action.value
      }
      return nextState || state

    //...
    default:
      return state
  }
}

export default mapReducer
