const initialState = { toggleDrawerMenu: true, isAppLoading: true, openAreaDialog: false }

function appReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case "TOGGLE_DRAWER_MENU":
      nextState = {
        ...state,
        toggleDrawerMenu: action.value
      }
      return nextState || state

    case "TOGGLE_APP_LOADING":
      nextState = {
        ...state,
        isAppLoading: action.value
      }
      return nextState || state

    case "OPEN_AREA_DIALOG":
      nextState = {
        ...state,
        openAreaDialog: action.value
      }
      return nextState || state

    case "OPEN_NO_AREA_DIALOG":
      nextState = {
        ...state,
        openNoAreaDialog: action.value
      }
      return nextState || state

    //...
    default:
      return state
  }
}

export default appReducer
