import { createStore, combineReducers } from "redux"

import userReducer from "./reducers/userReducer"
import appReducer from "./reducers/appReducer"
import mapReducer from "./reducers/mapReducer"
import dataReducer from "./reducers/dataReducer"

/* eslint-disable no-underscore-dangle */

const store = createStore(
  combineReducers({
    userReducer,
    appReducer,
    mapReducer,
    dataReducer
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

/* eslint-enable */

export default store
