import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Grid } from "@material-ui/core"
import { Popover } from "@material-ui/core"
import { Fab } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
// import { Badge } from "@material-ui/core"
import { List } from "@material-ui/core"
import { ListItem } from "@material-ui/core"
import { ListItemIcon } from "@material-ui/core"
import { ListItemText } from "@material-ui/core"

import AccountIcon from "@material-ui/icons/Person"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"
// import NotificationsIcon from "@material-ui/icons/Notifications"
import SignOutIcon from "@material-ui/icons/PowerSettingsNew"

import firebase from "../config/firebase.js"

import { withSnackbar } from "notistack"
import { message, requestFullscreen, exitFullscreen } from "../functions/appInterface"

const styles = theme => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  },
  extendedButton: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    },
    boxShadow: "none",
    textTransform: "none"
  }
})

class ToolbarShortcuts extends Component {
  state = {
    isFullscreen: false,
    anchorEl: null
  }

  handleOpenPopover = event => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleClosePopover = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleRequestFullscreen = () => {
    this.setState({
      isFullscreen: true
    })
    requestFullscreen()
  }

  handleExitFullscreen = () => {
    this.setState({
      isFullscreen: false
    })
    exitFullscreen()
  }

  // Define alert message context
  message = message

  handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Save to Redux global state
        this.props.dispatch({ type: "UPDATE_USER", value: null })
        this.props.dispatch({ type: "UPDATE_USER_EMAIL", value: null })
        this.props.dispatch({ type: "UPDATE_USER_PASSWORD", value: null })
        this.props.dispatch({ type: "UPDATE_USER_GROUP", value: null })
        this.props.dispatch({ type: "UPDATE_USER_FIRST_NAME", value: null })
        this.props.dispatch({ type: "UPDATE_USER_LAST_NAME", value: null })
        this.props.dispatch({ type: "UPDATE_USER_PHONE", value: null })
        this.props.dispatch({ type: "UPDATE_USER_AREAS", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_EMAIL", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_PASSWORD", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_FIRST_NAME", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_LAST_NAME", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_PHONE", value: null })
        this.props.dispatch({ type: "UPDATE_NEXT_USER_AREAS", value: null })

        this.props.dispatch({ type: "TOGGLE_APP_LOADING", value: true })
        this.message("warning", "Vous êtes déconnecté...")
      })
      .catch(error => {
        console.error("Error 9c24df5a-2301-4bd6-a5dd-6b0362179809", error)
        this.message("error", "Erreur : " + error.message)
      })
  }

  render() {
    const { classes } = this.props
    const { isFullscreen, anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <Fragment>
        <div className={classes.sectionDesktop}>
          {/* <Tooltip title="Notifications" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
            <IconButton color="inherit" component={Link} to="/notifications">
              <Badge badgeContent={8} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip> */}

          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Tooltip title="Mon compte" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
              <Fab
                aria-owns={open ? "accountPopper" : undefined}
                aria-haspopup="true"
                variant="extended"
                aria-label="Mon compte"
                color="inherit"
                className={classes.extendedButton}
                onClick={this.handleOpenPopover}
              >
                <AccountIcon className={classes.extendedIcon} />
                {this.props.userFirstName + " " + this.props.userLastName}
              </Fab>
            </Tooltip>
          </Grid>
        </div>

        <div className={classes.sectionMobile}>
          <Tooltip title="Mon compte" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
            <IconButton color="inherit" component={Link} aria-label="Mon compte" onClick={this.handleOpenPopover} to="#">
              <AccountIcon />
            </IconButton>
          </Tooltip>
        </div>

        <Popover
          id="accountPopper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClosePopover}
          onClick={this.handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <List>
            <ListItem button component={Link} to="/compte">
              <ListItemIcon>
                <AccountIcon />
              </ListItemIcon>
              <ListItemText primary="Mon compte" />
            </ListItem>

            <ListItem button onClick={this.handleSignOut}>
              <ListItemIcon>
                <SignOutIcon />
              </ListItemIcon>
              <ListItemText primary="Se déconnecter" />
            </ListItem>
          </List>
        </Popover>

        <Tooltip title="Plein écran" placement="bottom" TransitionComponent={Zoom} disableFocusListener>
          {isFullscreen ? (
            <IconButton color="inherit" onClick={this.handleExitFullscreen}>
              <FullscreenExitIcon />
            </IconButton>
          ) : (
            <IconButton color="inherit" onClick={this.handleRequestFullscreen}>
              <FullscreenIcon />
            </IconButton>
          )}
        </Tooltip>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    userFirstName: state.userReducer.userFirstName,
    userLastName: state.userReducer.userLastName
  }
}

export default withStyles(styles)(connect(mapStateToProps)(withSnackbar(ToolbarShortcuts)))
