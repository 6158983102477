import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"

import { Typography } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { Grid } from "@material-ui/core"

import CustomLink from "../modules/CustomLink"
import LogoIcon from "../images/icon-192.png"

const styles = theme => ({
  layout: {
    flexGrow: 1,
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(500 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  logo: {
    marginTop: theme.spacing.unit * 3
  },
  title: {
    margin: theme.spacing.unit * 2
  },
  footer: {
    margin: theme.spacing.unit * 3
  },
  nowrap: {
    whiteSpace: "nowrap"
  }
})

class Home extends Component {
  render() {
    const { classes, children } = this.props

    return (
      <Fragment>
        <main className={classes.layout}>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Grid container alignItems="center" spacing={16} className={classes.title}>
                <Grid item>
                  <Typography>
                    <img src={LogoIcon} width="70" alt="" />
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h4">Proxiloup</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {children}

          <Divider />
          <Typography paragraph align="center" className={classes.footer}>
            <small>
              Proxiloup est un outil d'aide à la gestion en temps réel de la protection des troupeaux, permettant d'informer un réseau d'éleveurs et de bergers
              par SMS en cas de présence suspectée de prédateurs sur le territoire. En savoir plus :{" "}
              <CustomLink href="https://www.proxiloup.com" target="_blank" className={classes.nowrap}>
                www.proxiloup.com
              </CustomLink>
            </small>
          </Typography>
        </main>
      </Fragment>
    )
  }
}

export default withStyles(styles)(Home)
