import React, { Component } from "react"
import moment from "moment"
import { connect } from "react-redux"

import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import * as turf from "@turf/turf"
import { token } from "../config/mapbox.json"
import { colorBlue, colorLight, colorDark, colorYellow } from "../config/colors.json"

import { withSnackbar } from "notistack"
import { message } from "../functions/appInterface"
import { generateId, dateNow, timeNow } from "../functions/appStrings"
import { changeBaseMapStyle, toggleElevationShadows, toggle3dView, zoomInOut, flyToPosition, centerMapToPosition } from "../functions/mapTools"
import { displayAreas, displayContacts, displayRessources } from "../functions/mapDisplayFeatures"
import {
  enableAlertViewMode,
  disableAlertViewMode,
  enableAlertNewMode,
  disableAlertNewMode,
  selectContactsByRadius,
  removeContactsSelectFeatures
} from "../functions/mapAlert"
// import { enablePointDrawMode, disablePointDrawMode } from "../functions/mapTempFeature"
// import { enableContactViewMode, disableContactViewMode, enableRessourceViewMode, disableRessourceViewMode } from "../functions/mapFeatureView"

import areasName from "../data/areasName.json"
import { translation } from "../config/languages.json"

mapboxgl.accessToken = token

class MapCanvas extends Component {
  // Define map variable
  map
  // END : Define map variable

  // Define alert message context
  message = message
  // END : Define alert message context

  // ----------------------------------------------------------------------
  // COMPONENT DID MOUNT
  // ----------------------------------------------------------------------
  componentDidMount() {
    const {
      contacts,
      ressources,
      baseMapStyle,
      contactViewMode,
      // contactUpdateMode,
      // contactNewMode,
      ressourceViewMode,
      // ressourceUpdateMode,
      // ressourceNewMode
      userReducer
    } = this.props

    // Define map style
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      attributionControl: false,
      center: [6.6524, 46.5534], // Canton de Vaud
      // center: [7.0298, 47.8742], // Haut-Rhin Vosges (Saint-Amarin)
      // center: new RegExp("localhost").test(window.location.origin) ? [6.6524, 46.5534] : [7.0298, 47.8742],
      // zoom: 8.5,
      zoom: 5,
      pitch: 0,
      style: {
        version: 8,
        sources: {},
        layers: [
          {
            id: "background",
            type: "background",
            paint: {
              "background-color": colorLight
            }
          }
        ],
        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf"
      }
    })
    // END : Define map style

    // Map loading
    this.map.on("load", () => {
      // Add map attribution control
      this.map.addControl(
        new mapboxgl.AttributionControl({
          compact: true
        })
      )
      // END : Add map attribution controls

      // Add area boundaries layer
      if (userReducer.userAreas) {
        displayAreas(this.map, userReducer.userAreas)
      }
      // END : Add area boundaries layer

      // Add base map style
      if (baseMapStyle) {
        changeBaseMapStyle(this.map, baseMapStyle)
      }
      // END : Add base map style

      // Add contacts points layer
      if (contacts) {
        displayContacts(this.map, contacts)
      }
      // END : Add contacts points layer

      // Add ressources points layer
      if (ressources) {
        displayRessources(this.map, ressources)
      }
      // END : Add ressources points layer
    })
    // END : Map loading

    // Toggle view mode actions
    if (contactViewMode) {
      this.enableContactViewMode()
    } else {
      this.disableContactViewMode()
    }
    if (ressourceViewMode) {
      this.enableRessourceViewMode()
    } else {
      this.disableRessourceViewMode()
    }
    // END : Toggle view mode actions
  }

  // ----------------------------------------------------------------------
  // COMPONENT DID UPDATE
  // ----------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    const {
      toggleDrawerMenu,
      baseMapStyle,
      elevationShadows,
      is3dView,
      zoomDirection,
      userGeolocation,
      flyToActiveFeature,
      contacts,
      ressources,
      contactViewMode,
      contactUpdateMode,
      contactNewMode,
      ressourceViewMode,
      ressourceUpdateMode,
      ressourceNewMode,
      activeContact,
      activeRessource,
      // activeContactId,
      // activeRessourceId,
      // newPointFeature,
      contactPointDrawMode,
      ressourcePointDrawMode,
      alertNewMode,
      alertViewMode,
      activeAlert,
      nextAlert,
      alertPolygonDrawMode,
      // alertContacts,
      userReducer
    } = this.props

    // Add area boundaries layer
    if (prevProps.userReducer.userAreas !== userReducer.userAreas) {
      displayAreas(this.map, userReducer.userAreas)
    }
    // END : Add area boundaries layer

    // Change base map style
    if (prevProps.baseMapStyle !== baseMapStyle) {
      changeBaseMapStyle(this.map, baseMapStyle)
    }
    // END : Change base map style

    // Toggle elevation shadows
    if (prevProps.elevationShadows !== elevationShadows) {
      toggleElevationShadows(this.map)
    }
    // END : Toggle elevation shadows

    // Toggle map 3D view
    if (prevProps.is3dView !== is3dView) {
      toggle3dView(this.map)
    }
    // END : Toggle map 3D view

    // Zoom in or Zoom out
    if (prevProps.zoomDirection !== zoomDirection) {
      zoomInOut(this.map, zoomDirection)
    }
    // END : Zoom in or Zoom out

    // Geolocate user
    if (prevProps.userGeolocation !== userGeolocation) {
      this.geolocateUser()
    }
    // END : Geolocate user

    // Resize map on toggle drawer menu
    if (prevProps.toggleDrawerMenu !== toggleDrawerMenu) {
      this.map.resize()
    }
    // END : Resize map on toggle drawer menu

    // Run actions on change active feature
    if (prevProps.activeContact !== activeContact || prevProps.activeRessource !== activeRessource) {
      this.map.resize()

      if (!activeContact) {
        this.map.setFilter("contactsFeaturesActive", ["==", "id", ""])
      }
      if (!activeRessource) {
        this.map.setFilter("ressourcesFeaturesActive", ["==", "id", ""])
        removeContactsSelectFeatures(this.map)
      }
    }
    // END : Run actions on change active feature

    // Add contacts points layer
    if (prevProps.contacts !== contacts || prevProps.activeContact !== activeContact || prevProps.activeRessource !== activeRessource) {
      if (contacts) {
        displayContacts(this.map, contacts)
      }
    }
    // END : Add contacts points layer

    // Add ressources points layer
    if (prevProps.ressources !== ressources || prevProps.activeContact !== activeContact || prevProps.activeRessource !== activeRessource) {
      if (ressources) {
        displayRessources(this.map, ressources)
      }
    }
    // END : Add ressources points layer

    // Toggle view mode actions
    if (prevProps.contactViewMode !== contactViewMode) {
      if (contactViewMode) {
        this.enableContactViewMode()
      } else {
        this.disableContactViewMode()
      }
    }
    if (prevProps.ressourceViewMode !== ressourceViewMode) {
      if (ressourceViewMode) {
        this.enableRessourceViewMode()
      } else {
        this.disableRessourceViewMode()
      }
    }
    // END : Toggle view mode actions

    // Toggle update mode actions
    if (prevProps.contactUpdateMode !== contactUpdateMode) {
      if (contactUpdateMode) {
        this.enableContactUpdateMode()
      } else {
        this.disableContactUpdateMode()
      }
    }
    if (prevProps.ressourceUpdateMode !== ressourceUpdateMode) {
      if (ressourceUpdateMode) {
        this.enableRessourceUpdateMode()
      } else {
        this.disableRessourceUpdateMode()
      }
    }
    // END : Toggle update mode actions

    // Fly to active feature
    if (prevProps.flyToActiveFeature !== flyToActiveFeature) {
      this.flyToActiveFeature()
    }
    // END : Fly to active feature

    // Enable point draw mode
    // if (prevProps.newPointFeature !== newPointFeature) {
    //   if(!newPointFeature){
    //     this.enablePointDrawMode()
    //   }
    // }
    // END : Enable point draw mode

    // Enable point draw mode
    if (prevProps.contactPointDrawMode !== contactPointDrawMode || prevProps.ressourcePointDrawMode !== ressourcePointDrawMode) {
      if (contactPointDrawMode || ressourcePointDrawMode) {
        this.enablePointDrawMode()
      } else {
        this.disablePointDrawMode()
      }
    }
    // END : Enable point draw mode

    // Add new contact feature
    if (prevProps.contactNewMode !== contactNewMode) {
      if (contactNewMode) {
        this.enableContactNewMode()
      } else {
        this.disableContactNewMode()
      }
    }
    // END : Add new contact feature

    // Add new ressource feature
    if (prevProps.ressourceNewMode !== ressourceNewMode) {
      if (ressourceNewMode) {
        this.enableRessourceNewMode()
      } else {
        this.disableRessourceNewMode()
      }
    }
    // END : Add new ressource feature

    // Toggle alert view mode
    if (prevProps.alertViewMode !== alertViewMode || prevProps.activeRessource !== activeRessource) {
      if (alertViewMode && activeAlert && activeRessource) {
        enableAlertViewMode(this.map, activeRessource.lng, activeRessource.lat, activeAlert, contacts)
      } else {
        disableAlertViewMode(this.map)
      }
    }
    // END : Toggle alert view mode

    // Toggle alert new mode
    if (prevProps.alertNewMode !== alertNewMode || prevProps.activeRessource !== activeRessource) {
      if (alertNewMode && !activeAlert && activeRessource) {
        enableAlertNewMode(this.map, activeRessource.lng, activeRessource.lat, nextAlert, contacts)
      } else {
        disableAlertNewMode(this.map)
      }
    }
    // END : Toggle alert new mode

    // Toggle alert new mode
    if (prevProps.nextAlert !== nextAlert || prevProps.activeRessource !== activeRessource) {
      if (nextAlert && !activeAlert && activeRessource) {
        if (prevProps.nextAlert.method !== nextAlert.method) {
          removeContactsSelectFeatures(this.map)
        }
        if (nextAlert.method === "auto") {
          // this.removePolygonDraw()
          this.props.dispatch({ type: "TOGGLE_ALERT_POLYGON_DRAW_MODE", value: false })
          if (nextAlert.radius) {
            selectContactsByRadius(this.map, activeRessource.lng, activeRessource.lat, nextAlert.radius, this.props.contacts)
          }
        }
        if (nextAlert.method === "manual") {
          this.map.setFilter("ressourceCirclesLineActiveLayer", ["==", "radius", ""]) // <<<<<<<<<<<<<<<<<<<<<< Créer une fonction spécifique
          this.map.setFilter("ressourceCirclesFillActiveLayer", ["==", "radius", ""]) // <<<<<<<<<<<<<<<<<<<<<<
        }
      }
    }
    // END : Toggle alert new mode

    // Toggle alert polygon draw mode
    if (prevProps.alertPolygonDrawMode !== alertPolygonDrawMode) {
      if (alertPolygonDrawMode) {
        this.enableAlertPolygonDrawMode()
      } else {
        this.disableAlertPolygonDrawMode()
      }
    }
    // END : Toggle alert polygon draw mode

    // // Display alert contacts
    // if (prevProps.alertContacts !== alertContacts) {
    //   if (alertContacts) {
    //     Object.keys(alertContacts).forEach(key => {
    //       this.map.setFilter("contactsSelectFeaturesLayer", ["==", "radius", key])
    //     })
    //   } else {
    //     this.map.setFilter("contactsSelectFeaturesLayer", ["==", "radius", ""])
    //   }
    // }
    // // END : Display alert contacts
  }

  // ----------------------------------------------------------------------
  // METHODS
  // ----------------------------------------------------------------------

  flyToActiveFeature = () => {
    const { lng, lat } = this.props.flyToActiveFeature
    flyToPosition(this.map, lng, lat, 12)
  }

  // Function Geolocate user
  geolocateUser = () => {
    if (this.props.userGeolocation) {
      this.message("info", "Recherche de votre position...")
      navigator.geolocation.getCurrentPosition(
        position => {
          this.message("success", "Géolocalisation réussie !")

          if (position.coords) {
            const lng = position.coords.longitude
            const lat = position.coords.latitude

            // Display current user location on the map
            const userPositionFeature = turf.point([lng, lat])
            if (this.map.getLayer("userPositionLayer")) {
              this.map.removeLayer("userPositionLayer")
              this.map.removeSource("userPositionSource")
            }
            this.map.addSource("userPositionSource", {
              type: "geojson",
              data: userPositionFeature
            })
            this.map.addLayer({
              id: "userPositionLayer",
              type: "circle",
              source: "userPositionSource",
              paint: {
                "circle-radius": 6,
                "circle-color": colorBlue,
                "circle-stroke-width": 2,
                "circle-stroke-color": colorLight
              }
            })

            // Go to current user location
            flyToPosition(this.map, lng, lat, 12)

            // Disable geolocation after 30 sec
            setTimeout(() => {
              if (this.props.userGeolocation) {
                this.props.dispatch({ type: "TOGGLE_USER_GEOLOCATION", value: !this.props.userGeolocation })
              }
            }, 30000)
          }
        },
        error => {
          console.warn("Error f6299a8d-5196-4220-bd85-33220027c7b8", error)
          this.props.dispatch({ type: "TOGGLE_USER_GEOLOCATION", value: !this.props.userGeolocation })
          if (error.code === 1) {
            this.message("warning", "Le partage de votre position est bloqué")
          } else if (error.code === 3) {
            this.message("error", "Temps de réponse trop long...")
          } else {
            this.message("error", "Erreur : " + error.message)
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 20000,
          maximumAge: 0
        }
      )
    } else {
      this.message("info", "Géolocalisation désactivée...")

      if (this.map.getLayer("userPositionLayer")) {
        this.map.removeLayer("userPositionLayer")
        this.map.removeSource("userPositionSource")
      }
    }
  }
  // END : Function Geolocate user

  // Function Define popup
  initMapPopup = () => {
    return new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: {
        top: [0, 15],
        right: [-15, 0],
        bottom: [0, -15],
        left: [15, 0]
      }
    })
  }
  popup = this.initMapPopup()
  // END : Function Define popup

  // --- MOUSE ---------------------------------------------------------------------------------------

  // Functions Toggle mouse hover feature
  mouseEnterContactFeature = feature => {
    const layerId = feature.features[0].layer.id
    // console.log(feature.features[0])

    if (layerId === "contactsFeatures") {
      const id = feature.features[0].properties.id
      const contact = this.props.contacts[id]
      this.map.getCanvasContainer().style.cursor = "pointer"
      this.map.setFilter("contactsFeaturesHover", ["==", "id", id])
      this.popup
        .setLngLat([contact.lng, contact.lat])
        .setHTML(contact.firstname + " " + contact.lastname + "<br/>" + contact.title + "<br/>" + contact.location.city + "<br/>" + contact.activity)
        .addTo(this.map)
    }
    if (layerId === "contactsClusters") {
      const id = feature.features[0].properties.cluster_id
      this.map.getCanvasContainer().style.cursor = "pointer"
      this.map.setFilter("contactsClustersHover", ["==", "cluster_id", id])
    }
  }
  mouseLeaveContactFeature = () => {
    this.map.getCanvasContainer().style.cursor = ""
    this.map.setFilter("contactsFeaturesHover", ["==", "id", ""])
    this.map.setFilter("contactsClustersHover", ["==", "cluster_id", ""])
    this.popup.remove()
  }
  mouseEnterRessourceFeature = feature => {
    const layerId = feature.features[0].layer.id
    // console.log(feature.features[0])

    if (layerId === "ressourcesFeatures") {
      const id = feature.features[0].properties.id
      const ressource = this.props.ressources[id]
      this.map.getCanvasContainer().style.cursor = "pointer"
      this.map.setFilter("ressourcesFeaturesHover", ["==", "id", id])
      this.popup
        .setLngLat([ressource.lng, ressource.lat])
        .setHTML(
          `
          ${ressource.event ? (ressource.event.category ? ressource.event.category : ressource.event[0] + ", " + ressource.event[1]) : ""}
          <br/>
          ${ressource.species ? translation.ressource.species[ressource.species]["fr"] : ""}
          <br/>
          ${moment(ressource.date, ["YYYY-MM-DD"]).format("DD/MM/YYYY")}
          <br/>
          `
        )
        .addTo(this.map)
    }
    if (layerId === "ressourcesClusters") {
      const id = feature.features[0].properties.cluster_id
      this.map.getCanvasContainer().style.cursor = "pointer"
      this.map.setFilter("ressourcesClustersHover", ["==", "cluster_id", id])
    }
  }
  mouseLeaveRessourceFeature = () => {
    this.map.getCanvasContainer().style.cursor = ""
    this.map.setFilter("ressourcesFeaturesHover", ["==", "id", ""])
    this.map.setFilter("ressourcesClustersHover", ["==", "cluster_id", ""])
    this.popup.remove()
  }
  // END : Functions Mouse hover ressources feature

  // Functions Toggle click feature
  clickContactFeature = feature => {
    const id = feature.features[0].properties.id
    const contact = this.props.contacts[id]

    if (contact) {
      this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT_ID", value: id })
      this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: contact })
      this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE_ID", value: null })
      this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: null })
      setTimeout(() => {
        centerMapToPosition(this.map, contact.lng, contact.lat)
      }, 200)
      this.map.setFilter("contactsFeaturesActive", ["==", "id", id])
      this.map.setFilter("ressourcesFeaturesActive", ["==", "id", ""])
    }
  }
  clickRessourceFeature = feature => {
    const id = feature.features[0].properties.id
    const ressource = this.props.ressources[id]

    if (ressource) {
      this.props.dispatch({ type: "UPDATE_ACTIVE_ALERT", value: "" })
      const { alerts } = this.props
      if (alerts) {
        Object.keys(alerts).forEach(key => {
          if (alerts[key].ressource === id) {
            alerts[key].id = key
            this.props.dispatch({ type: "UPDATE_ACTIVE_ALERT", value: alerts[key] })
          }
        })
      }

      this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE_ID", value: id })
      this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: ressource })
      this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT_ID", value: null })
      this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: null })

      setTimeout(() => {
        centerMapToPosition(this.map, ressource.lng, ressource.lat)
      }, 200)
      this.map.setFilter("ressourcesFeaturesActive", ["==", "id", id])
      this.map.setFilter("contactsFeaturesActive", ["==", "id", ""])
    }
  }
  // END : Functions Toggle click feature

  // --- VIEW MODE ---------------------------------------------------------------------------------------

  // Functions Toggle view mode actions
  enableContactViewMode = () => {
    // console.info("Enable : View Mode (contact)")

    this.map.on("mouseenter", "contactsFeatures", this.mouseEnterContactFeature)
    this.map.on("mouseleave", "contactsFeatures", this.mouseLeaveContactFeature)
    this.map.on("click", "contactsFeatures", this.clickContactFeature)
    if (this.props.activeContactId) {
      if (this.map.getLayer("contactsFeaturesActive")) {
        this.map.setFilter("contactsFeaturesActive", ["==", "id", this.props.activeContactId])
      }
    }
    this.map.on("mouseenter", "contactsClusters", this.mouseEnterContactFeature)
    this.map.on("mouseleave", "contactsClusters", this.mouseLeaveContactFeature)
  }
  disableContactViewMode = () => {
    // console.warn("Disable : View Mode (contact)")

    this.map.off("mouseenter", "contactsFeatures", this.mouseEnterContactFeature)
    this.map.off("mouseleave", "contactsFeatures", this.mouseLeaveContactFeature)
    this.map.off("click", "contactsFeatures", this.clickContactFeature)
  }
  enableRessourceViewMode = () => {
    // console.info("Enable : View Mode (ressource)")

    this.map.on("mouseenter", "ressourcesFeatures", this.mouseEnterRessourceFeature)
    this.map.on("mouseleave", "ressourcesFeatures", this.mouseLeaveRessourceFeature)
    this.map.on("click", "ressourcesFeatures", this.clickRessourceFeature)
    if (this.props.activeRessourceId) {
      if (this.map.getLayer("ressourcesFeaturesActive") === null) {
        this.map.setFilter("ressourcesFeaturesActive", ["==", "id", this.props.activeRessourceId])
      }
    }
    this.map.on("mouseenter", "ressourcesClusters", this.mouseEnterRessourceFeature)
    this.map.on("mouseleave", "ressourcesClusters", this.mouseLeaveRessourceFeature)
  }
  disableRessourceViewMode = () => {
    // console.warn("Disable : View Mode (ressource)")

    this.map.off("mouseenter", "ressourcesFeatures", this.mouseEnterRessourceFeature)
    this.map.off("mouseleave", "ressourcesFeatures", this.mouseLeaveRessourceFeature)
    this.map.off("click", "ressourcesFeatures", this.clickRessourceFeature)
  }
  // END : Functions Toggle view mode actions

  // --- UPDATE MODE ---------------------------------------------------------------------------------------

  // Functions Toggle update mode actions
  enableContactUpdateMode = () => {
    // console.info("Enable : Update Mode (contact)")

    this.map.off("mouseenter", "contactsFeatures", this.mouseEnterContactFeature)
    this.map.off("mouseleave", "contactsFeatures", this.mouseLeaveContactFeature)
    this.map.off("click", "contactsFeatures", this.clickContactFeature)
    if (this.map.getLayer("contactsFeaturesActive")) {
      this.map.setFilter("contactsFeaturesActive", ["==", "id", this.props.activeContactId])
    }
  }
  disableContactUpdateMode = () => {
    // console.warn("Disable : Update Mode (contact)")

    this.map.on("mouseenter", "contactsFeatures", this.mouseEnterContactFeature)
    this.map.on("mouseleave", "contactsFeatures", this.mouseLeaveContactFeature)
    this.map.on("click", "contactsFeatures", this.clickContactFeature)
  }
  enableRessourceUpdateMode = () => {
    // console.info("Enable : Update Mode (ressource)")

    this.map.off("mouseenter", "ressourcesFeatures", this.mouseEnterRessourceFeature)
    this.map.off("mouseleave", "ressourcesFeatures", this.mouseLeaveRessourceFeature)
    this.map.off("click", "ressourcesFeatures", this.clickRessourceFeature)
    if (this.map.getLayer("ressourcesFeaturesActive")) {
      this.map.setFilter("ressourcesFeaturesActive", ["==", "id", this.props.activeRessourceId])
    }
  }
  disableRessourceUpdateMode = () => {
    // console.warn("Disable : Update Mode (ressource)")

    this.map.on("mouseenter", "ressourcesFeatures", this.mouseEnterRessourceFeature)
    this.map.on("mouseleave", "ressourcesFeatures", this.mouseLeaveRessourceFeature)
    this.map.on("click", "ressourcesFeatures", this.clickRessourceFeature)
  }
  // END : Functions Toggle update mode actions

  // --- NEW MODE ---------------------------------------------------------------------------------------

  // Functions Toggle features new mode
  enableContactNewMode = () => {
    // console.info("Enable : New Mode (contact)")

    const id = generateId("CT")
    const lng = this.props.newPointFeature.lng
    const lat = this.props.newPointFeature.lat
    const location = this.props.newPointFeature.location
    const area = this.props.newPointFeature.area
    const { user, userFirstName, userLastName, userEmail, userPhone } = this.props.userReducer
    const contact = {
      title: "",
      activity: "",
      firstname: userFirstName,
      lastname: userLastName,
      email: userEmail,
      phone: userPhone,
      lng,
      lat,
      location,
      area,
      author: user.uid
    }
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT_ID", value: id })
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: contact })
    this.map.getCanvasContainer().style.cursor = ""
    this.map.off("click", this.displayPointFeature)
  }
  enableRessourceNewMode = () => {
    console.info("Enable : New Mode (ressource)")

    const id = generateId("RS")
    const lng = this.props.newPointFeature.lng
    const lat = this.props.newPointFeature.lat
    const location = this.props.newPointFeature.location
    const area = this.props.newPointFeature.area
    const { user } = this.props.userReducer
    const ressource = {
      type: "",
      comment: "",
      status: "pending",
      date: dateNow(),
      time: timeNow(),
      lng,
      lat,
      location,
      area,
      author: user.uid
    }
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE_ID", value: id })
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: ressource })
    this.map.getCanvasContainer().style.cursor = ""
    this.map.off("click", this.displayPointFeature)
  }
  disableContactNewMode = () => {
    // console.warn("Disable : New Mode (contact)")
  }
  disableRessourceNewMode = () => {
    // console.warn("Disable : New Mode (ressource)")
  }
  // END : Functions Toggle features new mode

  // --- POINT DRAW ---------------------------------------------------------------------------------------

  getPlaceName = (lng, lat) => {
    const API_ENDPOINT =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      lng +
      "%2C" +
      lat +
      ".json?access_token=" +
      token +
      "&cachebuster=1546717821040&autocomplete=false&types=place&language=fr"

    return (
      fetch(API_ENDPOINT, {
        method: "GET"
      })
        .then(response => response.json())
        // .then(data => data.features[0].text)
        .catch(error => console.error(error))
    )
  }

  // Functions Display/Hide feature point layer
  hidePointFeature = () => {
    if (this.map.getLayer("pointFeatureLayer")) {
      this.map.removeLayer("pointFeatureLayer")
      this.map.removeSource("pointFeatureSource")
    }
    this.props.dispatch({ type: "UPDATE_NEW_POINT_FEATURE", value: false })
  }
  displayPointFeature = e => {
    const lng = e.lngLat.lng.toFixed(7)
    const lat = e.lngLat.lat.toFixed(7)

    this.getPlaceName(lng, lat)
      .then(data => {
        const location = {
          city: data.features[0].text,
          region: data.features[0].context[0].text,
          country: data.features[0].context[1].text
        }
        const area = {
          shortcode: data.features[0].context[0].short_code,
          name: data.features[0].context[0].text
          // country: data.features[0].context[1].text
        }
        console.log("data.features[0] : ", data.features[0])
        console.log("location : ", location)
        // console.log("area : ", area)

        this.props.dispatch({ type: "UPDATE_NEW_POINT_FEATURE", value: { lng, lat, location, area: area.shortcode } })

        return area
      })
      .then(area => {
        // Disable drawing outside of area
        if (
          (this.props.userReducer.userAreas && !this.props.userReducer.userAreas.includes(area.shortcode)) ||
          this.props.userReducer.userAreas === undefined
        ) {
          console.log("area : ", area)

          // Test if area exist in database
          if (Object.keys(areasName).includes(area.shortcode)) {
            console.log("area exist !")
            area.status = "available"
          } else {
            console.log("area not exist...")
            area.status = "unavailable"
          }
          if (area.status) {
            this.props.dispatch({ type: "UPDATE_CURRENT_AREA", value: area })
          }

          this.hidePointFeature()
          this.message("warning", "Impossible de créer un point hors de votre territoire")
          this.props.dispatch({ type: "OPEN_AREA_DIALOG", value: true })
        }
      })

    const pointFeature = turf.point([lng, lat])

    this.hidePointFeature()

    this.map.addSource("pointFeatureSource", {
      type: "geojson",
      data: pointFeature
    })
    this.map.addLayer({
      id: "pointFeatureLayer",
      type: "circle",
      source: "pointFeatureSource",
      paint: {
        "circle-radius": 14,
        "circle-color": colorBlue,
        "circle-stroke-width": 2,
        "circle-stroke-color": colorLight
      }
    })
  }
  // END : Functions Display/Hide feature point layer

  // Functions Display/Hide temporary point feature
  enablePointDrawMode = () => {
    console.info("Enable : Draw Mode (point)")

    this.disableContactViewMode()
    this.disableRessourceViewMode()

    this.map.getCanvasContainer().style.cursor = "crosshair"
    this.map.on("click", this.displayPointFeature)
  }
  disablePointDrawMode = () => {
    // console.warn("Disable : Draw Mode (point)")

    this.enableContactViewMode()
    this.enableRessourceViewMode()

    this.map.getCanvasContainer().style.cursor = ""
    this.map.off("click", this.displayPointFeature)

    this.hidePointFeature()
  }
  // END : Functions Display/Hide temporary point feature

  // --- POLYGON DRAW ---------------------------------------------------------------------------------------

  // Functions Display/Hide feature polygon layer
  removePolygonDraw = () => {
    if (this.map.getLayer("polygonDrawPointsLayer")) {
      this.map.removeLayer("polygonDrawPointsLayer")
      this.map.removeSource("polygonDrawPointsSource")
    }
    if (this.map.getLayer("polygonDrawLinesLayer")) {
      this.map.removeLayer("polygonDrawLinesLayer")
      this.map.removeLayer("polygonDrawFillLayer")
      this.map.removeSource("polygonDrawSource")
    }

    // this.props.dispatch({ type: "UPDATE_NEW_POINT_FEATURE", value: false })
  }

  drawOnClick = e => {
    let polygonDrawPoints = { ...this.state.polygonDrawPoints }
    let polygonDrawPointsArray = [...this.state.polygonDrawPointsArray]
    let polygonDrawFeature = { ...this.state.polygonDrawFeature }

    const lng = Number(e.lngLat.lng.toFixed(7))
    const lat = Number(e.lngLat.lat.toFixed(7))

    const pointFeature = turf.point([lng, lat])
    polygonDrawPoints.features.push(pointFeature)
    this.map.getSource("polygonDrawPointsSource").setData(polygonDrawPoints)

    polygonDrawPointsArray.push([lng, lat])
    this.setState({
      polygonDrawPointsArray
    })

    let nextAlert = { ...this.props.nextAlert }
    nextAlert.polygon = polygonDrawPointsArray
    this.props.dispatch({ type: "UPDATE_NEXT_ALERT", value: nextAlert })

    const structurePolygonPointsArray = [].concat(polygonDrawPointsArray)
    structurePolygonPointsArray.push(polygonDrawPointsArray[0])

    if (structurePolygonPointsArray.length === 3) {
      polygonDrawFeature = turf.lineString(polygonDrawPointsArray)
      this.map.getSource("polygonDrawSource").setData(polygonDrawFeature)
    }

    if (structurePolygonPointsArray.length > 3) {
      polygonDrawFeature = turf.polygon([structurePolygonPointsArray])
      this.map.getSource("polygonDrawSource").setData(polygonDrawFeature)

      const contactsJson = this.map.getSource("contactsFeaturesSource")._data
      const contactsSelectJson = turf.pointsWithinPolygon(contactsJson, polygonDrawFeature)

      const ressourceLng = this.props.activeRessource.lng
      const ressourceLat = this.props.activeRessource.lat

      contactsSelectJson.features.forEach(contactFeature => {
        const distance = turf.distance([ressourceLng, ressourceLat], contactFeature.geometry.coordinates, {
          units: "kilometers"
        })
        contactFeature.properties.distance = Number(distance.toFixed(1))
      })

      removeContactsSelectFeatures(this.map)

      this.map.addSource("contactsSelectFeaturesSource", {
        type: "geojson",
        data: contactsSelectJson
      })

      this.map.addLayer({
        id: "contactsSelectFeaturesLayer",
        type: "circle",
        source: "contactsSelectFeaturesSource",
        paint: {
          "circle-radius": 10,
          "circle-color": colorYellow,
          "circle-stroke-width": 2,
          "circle-stroke-color": colorDark
        }
      })

      contactsSelectJson.features = contactsSelectJson.features.sort((feature1, feature2) => {
        return feature1.properties.distance - feature2.properties.distance
      })

      const alertContacts = {}
      contactsSelectJson.features.forEach(contact => {
        const { id, distance } = contact.properties
        alertContacts[id] = this.props.contacts[id]
        alertContacts[id].distance = distance
      })
      this.props.dispatch({ type: "UPDATE_ALERT_CONTACTS", value: alertContacts })
    }
  }
  // END : Functions Display/Hide feature polygon layer

  // Functions Toggle alert polygon draw mode
  enableAlertPolygonDrawMode = () => {
    console.info("Enable : Draw Mode (polygon)")

    this.setState({
      polygonDrawPoints: turf.featureCollection([]),
      polygonDrawPointsArray: [],
      polygonDrawFeature: turf.feature("")
    })
    const polygonDrawPoints = turf.featureCollection([])
    const polygonDrawFeature = turf.feature("")

    this.disableContactViewMode()
    this.disableRessourceViewMode()

    this.map.getCanvasContainer().style.cursor = "crosshair"
    this.map.on("click", this.drawOnClick)

    removeContactsSelectFeatures(this.map)
    this.removePolygonDraw()

    this.map.addSource("polygonDrawPointsSource", {
      type: "geojson",
      data: polygonDrawPoints
    })
    this.map.addLayer({
      id: "polygonDrawPointsLayer",
      type: "circle",
      source: "polygonDrawPointsSource",
      paint: {
        "circle-radius": 4,
        "circle-color": colorBlue,
        "circle-stroke-width": 2,
        "circle-stroke-color": colorLight
      }
    })

    this.map.addSource("polygonDrawSource", {
      type: "geojson",
      data: polygonDrawFeature
    })
    this.map.addLayer({
      id: "polygonDrawLinesLayer",
      type: "line",
      source: "polygonDrawSource",
      paint: {
        "line-width": 2,
        "line-color": colorBlue,
        "line-dasharray": [1, 1.5]
      }
    })
    this.map.addLayer({
      id: "polygonDrawFillLayer",
      type: "fill",
      source: "polygonDrawSource",
      paint: {
        "fill-color": colorBlue,
        "fill-outline-color": colorBlue,
        "fill-opacity": 0.1
      }
    })

    // scrollToTop();
  }

  disableAlertPolygonDrawMode = () => {
    // console.info("Disable : Draw Mode (polygon)")

    this.enableContactViewMode()
    this.enableRessourceViewMode()

    this.map.getCanvasContainer().style.cursor = ""

    // if (!isTouchDevice()) {
    //   enableMousehoverContactPoint();
    //   enableMousehoverRessourcePoint();
    // };

    removeContactsSelectFeatures(this.map)
    this.removePolygonDraw()

    this.map.off("click", this.drawOnClick)
    this.map.on("click", "contactsFeatures", this.clickContactFeature)
    this.map.on("click", "ressourcesFeatures", this.clickRessourceFeature)

    // warningMessage(cancelMessage);
  }
  // END : Functions Toggle alert polygon draw mode

  // ----------------------------------------------------------------------
  // render()
  // ----------------------------------------------------------------------

  render() {
    const mapCSS = {
      width: "100%",
      height: "100%",
      flexGrow: 1
    }
    return <div style={mapCSS} ref={element => (this.mapContainer = element)} />
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    alerts: state.dataReducer.alerts,
    ressources: state.dataReducer.ressources,
    contacts: state.dataReducer.contacts,

    toggleDrawerMenu: state.appReducer.toggleDrawerMenu,

    baseMapStyle: state.mapReducer.baseMapStyle,
    elevationShadows: state.mapReducer.elevationShadows,
    is3dView: state.mapReducer.is3dView,
    zoomDirection: state.mapReducer.zoomDirection,
    userGeolocation: state.mapReducer.userGeolocation,
    contactViewMode: state.mapReducer.contactViewMode,
    contactUpdateMode: state.mapReducer.contactUpdateMode,
    contactNewMode: state.mapReducer.contactNewMode,
    ressourceViewMode: state.mapReducer.ressourceViewMode,
    ressourceUpdateMode: state.mapReducer.ressourceUpdateMode,
    ressourceNewMode: state.mapReducer.ressourceNewMode,
    activeContact: state.mapReducer.activeContact,
    activeRessource: state.mapReducer.activeRessource,
    activeContactId: state.mapReducer.activeContactId,
    activeRessourceId: state.mapReducer.activeRessourceId,
    newPointFeature: state.mapReducer.newPointFeature,
    contactPointDrawMode: state.mapReducer.contactPointDrawMode,
    ressourcePointDrawMode: state.mapReducer.ressourcePointDrawMode,
    flyToActiveFeature: state.mapReducer.flyToActiveFeature,
    alertNewMode: state.mapReducer.alertNewMode,
    alertViewMode: state.mapReducer.alertViewMode,
    activeAlert: state.mapReducer.activeAlert,
    nextAlert: state.mapReducer.nextAlert,
    alertPolygonDrawMode: state.mapReducer.alertPolygonDrawMode,
    alertContacts: state.mapReducer.alertContacts
  }
}

export default connect(mapStateToProps)(withSnackbar(MapCanvas))
