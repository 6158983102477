const initialState = {
  user: "",
  userGroup: "",
  userFirstName: "",
  userLastName: "",
  userEmail: "",
  userPassword: "",
  nextUserFirstName: "",
  nextUserLastName: "",
  nextUserEmail: "",
  nextUserPassword: ""
}

function userReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    // USER

    case "UPDATE_USER":
      nextState = {
        ...state,
        user: action.value
      }
      return nextState || state

    case "UPDATE_USER_AREAS_TEMP":
      nextState = {
        ...state,
        userAreasTemp: action.value
      }
      return nextState || state

    // USER DATA

    case "UPDATE_USER_GROUP":
      nextState = {
        ...state,
        userGroup: action.value
      }
      return nextState || state

    case "UPDATE_USER_FIRST_NAME":
      nextState = {
        ...state,
        userFirstName: action.value
      }
      return nextState || state

    case "UPDATE_USER_LAST_NAME":
      nextState = {
        ...state,
        userLastName: action.value
      }
      return nextState || state

    case "UPDATE_USER_EMAIL":
      nextState = {
        ...state,
        userEmail: action.value
      }
      return nextState || state

    case "UPDATE_USER_PASSWORD":
      nextState = {
        ...state,
        userPassword: action.value
      }
      return nextState || state

    case "UPDATE_USER_PHONE":
      nextState = {
        ...state,
        userPhone: action.value
      }
      return nextState || state
    case "UPDATE_USER_DESCRIPTION":
      nextState = {
        ...state,
        userDescription: action.value
      }
      return nextState || state
    case "UPDATE_USER_AREAS":
      nextState = {
        ...state,
        userAreas: action.value
      }
      return nextState || state

    // NEXT USER DATA

    case "UPDATE_NEXT_USER_FIRST_NAME":
      nextState = {
        ...state,
        nextUserFirstName: action.value
      }
      return nextState || state

    case "UPDATE_NEXT_USER_LAST_NAME":
      nextState = {
        ...state,
        nextUserLastName: action.value
      }
      return nextState || state

    case "UPDATE_NEXT_USER_EMAIL":
      nextState = {
        ...state,
        nextUserEmail: action.value
      }
      return nextState || state

    case "UPDATE_NEXT_USER_PASSWORD":
      nextState = {
        ...state,
        nextUserPassword: action.value
      }
      return nextState || state

    case "UPDATE_NEXT_USER_PHONE":
      nextState = {
        ...state,
        nextUserPhone: action.value
      }
      return nextState || state

    case "UPDATE_NEXT_USER_DESCRIPTION":
      nextState = {
        ...state,
        nextUserDescription: action.value
      }
      return nextState || state

    case "UPDATE_NEXT_USER_AREAS":
      nextState = {
        ...state,
        nextUserAreas: action.value
      }
      return nextState || state

    // ELSE
    default:
      return state
  }
}

export default userReducer
