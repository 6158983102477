import React from "react"
import classNames from "classnames"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  root: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.dark
    }
  }
})

function Link(props) {
  const { classes, children, className, ...other } = props

  return (
    <a className={classNames(classes.root, className)} {...other}>
      {children || "class names"}
    </a>
  )
}

export default withStyles(styles)(Link)
