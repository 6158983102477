import React from "react"

import { SvgIcon } from "@material-ui/core"

export default props => (
  <SvgIcon viewBox="0 0 428.22 423.53" {...props}>
    <path
      d="M307.65,466.82H36.39c.88-10.28,1.14-20.39,2.71-30.3,4.46-28.14,15.86-52.78,38.23-71.41C92,352.92,109,345.7,127.42,341.53a8.33,8.33,0,0,1,5.08,1q39.51,20.31,79,.05a9.62,9.62,0,0,1,6-.78c48.95,11.05,80.73,45.73,87.54,95.67C306.34,447,306.77,456.62,307.65,466.82ZM134.31,392.24c-10.34,6.29-20.16,12.19-29.89,18.22-2.74,1.7-3.32,6.9-.8,8.5,10.07,6.37,20.31,12.47,30.7,18.79,1.54-2.39,2.75-4.29,4.17-6.51L112.1,415l26.43-16.25Zm72.19,6.46,26.37,16.36-26.41,16.18,4.25,6.52c10.34-6.31,20.3-12.28,30.15-18.44,2.69-1.67,2.86-6.83.23-8.48-10-6.26-20.06-12.31-30.39-18.61Zm-46.71,41.94,32-46.8-6.68-4.37-32.08,46.84Z"
      transform="translate(-36.39 -43.28)"
    />
    <path
      d="M171.84,343.09C131.55,343,99,310.15,99.12,269.64a73.1,73.1,0,0,1,73-72.64c40,0,72.78,32.93,72.72,73A73,73,0,0,1,171.84,343.09Z"
      transform="translate(-36.39 -43.28)"
    />
    <path
      d="M447.31,186.37l-18.63,18.7c-3.87-3.21-8.24-6.54-12.24-10.26-2.67-2.49-4.74-3.07-8.17-1a50.4,50.4,0,0,1-13.3,5.56c-4.1,1-5.14,3-5.26,6.87-.15,5.29-.94,10.56-1.48,16.16H362.06c-.61-6-1.37-12.06-1.76-18.13-.17-2.59-1.12-3.6-3.58-4.44a103.87,103.87,0,0,1-15.63-6.51c-2.83-1.51-4.4-1-6.48.87-4.37,3.82-8.9,7.46-13.06,10.92L303,186.32c3.22-3.88,6.94-8.51,10.85-13,1.82-2.09,2.36-3.64.84-6.47a100.71,100.71,0,0,1-6.48-15.63c-.84-2.51-1.89-3.4-4.45-3.57-6-.41-11.91-1.15-18.14-1.78V119.77c5.75-.55,11.5-1.25,17.27-1.6,3-.18,4.53-1,5.51-4.3,1.62-5.37,4.42-10.38,6.36-15.68a6.21,6.21,0,0,0-.5-5.06c-3.66-4.91-7.73-9.51-11.37-13.89l18.58-18.71c4.33,3.59,9.13,7.41,13.72,11.46,1.93,1.71,3.3,1.69,5.62.53A125.49,125.49,0,0,1,356.39,66c2.6-.88,3.78-1.82,3.94-4.7.31-6,1.09-11.88,1.71-18h26.17c.55,5.78,1.28,11.53,1.59,17.3.16,3.08,1.14,4.51,4.29,5.53,6.29,2,12.34,4.84,18.94,7.51L429,60.42c6.14,6.24,12.07,12.25,18.47,18.74-4.22,5.1-8.83,10.69-12.94,15.67,2.77,7.2,5.2,13.91,8,20.45a5.66,5.66,0,0,0,4,2.65c5.92.8,11.89,1.2,18.15,1.77v26.16c-5.76.6-11.64,1.39-17.54,1.76-2.92.18-4.23,1.12-5.15,4a90.89,90.89,0,0,1-6.29,15.16c-1.46,2.74-1.19,4.3.73,6.45C440.23,177.61,443.83,182.18,447.31,186.37ZM375,98.84a34,34,0,1,0,34.14,33.74A34,34,0,0,0,375,98.84Z"
      transform="translate(-36.39 -43.28)"
    />
  </SvgIcon>
)
