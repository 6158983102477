import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Card } from "@material-ui/core"
import { CardContent } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
import { Hidden } from "@material-ui/core"
import { List } from "@material-ui/core"
import { ListItem } from "@material-ui/core"
import { ListItemSecondaryAction } from "@material-ui/core"
import { ListItemText } from "@material-ui/core"
import { Fade } from "@material-ui/core"

import TitleIcon from "@material-ui/icons/PersonPinCircle"
import ContactIcon from "@material-ui/icons/PersonPin"
import MapIcon from "@material-ui/icons/Map"

const colorGreen = "#558b2f"

const styles = theme => ({
  root: {
    overflowX: "auto",
    padding: theme.spacing.unit * 2,
    // backgroundColor: theme.palette.grey[100],
    backgroundColor: colorGreen,
    color: theme.palette.common.white,
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  }
})

class Contacts extends Component {

  handleFlyToContactPosition = (lng, lat, contactId) => {
    const contact = this.props.contacts[contactId]
    // Redirect to map
    this.props.history.push("/")

    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT_ID", value: contactId })
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: contact })
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE_ID", value: null })
    this.props.dispatch({ type: "UPDATE_ACTIVE_RESSOURCE", value: null })

    setTimeout(() => {
      // Save to Redux global state
      this.props.dispatch({ type: "TOGGLE_FLY_TO_ACTIVE_FEATURE", value: { lng, lat } })
    }, 500)
  }

  render() {
    const { classes, contacts } = this.props

    return (
      <Fade in={true}>
        <div className={classes.root}>
          <Grid container direction="row" justify="flex-start" alignItems="center" style={{ margin: "8px", marginBottom: "24px" }}>
            <TitleIcon fontSize="large" style={{ marginRight: "16px" }} color="inherit" />
            <Grid item xs zeroMinWidth>
              <Typography variant="h5" noWrap color="inherit">
                Troupeaux - Contacts
              </Typography>
            </Grid>
          </Grid>

          <Card style={{ borderRadius: "0" }}>
            <CardContent style={{ padding: "0" }}>
              <List dense>
                {contacts &&
                  Object.keys(contacts).map(key => {
                    const contactId = key
                    const contact = contacts[contactId]
                    return (
                      <div key={contactId}>
                        <ListItem>
                          <Hidden xsDown>
                            <ContactIcon fontSize="large" color="action" />
                          </Hidden>
                          <ListItemText
                            primary={contact.firstname + " " + contact.lastname + " ∙ ∙ ∙ " + contact.title + " ∙ ∙ ∙ " + contact.location.city}
                            secondary={contact.phone + " ∙ ∙ ∙ " + contact.email}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title="Situer sur la carte" placement="left" TransitionComponent={Zoom} disableFocusListener>
                              <IconButton onClick={() => this.handleFlyToContactPosition(contact.lng, contact.lat, contactId)}>
                                <MapIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </div>
                    )
                  })}
              </List>
            </CardContent>
          </Card>
        </div>
      </Fade>
    )
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.dataReducer.contacts
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Contacts))
