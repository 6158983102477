import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import { Typography } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import { Zoom } from "@material-ui/core"
import { FormControl } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import { InputLabel } from "@material-ui/core"
import { Select } from "@material-ui/core"
import { MenuItem } from "@material-ui/core"
import { Grow } from "@material-ui/core"
import { Dialog } from "@material-ui/core"
import { DialogActions } from "@material-ui/core"
import { DialogContent } from "@material-ui/core"
import { DialogTitle } from "@material-ui/core"
import { withMobileDialog } from "@material-ui/core"
import { ExpansionPanel } from "@material-ui/core"
import { ExpansionPanelDetails } from "@material-ui/core"
import { ExpansionPanelSummary } from "@material-ui/core"
import { ExpansionPanelActions } from "@material-ui/core"

import CloseIcon from "@material-ui/icons/Close"
import EditIcon from "@material-ui/icons/Edit"
import CancelIcon from "@material-ui/icons/Cancel"
import SaveIcon from "@material-ui/icons/Save"
import DeleteIcon from "@material-ui/icons/Delete"
import MapIcon from "@material-ui/icons/Map"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { PhoneFormatter, phoneFormatRegex, phoneHelperText } from "../components/PhoneNumber"

import firebase from "../config/firebase"
import { translation } from "../config/languages.json"

import { withSnackbar } from "notistack"

import { message } from "../functions/appInterface"
import { sendEmail } from "../functions/apiLambda"

const styles = theme => ({
  panelDetails: {
    minWidth: 150,
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  deleteButton: {
    color: theme.palette.error.main
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20
  },
  capitalize: {
    textTransform: "capitalize"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  lowercase: {
    textTransform: "lowercase"
  }
})

class ContactContainer extends Component {
  state = {
    isUpdating: false,
    switchActive: true,
    openDeleteFeatureDialog: false,
    expanded: "contact"
  }

  // Define alert message context
  message = message
  // END : Define alert message context

  componentDidMount() {
    if (this.props.contactNewMode) {
      this.setState({ isUpdating: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeContactId !== this.props.activeContactId) {
      this.setState(state => ({ switchActive: !state.switchActive }))
      setTimeout(() => {
        this.setState(state => ({ switchActive: !state.switchActive }))
      }, 500)
    }
  }

  closeContainer = () => {
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: null })
  }

  // Refresh active fields
  refreshTitle(e) {
    const nextContact = Object.assign({}, this.props.activeContact)
    nextContact.title = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: nextContact })
    this.setState({ errorTitle: false })
  }
  refreshActivity(e) {
    const nextContact = Object.assign({}, this.props.activeContact)
    nextContact.activity = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: nextContact })
    this.setState({ errorActivity: false })
  }
  refreshFirstName(e) {
    const nextContact = Object.assign({}, this.props.activeContact)
    nextContact.firstname = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: nextContact })
    this.setState({ errorFirstName: false })
  }
  refreshLastName(e) {
    const nextContact = Object.assign({}, this.props.activeContact)
    nextContact.lastname = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: nextContact })
    this.setState({ errorContacLastName: false })
  }
  refreshEmail(e) {
    const nextContact = Object.assign({}, this.props.activeContact)
    nextContact.email = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: nextContact })
    this.setState({ errorEmail: false })
  }
  refreshPhone(e) {
    const nextContact = Object.assign({}, this.props.activeContact)
    nextContact.phone = e.target.value
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: nextContact })
    // Respect phone format
    if (!phoneFormatRegex.test(e.target.value)) {
      this.setState({ errorPhone: true })
    } else {
      this.setState({ errorPhone: false })
    }
  }
  // END : Refresh active fields

  handleDeleteFeature = () => {
    const { contacts, activeContactId, joins, alerts } = this.props

    // Update Firebase
    firebase
      .database()
      .ref("/contacts/" + activeContactId)
      .remove(error => {
        if (error) {
          // Write failed...
          console.error("Error 15b97ef9-8aee-475e-83fa-8c5a6ab0e7ce", error)
          this.message("error", "Erreur : " + error.message)
        } else {
          // Write success...
          this.message("success", "Base de données mise à jour !")

          delete contacts[activeContactId]
          this.setState({ openDeleteFeatureDialog: false })
          // Save to Redux global state
          this.props.dispatch({ type: "UPDATE_DATA_CONTACTS", value: contacts })
          this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: null })

          this.props.dispatch({ type: "TOGGLE_CONTACT_UPDATE_MODE", value: false })
          this.props.dispatch({ type: "TOGGLE_RESSOURCE_UPDATE_MODE", value: false })
          this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
          this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })

          // Update JOINS
          var contactRegex = new RegExp(activeContactId)
          const contactJoins = Object.keys(joins).filter(key => contactRegex.test(key))

          if (contactJoins.length > 0) {
            contactJoins.forEach(key => {
              // Update ALERTS (if no more join)
              const alertId = joins[key].alert
              const alertRegex = new RegExp(alertId)
              const alertJoins = Object.keys(joins).filter(key => alertRegex.test(key))

              if (alertJoins.length === 1) {
                delete alerts[alertId]
                // Save to Redux global state
                this.props.dispatch({ type: "UPDATE_DATA_ALERTS", value: alerts })
                // Update Firebase
                firebase
                  .database()
                  .ref("/alerts/" + alertId)
                  .remove(error => {
                    if (error) {
                      // Write failed...
                      console.error("Error 35b7fa52-844f-4300-9602-094e2fe52b7f", error)
                      this.message("error", "Erreur : " + error.message)
                    }
                  })
              }

              delete joins[key]
              // Save to Redux global state
              this.props.dispatch({ type: "UPDATE_DATA_JOINS", value: joins })

              // Update Firebase
              firebase
                .database()
                .ref("/joins/" + key)
                .remove(error => {
                  if (error) {
                    // Write failed...
                    console.error("Error dd9dcba9-adb5-4f59-a81d-ab49c9708f04", error)
                    this.message("error", "Erreur : " + error.message)
                  }
                })
            })
          }
        }
      })
  }

  saveUpdate = () => {
    const { contacts, activeContact, activeContactId } = this.props

    if (!phoneFormatRegex.test(activeContact.phone)) {
      this.message("warning", "Numéro de mobile non valide")
      this.setState({ errorPhone: true })
      this.setState({ isUpdating: true })
    } else {
      if (activeContact.title && activeContact.activity && activeContact.firstname && activeContact.lastname && activeContact.email && activeContact.phone) {
        this.setState(state => ({ isUpdating: !state.isUpdating }))

        if (activeContact === contacts[activeContactId]) {
          // Need no update
          this.message("info", "Aucune modification n'a été effectuée...")
          this.setState({ isUpdating: true })
        } else {
          // Update Firebase
          firebase
            .database()
            .ref("/contacts/" + activeContactId)
            .update(activeContact, error => {
              if (error) {
                // Write failed...
                console.error("Error 7a628bad-33ee-4d8c-b20c-25add8ea1c77", error)
                this.message("error", "Erreur : " + error.message)
              } else {
                // Write success...
                this.message("success", "Base de données mise à jour !")
                // Save to Redux global state
                const nextContacts = Object.assign({}, contacts)
                nextContacts[activeContactId] = activeContact
                this.props.dispatch({ type: "UPDATE_DATA_CONTACTS", value: nextContacts })

                this.props.dispatch({ type: "TOGGLE_CONTACT_UPDATE_MODE", value: false })
                this.props.dispatch({ type: "TOGGLE_RESSOURCE_UPDATE_MODE", value: false })
                this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
                this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })
              }
            })
        }
      } else {
        this.message("warning", "L'un des champs est vide...")
        if (!activeContact.title) {
          this.setState({ errorTitle: true })
        }
        if (!activeContact.activity) {
          this.setState({ errorActivity: true })
        }
        if (!activeContact.firstname) {
          this.setState({ errorFirstName: true })
        }
        if (!activeContact.lastname) {
          this.setState({ errorLastName: true })
        }
        if (!activeContact.email) {
          this.setState({ errorEmail: true })
        }
        if (!activeContact.phone) {
          this.setState({ errorPhone: true })
        }
      }
    }
  }

  saveNew = () => {
    const { activeContact, activeContactId, userFirstName, userLastName, userEmail, userPhone, userGroup } = this.props

    if (!phoneFormatRegex.test(activeContact.phone)) {
      this.message("warning", "Numéro de mobile non valide")
      this.setState({ errorPhone: true })
      this.setState({ isUpdating: true })
    } else {
      if (activeContact.title && activeContact.activity && activeContact.firstname && activeContact.lastname && activeContact.email && activeContact.phone) {
        this.setState(state => ({ isUpdating: !state.isUpdating }))

        // Update Firebase
        firebase
          .database()
          .ref("/contacts/" + activeContactId)
          .update(activeContact, error => {
            if (error) {
              // Write failed...
              console.error("Error c70cd533-5847-42ef-a60d-074df069b830", error)
              this.message("error", "Erreur : " + error.message)
            } else {
              // Write success...
              this.message("success", "Base de données mise à jour !")
              // Save to Redux global state
              // const nextContacts = Object.assign({}, contacts)
              // nextContacts[activeContactId] = activeContact
              // this.props.dispatch({ type: "UPDATE_DATA_CONTACTS", value: nextContacts })
              this.props.dispatch({ type: "TOGGLE_CONTACT_POINT_DRAW_MODE", value: false })
              this.props.dispatch({ type: "TOGGLE_CONTACT_NEW_MODE", value: false })
              this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })

              // Send notification to area manager and admin
              if (!(new RegExp("@proxiloup.com").test(userEmail) || userEmail === "lyon.gus@gmail.com")) {
                firebase
                  .database()
                  .ref("/users/")
                  .once("value")
                  .then(snapshot => {
                    const users = snapshot.val()

                    const recipients = Object.keys(users)
                      .filter(key => users[key].areas && users[key].areas.includes(activeContact.area))
                      .filter(key => users[key].group && ["inspector", "manager", "admin"].includes(users[key].group))
                      .reduce((obj, key) => {
                        obj[key] = users[key]
                        return obj
                      }, {})

                    return recipients
                  })
                  .then(recipients => {
                    Object.keys(recipients).forEach(recipient => {
                      const userName = userFirstName + " " + userLastName
                      const contactName = activeContact.firstname + " " + activeContact.lastname

                      const mailFrom = `"Proxiloup.com" <contact@proxiloup.com>` // verified AWS email
                      const mailReplyTo = ``
                      const mailTo = recipients[recipient].email
                      const mailSubject = userName + " a créé un troupeau"
                      const mailBody = `
                      <html>
                        <head>
                        <title>${mailSubject}</title>
                        </head>
                        <body>
                          <p>Un nouveau troupeau a été créé sur votre territoire.</p>
                          <p>
                            TROUPEAU<br />
                            <b>- Lieu-dit / Titre : </b>${activeContact.title}<br />
                            <b>- Commune : </b>${activeContact.location.city}<br />
                            <b>- Type d'activité : </b>${activeContact.activity}<br />
                          </p>
                          <p>
                            CONTACT<br />
                            <b>- Nom : </b>${contactName}<br />
                            <b>- Email : </b>${activeContact.email}<br />
                            <b>- Phone : </b>${activeContact.phone}<br />
                          </p>
                          <p>
                            AUTEUR<br />
                            <b>- Nom : </b>${userName}<br />
                            <b>- Email : </b>${userEmail}<br />
                            <b>- Phone : </b>${userPhone}<br />
                            <b>- Groupe : </b>${translation.users.groups[userGroup]["fr"]}<br />
                          </p>
                          <p>
                          --
                          </p>
                          <p><em>PS : Ceci est un message automatique envoyé via l'application Proxiloup.com</em></p>
                        </body>
                      </html>
                    `

                      sendEmail(mailFrom, mailReplyTo, mailTo, mailSubject, mailBody).then(response => {
                        // console.log("response : ", response)
                        if (response.status === 200) {
                          // SUCCESS
                          this.setState({ isLoading: false, submit: "success" })
                        } else {
                          // ERROR
                          this.setState({ isLoading: false, submit: "error" })
                        }
                        return response.json()
                      })
                    })
                  })
              }
              // END OF Send notification to area manager and admin
            }
          })
      } else {
        this.message("warning", "L'un des champs est vide...")
        if (!activeContact.title) {
          this.setState({ errorTitle: true })
        }
        if (!activeContact.activity) {
          this.setState({ errorActivity: true })
        }
        if (!activeContact.firstname) {
          this.setState({ errorFirstName: true })
        }
        if (!activeContact.lastname) {
          this.setState({ errorLastName: true })
        }
        if (!activeContact.email) {
          this.setState({ errorEmail: true })
        }
        if (!activeContact.phone) {
          this.setState({ errorPhone: true })
        }
      }
    }
  }

  // Functions Toggle update modes
  enableUpdateMode = () => {
    this.setState({ isUpdating: true })
    this.setState({
      errorTitle: false,
      errorActivity: false,
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorPhone: false
    })
    this.props.dispatch({ type: "TOGGLE_CONTACT_UPDATE_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_UPDATE_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: false })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: false })
  }
  disableUpdateMode = () => {
    this.message("warning", "Opération annulée...")

    this.setState({ isUpdating: false })
    this.setState({
      errorTitle: false,
      errorActivity: false,
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorPhone: false
    })

    // Restore original data
    const { contacts, activeContactId } = this.props
    const contact = contacts[activeContactId]
    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: contact })
    // END : Restore original data

    this.props.dispatch({ type: "TOGGLE_CONTACT_UPDATE_MODE", value: false })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_UPDATE_MODE", value: false })
    this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })
  }
  // END : Functions Toggle update modes

  cancelNew = () => {
    if (this.state.isUpdating) {
      this.message("warning", "Opération annulée...")
    }
    this.setState(state => ({ isUpdating: !state.isUpdating }))
    this.setState({
      errorTitle: false,
      errorActivity: false,
      errorFirstName: false,
      errorLastName: false,
      errorEmail: false,
      errorPhone: false
    })
    // Save to Redux global state
    this.props.dispatch({ type: "UPDATE_ACTIVE_CONTACT", value: null })
    this.props.dispatch({ type: "TOGGLE_CONTACT_VIEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_RESSOURCE_VIEW_MODE", value: true })
    this.props.dispatch({ type: "TOGGLE_CONTACT_POINT_DRAW_MODE", value: false })
    this.props.dispatch({ type: "TOGGLE_CONTACT_NEW_MODE", value: false })
  }

  handleCloseDialog = () => {
    this.setState({ openDeleteFeatureDialog: false })
  }

  handleFlyToActivePosition = () => {
    const { lng, lat } = this.props.activeContact
    // Save to Redux global state
    this.props.dispatch({ type: "TOGGLE_FLY_TO_ACTIVE_FEATURE", value: { lng, lat } })
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  render() {
    const { isUpdating } = this.state
    const { classes, users, activeContact } = this.props
    const { fullScreen } = this.props

    return (
      <Fragment>
        {activeContact && (
          <Fragment>
            <Grid container direction="row" justify="space-between" alignItems="center" onClick={this.toggleDrawerMobile} style={{ flexWrap: "nowrap" }}>
              <Typography variant="h6" color="inherit" style={{ marginLeft: "8px" }} noWrap>
                Troupeau - Contact
              </Typography>
              <Tooltip title="Fermer" placement="left" TransitionComponent={Zoom} disableFocusListener>
                <div>
                  <IconButton onClick={this.closeContainer} disabled={!this.props.contactViewMode} color="inherit">
                    <CloseIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>

            <Grow in={this.state.switchActive}>
              <ExpansionPanel expanded={this.state.expanded === "contact"} onChange={this.handleChangePanel("contact")}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {activeContact.firstname ? activeContact.firstname + " " + activeContact.lastname : "Nouveau troupeau"}
                    {activeContact.title ? " - " + activeContact.title : ""}
                  </Typography>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                  <div className={classes.panelDetails}>
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        id="contactTitle"
                        name="contactTitle"
                        type="text"
                        label="Lieu-dit / Titre personnalisé"
                        fullWidth
                        value={activeContact.title ? activeContact.title : ""}
                        onChange={e => this.refreshTitle(e)}
                        disabled={!isUpdating}
                        error={this.state.errorTitle}
                      />
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <InputLabel htmlFor="contactActivity">Type d'activité</InputLabel>
                      <Select
                        value={activeContact.activity ? activeContact.activity : ""}
                        onChange={e => this.refreshActivity(e)}
                        disabled={!isUpdating}
                        inputProps={{
                          id: "contactActivity",
                          name: "contactActivity"
                        }}
                        error={this.state.errorActivity}
                      >
                        <MenuItem value="">
                          <em>Choisir...</em>
                        </MenuItem>
                        <MenuItem value="Elevage ovin">Elevage ovin</MenuItem>
                        <MenuItem value="Elevage bovin laitier">Elevage bovin laitier</MenuItem>
                        <MenuItem value="Elevage bovin allaitant">Elevage bovin allaitant</MenuItem>
                        <MenuItem value="Elevage caprin">Elevage caprin</MenuItem>
                        <MenuItem value="Elevage mixte">Elevage mixte</MenuItem>
                      </Select>
                    </FormControl>

                    {/* {this.props.contactNewMode && (
                      <FormControl margin="normal" fullWidth>
                        <Typography>
                          <CustomLink href="/#" onClick={this._______________}>
                            Charger mes coordonnées
                          </CustomLink>
                        </Typography>
                      </FormControl>
                    )} */}

                    <FormControl margin="normal" fullWidth>
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="contactFirstName"
                            name="contactFirstName"
                            type="text"
                            label="Prénom"
                            fullWidth
                            value={activeContact.firstname ? activeContact.firstname : ""}
                            onChange={e => this.refreshFirstName(e)}
                            inputProps={{
                              className: classes.capitalize
                            }}
                            disabled={!isUpdating}
                            error={this.state.errorFirstName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="contactLastName"
                            name="contactLastName"
                            type="text"
                            label="NOM"
                            fullWidth
                            value={activeContact.lastname ? activeContact.lastname : ""}
                            onChange={e => this.refreshLastName(e)}
                            inputProps={{
                              className: classes.uppercase
                            }}
                            disabled={!isUpdating}
                            error={this.state.errorLastName}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <TextField
                        id="contactEmail"
                        name="contactEmail"
                        type="email"
                        label="Email"
                        fullWidth
                        value={activeContact.email ? activeContact.email : ""}
                        onChange={e => this.refreshEmail(e)}
                        inputProps={{
                          className: classes.lowercase
                        }}
                        disabled={!isUpdating}
                        error={this.state.errorEmail}
                      />
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <TextField
                        id="contactPhone"
                        name="contactPhone"
                        type="text"
                        label="Téléphone mobile"
                        fullWidth
                        value={activeContact.phone ? activeContact.phone : ""}
                        onChange={e => this.refreshPhone(e)}
                        disabled={!isUpdating}
                        error={this.state.errorPhone}
                        helperText={this.state.errorPhone ? phoneHelperText : ""}
                        InputProps={{
                          inputComponent: PhoneFormatter
                        }}
                      />
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <InputLabel shrink>Localisation</InputLabel>
                      <Button color="primary" variant="outlined" style={{ marginTop: "25px" }} fullWidth onClick={this.handleFlyToActivePosition}>
                        <MapIcon className={classes.buttonIcon} />
                        Situer sur la carte
                      </Button>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <TextField
                        id="contactCity"
                        name="contactCity"
                        type="text"
                        label="Commune"
                        fullWidth
                        value={
                          activeContact.location
                            ? activeContact.location.city + ", " + activeContact.location.region + ", " + activeContact.location.country
                            : ""
                        }
                        disabled={true}
                      />
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="contactLng"
                            name="contactLng"
                            type="text"
                            label="Longitude"
                            fullWidth
                            value={activeContact.lng ? activeContact.lng : ""}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="contactLat"
                            name="contactLat"
                            type="text"
                            label="Latitude"
                            fullWidth
                            value={activeContact.lat ? activeContact.lat : ""}
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <TextField
                        id="contactAuthor"
                        name="contactAuthor"
                        type="text"
                        label="Auteur"
                        fullWidth
                        value={activeContact.author && users[activeContact.author] ? users[activeContact.author].firstname + " " + users[activeContact.author].lastname : ""}
                        disabled={true}
                      />
                    </FormControl>

                    {this.props.userGroup === "admin" && (
                      <FormControl margin="normal" fullWidth>
                        <Typography>REF : {this.props.activeContactId}</Typography>
                      </FormControl>
                    )}
                  </div>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions style={{ flexWrap: "wrap" }}>
                  {!isUpdating && (
                    <Fragment>
                      {(this.props.userGroup === "admin" || activeContact.author === this.props.user.uid) && (
                        <Button color="secondary" size="small" onClick={this.enableUpdateMode}>
                          <EditIcon className={classes.buttonIcon} />
                          Modifier
                        </Button>
                      )}
                      <Button color="default" size="small" onClick={this.closeContainer} disabled={!this.props.contactViewMode}>
                        <CloseIcon className={classes.buttonIcon} />
                        Fermer
                      </Button>
                    </Fragment>
                  )}
                  {isUpdating && !this.props.contactNewMode && (
                    <Fragment>
                      <Button color="primary" size="small" onClick={this.disableUpdateMode}>
                        <CancelIcon className={classes.buttonIcon} />
                        Annuler
                      </Button>
                      <Button color="secondary" size="small" onClick={this.saveUpdate}>
                        <SaveIcon className={classes.buttonIcon} />
                        Mettre à jour
                      </Button>
                      <Button size="small" className={classes.deleteButton} onClick={() => this.setState({ openDeleteFeatureDialog: true })}>
                        <DeleteIcon className={classes.buttonIcon} />
                        Supprimer
                      </Button>
                    </Fragment>
                  )}
                  {isUpdating && this.props.contactNewMode && (
                    <Fragment>
                      <Button color="primary" size="small" onClick={this.cancelNew}>
                        <CancelIcon className={classes.buttonIcon} />
                        Annuler
                      </Button>
                      <Button color="secondary" size="small" onClick={this.saveNew}>
                        <SaveIcon className={classes.buttonIcon} />
                        Enregistrer
                      </Button>
                    </Fragment>
                  )}
                </ExpansionPanelActions>
              </ExpansionPanel>
            </Grow>
          </Fragment>
        )}

        <Dialog fullScreen={fullScreen} open={this.state.openDeleteFeatureDialog} onClose={this.handleCloseDialog}>
          <DialogTitle>{"Supprimer définitivement ?"}</DialogTitle>
          <DialogContent>
            <Typography>Etes-vous certain de vouloir supprimer cette donnée ?</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleCloseDialog}>
              Annuler
            </Button>
            <Button onClick={this.handleDeleteFeature} className={classes.deleteButton} autoFocus>
              Effacer
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    userFirstName: state.userReducer.userFirstName,
    userLastName: state.userReducer.userLastName,
    userEmail: state.userReducer.userEmail,
    userPhone: state.userReducer.userPhone,
    userGroup: state.userReducer.userGroup,

    users: state.dataReducer.users,
    contacts: state.dataReducer.contacts,
    joins: state.dataReducer.joins,
    alerts: state.dataReducer.alerts,

    activeContact: state.mapReducer.activeContact,
    activeContactId: state.mapReducer.activeContactId,
    contactViewMode: state.mapReducer.contactViewMode,
    ressourceViewMode: state.mapReducer.ressourceViewMode,
    contactNewMode: state.mapReducer.contactNewMode
  }
}

export default withStyles(styles)(connect(mapStateToProps)(withMobileDialog()(withSnackbar(ContactContainer))))
